import { ComponentProps, useEffect, useMemo, useState } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box } from '@mui/material'
import { useLocation, useParams } from 'react-router-dom'
import { makeStyles } from 'tss-react/mui'

import { AlertWidget } from '_pages/companies/[id]/touchpoints'

import Avatar from '_shared/Avatar'
import { Button, IconButton } from '_shared/buttons'
import Skeleton from '_shared/Skeleton'
import Typography from '_shared/Typography'

import { nameReason } from '_core/components/dialogs/InformAboutIdentity'
import Empty from '_core/components/Empty'
import ExternalTag, { ExtraTagsPopover } from '_core/components/ExternalTag'
import Heading from '_core/components/Heading'
import IdentifierLabel from '_core/components/IdentifierLabel'
import { Interaction, getTime } from '_core/components/Interactions'
import InteractionsPrivacyMsg from '_core/components/InteractionsPrivacy'
import { Column, Columns, Narrow, useWide, WideStrict, Middle } from '_core/components/layout'
import Repeater from '_core/components/lists/Repeater'
import Timeline, { TimelineList } from '_core/components/lists/Timeline'
import NameLink from '_core/components/NameLink'
import Relationship from '_core/components/Relationship'
import SidepanelLink from '_core/components/SidepanelLink'
import TagsGroup from '_core/components/TagsGroup'
import TitleDescription from '_core/components/TitleDescription'
import TouchpointRelationSummary from '_core/components/TouchpointRelationSummary'
import Widget from '_core/components/Widget'

import useAbortableFetch from '_core/hooks/useAbortableFetch'

import { stringifyUrl } from '_core/helpers/browser'
import { sum } from '_core/helpers/numbers'

import { mergeUrlWithParams, post } from 'utils/httpUtils'
import { ellipsisText } from 'utils/Utils'

import { widgetSubTitle } from 'AppTheme'

import Paths from 'Paths'

const useStyles = makeStyles()((theme) => ({
  heading: widgetSubTitle(theme),
  timeline: {
    marginTop: theme.spacing(1),
    '& .MuiTimelineSeparator-root': {
      marginTop: theme.spacing(2),
      marginBottom: `-${theme.spacing(2)}`
    },
    '& .MuiTimelineDot-root': {
      background: 'none',
      border: 'none',
      boxShadow: 'none',
      padding: 0,
      color: theme.palette.primary.main
    }
  },
  shiftTop: {
    marginTop: `-${theme.spacing(2)}`
  },
  shiftBottom: {
    marginBottom: `-${theme.spacing(2)}`
  },
  empty: {
    '& > div:last-child': {
      '& .MuiTypography-root': {
        fontSize: '14px !important'
      },
      [theme.breakpoints.up('md')]: {
        padding: theme.spacing(5)
      }
    }
  },
  label: {
    fontSize: 14
  },
  tagsWrapper: {
    marginBottom: `-${theme.spacing(0.5)}`,
    flexWrap: 'wrap',
    rowGap: theme.spacing(0.5)
  }
}))

const InteractionsWidget = (props: any) => {
  const { classes } = useStyles()
  const { pathname } = useLocation()
  const { interactions } = props
  const { data: items, errorMessage } = interactions || {}
  const loading = props.loading || !interactions

  return (
    <Widget>
      <Heading
        underlined
        title="Interactions"
        icon={['far', 'envelope']}
        count={0}
        action={
          <Box flex={1}>
            <InteractionsPrivacyMsg />
          </Box>
        }
        link={!loading && items?.length ? `${Paths._companies}/${props.CompanyMd5}/interactions` : undefined}
      />
      {errorMessage && (
        <Empty
          className={classes.empty}
          icon={<FontAwesomeIcon size="4x" style={{ color: '#A7A7A7' }} icon={['fat', 'envelope-open-text']} />}
          subTitle={errorMessage}
        />
      )}
      {!errorMessage && (
        <>
          {(!!items?.length || loading) && (
            <Timeline scope="widget" className={classes.timeline} loading={loading}>
              <TimelineList
                items={items
                  ?.map((item: any) => ({
                    time: getTime(item.receivedDateTime),
                    title: item.rawSubject || '(No subject)',
                    byline: item.body,
                    byline2: (
                      <NameLink
                        variant="light"
                        url={stringifyUrl(`${Paths._people}/${item.senderEmail}`, { name: item.senderName, email: item.senderEmail })}
                        name={item.senderName}
                      />
                    ),
                    dotIcon: <FontAwesomeIcon icon={['fas', 'envelope']} />,
                    sidepanel: true,
                    link: stringifyUrl(`${Paths._messages}/${item.sourceKey}`, { deleteBackLink: pathname }),
                    component: 'div'
                  }))
                  .slice(0, 5)}
                skeleton={{ size: 5, loading }}
                component={Interaction}
              />
            </Timeline>
          )}
          {!items?.length && !loading && (
            <Empty
              className={classes.empty}
              subTitle={`You have not exchanged any email messages with ${props.CompanyNameText}`}
              icon={<FontAwesomeIcon size="3x" icon={['fat', 'envelope-open-text']} style={{ color: '#A7A7A7' }} />}
              close={false}
            />
          )}
        </>
      )}
    </Widget>
  )
}

const CSuiteWidget = ({ people, setPeople, teamNumber, ...props }: any) => {
  const [error, setError] = useState('')
  const { id } = useParams<{ id: string }>()
  const { fetchWithAbort, loading } = useAbortableFetch()
  const { classes } = useStyles()

  useEffect(() => {
    if (props.BestUrlText && !people && teamNumber) {
      ;(async () => {
        try {
          const res = await fetchWithAbort({ url: `/companies/${props.BestUrlText}/cSuite?titlecase=true&teamNumber=${teamNumber}` })
          if (res) setPeople(res)
        } catch (e: any) {
          setPeople({})
          setError(e?.status === 500 ? 'No records were found' : e?.error)
          console.log('error during fetching cSuite', e)
        }
      })()
    }
  }, [props.BestUrlText, people, teamNumber])

  const items = people?.data?.length
    ? people.data.slice(0, 5).map((person: { [key: string]: any }) => {
        return {
          name: person.full_name,
          score: person.personMd5 ? person.score : undefined,
          sidepanel: true,
          link: person.personMd5
            ? `${Paths._people}/${person.personMd5}`
            : mergeUrlWithParams(`${Paths._companies}/${id}/c-suite/${person.full_name}`, {
                email: person.work_email,
                company: person.job_company_name
              })
        }
      })
    : []

  const load = loading || props.loading
  const showEmptyWidget = error || (!load && !people?.data?.length)

  return (
    <Widget>
      <Heading
        underlined
        title="C Suite"
        icon={['fas', 'user-tie']}
        count={people?.total}
        link={people?.total && !load ? `${Paths._companies}/${id}/c-suite?name=${props.CompanyNameText}` : undefined}
        sidepanel
      />
      {!showEmptyWidget && <Repeater direction="horizontal" component={Avatar} skeleton={{ size: 5, loading: load }} items={items} />}
      {showEmptyWidget && (
        <Empty
          className={classes.empty}
          subTitle={error || 'No records were found'}
          icon={<FontAwesomeIcon size="3x" icon={['fat', 'user-group']} style={{ color: '#A7A7A7' }} />}
        />
      )}
    </Widget>
  )
}

const SimilarCompaniesWidget = ({ similar, setSimilar, teamNumber, ...props }: any) => {
  const [error, setError] = useState('')
  const { id } = useParams<{ id: string }>()
  const { fetchWithAbort, loading } = useAbortableFetch<{ [key: string]: any }>()
  const { classes } = useStyles()

  const load = loading || props.loading
  const { employee_count, industry, location, type, website } = props.marketData || {}
  const enoughFilters = props.marketData && (website || props.BestUrlText)
  const showEmptyWidget = error || (enoughFilters && !loading && !similar?.data?.length) || (props.marketData && !enoughFilters)

  const initParams = useMemo(
    () => ({
      website: website || props.BestUrlText,
      minimumEmployeeCount: employee_count ? Math.round(Number(employee_count) / 2).toString() : '',
      maximumEmployeeCount: employee_count ? Math.round(Number(employee_count) * 1.5).toString() : '',
      industries: industry ? [industry] : [],
      countries: [location?.country || 'United States'],
      companyType: type || 'Private',
      teamNumber: teamNumber.toString(),
      titlecase: 'true'
    }),
    [props.BestUrlText, website, employee_count, industry, location?.country, type, teamNumber]
  )

  useEffect(() => {
    if (enoughFilters && !similar && teamNumber) {
      ;(async () => {
        try {
          const res = await fetchWithAbort({ url: mergeUrlWithParams('/companies/similarCompanies', initParams) })
          if (res) setSimilar(res)
        } catch (e: any) {
          setSimilar({})
          setError(e?.status === 500 ? 'No records were found' : e?.error)
          console.log('error during fetching similar companies', e)
        }
      })()
    }
  }, [enoughFilters, similar, teamNumber])

  const items = similar?.data
    ? similar.data.map((company: { [key: string]: any }) => {
        return {
          name: company.name,
          score: company.companyMd5 ? company.score : undefined,
          sidepanel: true,
          logoUrl: company.website,
          link: company.companyMd5
            ? `${Paths._companies}/${company.companyMd5}`
            : mergeUrlWithParams(`${Paths._companies}/${id}/similar/${company.name}`, {
                website: company.website
              }),
          byline: company.website,
          byline2: company.industry
        }
      })
    : []

  return (
    <Widget>
      <Heading
        underlined
        title="Similar companies"
        icon={['fas', 'diagram-venn']}
        count={showEmptyWidget ? 0 : similar?.total}
        link={
          similar?.total && id && !showEmptyWidget ? `${Paths._companies}/${id}/similar?clearSearch=true&name=${props.CompanyNameText}` : undefined
        }
        sidepanel
      />
      {!showEmptyWidget && (
        <Repeater direction="horizontal" component={Avatar} skeleton={{ size: 5, loading: !props.marketData || load }} items={items} />
      )}
      {showEmptyWidget && (
        <Empty
          className={classes.empty}
          subTitle={
            error ||
            (enoughFilters ? 'No records were found matching your search' : 'Not enough market data is available for an effective search filter.')
          }
          action={
            <Box display="flex" justifyContent="center">
              <SidepanelLink
                linkProps={{ to: `${Paths._companies}/${id}/similar?openedFilters=true&clearSearch=true&name=${props.CompanyNameText}` }}
                sidepanel={true}
              >
                <Button variant="link" bold={false}>
                  {enoughFilters ? 'Change' : 'Add'} filters
                </Button>
              </SidepanelLink>
            </Box>
          }
          icon={<FontAwesomeIcon size="3x" icon={['fat', 'user-group']} style={{ color: '#A7A7A7' }} />}
        />
      )}
    </Widget>
  )
}

const IntroducersWidget = (props: any) => {
  const { classes } = useStyles()
  const wide = useWide()
  const { data: items, total_item_count } = props.Introducers || {}

  return (
    <Widget>
      <Heading
        underlined
        title="Introducers"
        count={total_item_count}
        icon={['far', 'address-book']}
        link={total_item_count ? `${Paths._companies}/${props.CompanyMd5}/introducers` : ''}
      />
      {(!!total_item_count || props.loading) && (
        <Repeater
          direction="horizontal"
          component={Avatar}
          skeleton={{ size: wide ? 5 : 4, loading: props.loading }}
          items={items?.slice(0, wide ? 5 : 4).map((intro: any) => ({
            name: intro.IntroducerName,
            userKey: intro.IntroducerBestEmailAddressText,
            score: intro.IntroducerScore,
            link: `${Paths._relationships}/${intro.UserKeyMd5}/companies/${props.CompanyMd5}`,
            sidepanel: true
          }))}
        />
      )}
      {!total_item_count && !props.loading && (
        <Empty
          className={classes.empty}
          subTitle={`${props.CompanyNameText} has no introducers`}
          icon={<FontAwesomeIcon size="3x" icon={['fat', 'address-book']} style={{ color: '#A7A7A7' }} />}
          close={false}
        />
      )}
    </Widget>
  )
}

const RelationshipsWidget = (props: any) => {
  const { classes } = useStyles()
  const { data: items, total_item_count } = props.Employees || {}

  return (
    <Widget>
      <Heading
        underlined
        title="Key relationships"
        icon={['far', 'star']}
        count={props.loading ? undefined : 0}
        link={total_item_count ? `${Paths._companies}/${props.CompanyMd5}/relationships` : ''}
      />
      {(!!total_item_count || props.loading) && (
        <Repeater
          direction="vertical"
          component={Relationship}
          skeleton={{ size: 3, loading: props.loading }}
          items={items?.slice(0, 3).map((rel: any) => ({
            name1: rel.PersonNameText,
            name2: rel.KnowerNameText,
            score: rel.UserKnowsPersonScore,
            userKey1: rel.ContactBestEmail
          }))}
        />
      )}
      {!total_item_count && !props.loading && (
        <Empty
          className={classes.empty}
          subTitle={`You have no key relationships with ${props.CompanyNameText}`}
          icon={<FontAwesomeIcon size="3x" icon={['fat', 'star']} style={{ color: '#A7A7A7' }} />}
          close={false}
        />
      )}
    </Widget>
  )
}

const TouchpointsWidget = (props: any) => {
  const { classes } = useStyles()
  const stats =
    !props.loading && props.Stats
      ? Object.getOwnPropertyNames(props.Stats).filter(
          (k) =>
            ['FirstInboundMsg', 'FirstMeeting', 'FirstOutboundMsg', 'LastInboundMsg', 'LastMeeting', 'LastOutboundMsg', 'NextFutureMeeting'].indexOf(
              k
            ) > -1
        )
      : []

  return (
    <Widget>
      <Heading
        underlined
        title="Key touch points"
        icon={['far', 'handshake']}
        count={props.loading ? -1 : 0}
        link={!props.loading && stats.length ? `/companies/${props.CompanyMd5}/touchpoints` : ''}
      />
      {(!!stats.length || props.loading) && (
        <Repeater component={TitleDescription} skeleton={{ size: 3, loading: props.loading }} items={TouchpointRelationSummary(props.Stats)} />
      )}
      {!stats.length && !props.loading && (
        <Empty
          className={classes.empty}
          subTitle={`You have no touchpoints with ${props.CompanyNameText}`}
          icon={<FontAwesomeIcon size="3x" icon={['fat', 'handshake']} style={{ color: '#A7A7A7' }} />}
          close={false}
        />
      )}
    </Widget>
  )
}

const ContactsWidget = (props: any) => {
  const { classes } = useStyles()
  const { total_item_count, data: items } = props.Employees || {}
  const wide = useWide()

  return (
    <Widget>
      <Heading
        underlined
        title="Contacts"
        count={total_item_count}
        icon={['far', 'user']}
        link={total_item_count ? `${Paths._companies}/${props.CompanyMd5}/contacts` : ''}
      />
      {(!!total_item_count || props.loading) && (
        <Repeater
          direction="horizontal"
          component={Avatar}
          skeleton={{ size: wide ? 5 : 4, loading: props.loading }}
          items={items?.slice(0, wide ? 5 : 4).map((contact: any) => ({
            name: contact.PersonNameText,
            score: contact.WeKnowPersonScore,
            sidepanel: true,
            link: `${Paths._people}/${contact.ContactUserKeyMd5 || contact.PersonMd5}`,
            userKey: contact.ContactBestEmail
          }))}
        />
      )}
      {!total_item_count && !props.loading && (
        <Empty
          className={classes.empty}
          subTitle={`${props.CompanyNameText} has no contacts`}
          icon={<FontAwesomeIcon size="3x" icon={['fat', 'user-group']} style={{ color: '#A7A7A7' }} />}
          close={false}
        />
      )}
    </Widget>
  )
}

const AliasesDomainsWidget = (props: any) => {
  const {
    classes: { heading }
  } = useStyles()
  const { id } = useParams<{ id: string }>()

  const submitInvalid = async (identity: string, value: number) => {
    return post(`/companies/disqualify?Identity=${identity}&Reason=${nameReason[value]}`)
  }

  return (
    <Widget>
      <Heading
        underlined
        title="Aliases and domains"
        count={props.loading ? -1 : sum(props.Aliases?.total_item_count, props.Urls?.total_item_count)}
        icon={['far', 'fingerprint']}
        link={!props.loading ? `${Paths._companies}/${props.CompanyMd5}/aliases` : undefined}
      />
      <Box marginBottom="16px">
        <Typography
          variant="body1"
          className={heading}
          component="div"
          style={{ marginTop: 16, paddingTop: 8, marginBottom: props?.Aliases?.data?.length ? -8 : 0 }}
        >
          Aliases
        </Typography>

        <Repeater
          component={IdentifierLabel}
          variant="homepage"
          skeleton={{ size: 2, loading: props.loading }}
          items={props.Aliases?.data?.slice(0, 2).map((name: any) => ({
            name: props.CompanyNameText,
            identity: name.CoNameAlias,
            icon: ['far', 'building'],
            type: 'CompanyName',
            submit: submitInvalid,
            sourceLink: `${Paths._companies}/${id}/aliasSources/${name.CoNameAlias}`,
            auditLink: `${Paths._companies}/${id}/audit`
          }))}
          empty="No aliases were found"
        />

        <Typography variant="body1" className={heading} component="div" style={{ marginTop: 12, marginBottom: props?.Urls?.data?.length ? -8 : 0 }}>
          Domains
        </Typography>

        <Repeater
          component={IdentifierLabel}
          variant="homepage"
          skeleton={{ size: 2, loading: props.loading }}
          items={props.Urls?.data?.slice(0, 2).map((email: any) => ({
            name: props.CompanyNameText,
            identity: email.CoUrl,
            icon: ['far', 'globe'],
            type: 'DomainUrl',
            submit: submitInvalid,
            sourceLink: `${Paths._companies}/${id}/domainSources/${email.CoUrl}`,
            auditLink: `${Paths._companies}/${id}/audit`
          }))}
          empty="No domains were found"
        />
      </Box>
    </Widget>
  )
}

const MarketDataWidget = (props: any) => {
  const { id: companyId } = useParams<{ id: string }>()
  const { classes } = useStyles()
  const { tags, summary, id, website, founded, location, employee_count, industry } = props.marketData || {}
  const mocked = new Array(3).fill('')

  const noData = props.marketData && !id
  const noWidgetData = id && !tags?.length && !summary
  const notEnoughData = noWidgetData && !website && !founded && !location && !employee_count && !industry

  return (
    <Widget>
      <Heading
        underlined
        title="Details"
        icon={['fas', 'chart-column']}
        count={0}
        action={
          <Box flex={1}>
            <IconButton
              disablePY
              component="a"
              href="https://help.dotalign.com/article/x8f8c6vw1i-dot-align-market-data"
              target="_blank"
              size="small"
              icon={['far', 'question-circle']}
              hint="Learn more about market data"
            />
          </Box>
        }
        link={!noData && !notEnoughData && props.CompanyNameText ? `${Paths._companies}/${companyId}/market-data?name=${props.CompanyNameText}` : ''}
        sidepanel
      />

      {(noData || notEnoughData || (noWidgetData && !notEnoughData)) && (
        <Empty
          className={classes.empty}
          icon={<FontAwesomeIcon size="3x" icon={['fat', 'chart-column']} style={{ color: '#A7A7A7' }} />}
          subTitle={
            <>
              {noData && 'No details are available in market data'}
              {notEnoughData && 'Not enough details are available in market data'}
              {noWidgetData && !notEnoughData && 'No tag or summary info is available, click "see all" for other market data'}
            </>
          }
        />
      )}

      <Box mb={{ md: 2 }}>
        {!props.marketData && (
          <Box>
            {mocked.map((el, i) => (
              <Skeleton condition={!props.marketData} height="23px" width="100%" key={i} />
            ))}
          </Box>
        )}

        {summary && <Typography style={{ paddingBottom: '8px' }}>{ellipsisText(summary, 150)}</Typography>}

        {(!props.marketData || !!tags?.length) && (
          <TagsGroup<ComponentProps<typeof ExternalTag>['tagData']>
            tagComponent={ExternalTag}
            className={classes.tagsWrapper}
            max={2}
            items={!props.marketData ? mocked : tags}
            title={<Typography bold>Tags</Typography>}
            {...(props.marketData
              ? {
                  renderShowAll: ({ extraTagsAmount }: { extraTagsAmount: number }) => (
                    <ExtraTagsPopover
                      tags={!props.marketData ? mocked : tags}
                      title="Tags"
                      name={props.CompanyNameText}
                      triggerElement={
                        <Box display="inline-flex" alignItems="center" height="100%">
                          <Typography noWrap>{extraTagsAmount} more</Typography>
                        </Box>
                      }
                    />
                  )
                }
              : {})}
          />
        )}
      </Box>
    </Widget>
  )
}

const Profile = ({
  isEnabled: isSalesforceEnabled,
  marketDataIntegration,
  marketData,
  similar,
  setSimilar,
  cSuite,
  setCSuite,
  teamNumber,
  ...props
}: any) => {
  const { classes, cx } = useStyles()

  useEffect(() => {
    if (!props.loading && props.CompanyMd5) window.parent.postMessage(`da_open`, '*')
  }, [props.loading, props.CompanyMd5])

  const { enabled: enabledMarketData, showSimilarCompanies } = marketDataIntegration || {}

  return (
    <>
      <WideStrict>
        <Columns className={classes.shiftBottom}>
          <Column md={12}>
            <AlertWidget {...props} />
          </Column>
        </Columns>

        {props.showInteractionsInProfile && (
          <>
            <Columns>
              {!enabledMarketData ? (
                <>
                  <Column>
                    <IntroducersWidget {...props} />
                    <TouchpointsWidget {...props} />
                  </Column>
                  <Column>
                    <ContactsWidget {...props} />
                    <RelationshipsWidget {...props} />
                  </Column>
                </>
              ) : (
                <>
                  <Column>
                    <MarketDataWidget marketData={marketData} {...props} />
                    <ContactsWidget {...props} />
                  </Column>
                  <Column>
                    <IntroducersWidget {...props} />
                    {showSimilarCompanies && <CSuiteWidget people={cSuite} setPeople={setCSuite} teamNumber={teamNumber} {...props} />}
                    {!showSimilarCompanies && <RelationshipsWidget {...props} />}
                  </Column>
                </>
              )}
              <Column>
                <InteractionsWidget {...props} />
              </Column>
            </Columns>

            {showSimilarCompanies && (
              <Columns className={classes.shiftTop}>
                <Column>
                  <SimilarCompaniesWidget marketData={marketData} similar={similar} setSimilar={setSimilar} teamNumber={teamNumber} {...props} />
                </Column>
                <Column>
                  <TouchpointsWidget {...props} />
                </Column>
                <Column>
                  <RelationshipsWidget {...props} />
                </Column>
              </Columns>
            )}

            <Columns className={classes.shiftTop}>
              {enabledMarketData && !showSimilarCompanies && (
                <Column>
                  <TouchpointsWidget {...props} />
                </Column>
              )}
              <Column>
                <AliasesDomainsWidget {...props} />
              </Column>
            </Columns>
          </>
        )}
        {!props.showInteractionsInProfile && (
          <>
            {showSimilarCompanies && (
              <Columns>
                <Column>
                  <MarketDataWidget marketData={marketData} {...props} />
                </Column>
                <Column>
                  <IntroducersWidget {...props} />
                </Column>
                <Column>
                  <ContactsWidget {...props} />
                </Column>
              </Columns>
            )}
            <Columns className={cx({ [classes.shiftTop]: showSimilarCompanies })}>
              {enabledMarketData && !showSimilarCompanies && (
                <>
                  <Column>
                    <MarketDataWidget marketData={marketData} {...props} />
                  </Column>
                  <Column>
                    <IntroducersWidget {...props} />
                  </Column>
                  <Column>
                    <ContactsWidget {...props} />
                  </Column>
                </>
              )}
              {!enabledMarketData && (
                <>
                  <Column>
                    <IntroducersWidget {...props} />
                  </Column>
                  <Column>
                    <ContactsWidget {...props} />
                  </Column>
                  <Column>
                    <TouchpointsWidget {...props} />
                  </Column>
                </>
              )}
              {showSimilarCompanies && (
                <>
                  <Column>
                    <SimilarCompaniesWidget marketData={marketData} similar={similar} setSimilar={setSimilar} teamNumber={teamNumber} {...props} />
                  </Column>
                  <Column>
                    <CSuiteWidget people={cSuite} setPeople={setCSuite} teamNumber={teamNumber} {...props} />
                  </Column>
                  <Column>
                    <RelationshipsWidget {...props} />
                  </Column>
                </>
              )}
            </Columns>
            <Columns className={classes.shiftTop}>
              {enabledMarketData && (
                <Column>
                  <TouchpointsWidget {...props} />
                </Column>
              )}
              {(!enabledMarketData || (enabledMarketData && !showSimilarCompanies)) && (
                <Column>
                  <RelationshipsWidget {...props} />
                </Column>
              )}
              <Column>
                <AliasesDomainsWidget {...props} />
              </Column>
            </Columns>
          </>
        )}
      </WideStrict>
      <Middle>
        <Columns className={classes.shiftBottom}>
          <Column md={12}>
            <AlertWidget {...props} />
          </Column>
        </Columns>
        {props.showInteractionsInProfile && (!enabledMarketData || showSimilarCompanies) && (
          <>
            <Columns>
              <Column two>
                {showSimilarCompanies && <MarketDataWidget marketData={marketData} {...props} />}
                <IntroducersWidget {...props} />
                {!enabledMarketData && <ContactsWidget {...props} />}
              </Column>
              <Column two>
                <InteractionsWidget {...props} />
              </Column>
            </Columns>
            {showSimilarCompanies && (
              <Columns className={classes.shiftTop}>
                <Column two>
                  <ContactsWidget {...props} />
                </Column>
                <Column two>
                  <CSuiteWidget people={cSuite} setPeople={setCSuite} teamNumber={teamNumber} {...props} />
                </Column>
              </Columns>
            )}
            <Columns className={classes.shiftTop}>
              {showSimilarCompanies && (
                <Column two>
                  <SimilarCompaniesWidget marketData={marketData} similar={similar} setSimilar={setSimilar} teamNumber={teamNumber} {...props} />
                </Column>
              )}
              {!enabledMarketData && (
                <Column two>
                  <RelationshipsWidget {...props} />
                </Column>
              )}
              <Column two>
                <TouchpointsWidget {...props} />
              </Column>
            </Columns>
            <Columns className={classes.shiftTop}>
              {showSimilarCompanies && (
                <Column two>
                  <RelationshipsWidget {...props} />
                </Column>
              )}
              <Column two>
                <AliasesDomainsWidget {...props} />
              </Column>
            </Columns>
          </>
        )}
        {props.showInteractionsInProfile && enabledMarketData && !showSimilarCompanies && (
          <>
            <Columns>
              <Column two>
                <MarketDataWidget marketData={marketData} {...props} />
                <IntroducersWidget {...props} />
              </Column>
              <Column two>
                <InteractionsWidget {...props} />
              </Column>
            </Columns>
            <Columns className={classes.shiftTop}>
              <Column two>
                <ContactsWidget {...props} />
              </Column>
              <Column two>
                <TouchpointsWidget {...props} />
              </Column>
            </Columns>
            <Columns className={classes.shiftTop}>
              <Column two>
                <RelationshipsWidget {...props} />
              </Column>
              <Column two>
                <AliasesDomainsWidget {...props} />
              </Column>
            </Columns>
          </>
        )}

        {!props.showInteractionsInProfile && (!enabledMarketData || showSimilarCompanies) && (
          <>
            <Columns>
              {showSimilarCompanies && (
                <Column two>
                  <MarketDataWidget marketData={marketData} {...props} />
                </Column>
              )}
              <Column two>
                <IntroducersWidget {...props} />
              </Column>
              {!enabledMarketData && (
                <Column two>
                  <ContactsWidget {...props} />
                </Column>
              )}
            </Columns>

            {showSimilarCompanies && (
              <Columns className={classes.shiftTop}>
                <Column two>
                  <ContactsWidget {...props} />
                </Column>
                <Column two>
                  <CSuiteWidget people={cSuite} setPeople={setCSuite} teamNumber={teamNumber} {...props} />
                </Column>
              </Columns>
            )}
            <Columns className={classes.shiftTop}>
              {showSimilarCompanies && (
                <Column two>
                  <SimilarCompaniesWidget marketData={marketData} similar={similar} setSimilar={setSimilar} teamNumber={teamNumber} {...props} />
                </Column>
              )}
              {!enabledMarketData && (
                <Column two>
                  <TouchpointsWidget {...props} />
                </Column>
              )}
              <Column two>
                <RelationshipsWidget {...props} />
              </Column>
            </Columns>
            <Columns className={classes.shiftTop}>
              <Column two>
                <AliasesDomainsWidget {...props} />
              </Column>
              {showSimilarCompanies && (
                <Column two>
                  <TouchpointsWidget {...props} />
                </Column>
              )}
            </Columns>
          </>
        )}

        {!props.showInteractionsInProfile && enabledMarketData && !showSimilarCompanies && (
          <>
            <Columns>
              <Column two>
                <MarketDataWidget marketData={marketData} {...props} />
              </Column>
              <Column two>
                <IntroducersWidget {...props} />
              </Column>
            </Columns>
            <Columns className={classes.shiftTop}>
              <Column two>
                <ContactsWidget {...props} />
              </Column>
              <Column two>
                <TouchpointsWidget {...props} />
              </Column>
            </Columns>
            <Columns className={classes.shiftTop}>
              <Column two>
                <RelationshipsWidget {...props} />
              </Column>
              <Column two>
                <AliasesDomainsWidget {...props} />
              </Column>
            </Columns>
          </>
        )}
      </Middle>
      <Narrow>
        <AlertWidget {...props} />
        {enabledMarketData && <MarketDataWidget marketData={marketData} {...props} />}
        <IntroducersWidget {...props} />
        <ContactsWidget {...props} />
        {showSimilarCompanies && (
          <>
            <CSuiteWidget people={cSuite} setPeople={setCSuite} teamNumber={teamNumber} {...props} />
            <SimilarCompaniesWidget marketData={marketData} similar={similar} setSimilar={setSimilar} teamNumber={teamNumber} {...props} />
          </>
        )}
        {props.showInteractionsInProfile && <InteractionsWidget {...props} />}
        <TouchpointsWidget {...props} />
        <RelationshipsWidget {...props} />
        <AliasesDomainsWidget {...props} />
      </Narrow>
    </>
  )
}
export default Profile
