import React, { useEffect, useState } from 'react'

import AssociationsEdit from '_core/components/team/AssociationsEdit'

import useSidepanelPayloads from '_core/hooks/useSidepanelPayloads'
import useTeam, { RenamedRole, Role } from '_core/hooks/useTeam'

import { LayoutContext } from 'Layout/LayoutContextProvider'

interface IEditUser extends SharingData {
  name: string
  teamName: string
  teamNumber: number
  userKeyMd5: string
  userKeyPlainText: string
  emailAddress: string
  prohibitAppAccess: boolean
}

interface IEditUserProps {
  loading: boolean
  user: IEditUser
  userRole: Role
  roles: Role[]
}

const EditUserAssociations = ({ loading, roles, user, userRole }: IEditUserProps) => {
  const [load, setLoad] = useState<boolean>(false)
  const [success, setSuccess] = useState<boolean>(false)
  const { editUserRole, renameContributorRole, renameMemberRole } = useTeam()
  const [currentRole, setCurrentRole] = useState<RenamedRole>(renameMemberRole(userRole))
  const [currentAccess, setCurrentAccess] = useState<boolean>(loading ? false : !user.prohibitAppAccess)
  const { setMobileHeader } = React.useContext(LayoutContext)
  const { updateParent } = useSidepanelPayloads()

  useEffect(() => {
    setMobileHeader('Edit user associations')
  }, [setMobileHeader])

  const handleRole = (role: RenamedRole) => {
    setCurrentRole(role)
  }

  const handleAccess = (checked: boolean) => {
    setCurrentAccess(checked)
  }

  const save = () => {
    if (user) {
      setLoad(true)
      editUserRole(user.teamNumber, user.userKeyPlainText, renameContributorRole(currentRole), !currentAccess)
        .then(() => {
          updateParent({ action: 'RELOAD_LIST', value: 'associations' })
          setLoad(false)
          setSuccess(true)
        })
        .catch((error) => {
          setLoad(false)
          console.log(error)
        })
    }
  }

  return (
    <AssociationsEdit
      name={user?.name}
      load={load}
      loading={loading}
      success={success}
      teamName={user?.teamName}
      teamId={user?.teamNumber.toString()}
      handleRole={handleRole}
      roles={roles}
      handleAccess={handleAccess}
      currentAccess={currentAccess}
      currentRole={currentRole}
      save={save}
    />
  )
}

export default EditUserAssociations
