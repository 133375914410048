import { useState } from 'react'

import useAbortableFetch from '_core/hooks/useAbortableFetch'

export type IntroductionVisibility = 'Off' | 'AdminsAndNetworkTeam' | 'AllUsers'
type ResponseType = [{ visibility: IntroductionVisibility }, ProfileType, { emailAddress: string }[]]

const useIntroductionsAccess = () => {
  const [userAccess, setUserAccess] = useState<boolean>()
  const { fetchWithAbort } = useAbortableFetch<ResponseType>()

  const setUserIntroductionsAccess = (
    visibility: IntroductionVisibility,
    isAdmin: boolean,
    userEmail: string,
    managers: { emailAddress: string }[]
  ) => {
    if (visibility === 'Off') {
      setUserAccess(false)
      return
    }

    const isNetworkManager = !!managers.find((manager) => manager.emailAddress === userEmail)
    const access = visibility === 'AllUsers' || (visibility === 'AdminsAndNetworkTeam' && (isAdmin || isNetworkManager))
    setUserAccess(access)
  }

  const getUserAccess = async () => {
    const settings = await fetchWithAbort([
      { url: '/usersettings/introductions' },
      { url: '/me/profile' },
      { url: '/prospecting/managers?role=ProspectingManager' }
    ])
    if (settings) {
      const [userSettings, profile, managers] = settings
      setUserIntroductionsAccess(userSettings.visibility, profile.IsAdmin, profile.UserKey, managers)
    }
  }

  return {
    userIntroductionsAccess: userAccess,
    getUserIntroductionsAccess: getUserAccess,
    setUserIntroductionsAccess
  }
}

export default useIntroductionsAccess
