import React, { useEffect } from 'react'

import { useHistory } from 'react-router-dom'

import { isSidepanel } from '_pages/sidebar'

import useAppHistory from '_core/hooks/useAppHistory'

import { LayoutContext } from 'Layout/LayoutContextProvider'

const useSidebar = () => {
  const history = useHistory()
  const { historyIndex: index, setHistoryIndex: setIndex } = React.useContext(LayoutContext)
  const { appHistory, setAppHistory } = useAppHistory()

  const forwardActive = index > 0
  const backActive = appHistory.length > 0 && index !== appHistory.length - 1

  useEffect(() => {
    if (window.name === 'extension') {
      window.parent.postMessage(`da_payloads:${JSON.stringify({ backActive, forwardActive })}`, '*')
    }
  }, [backActive, forwardActive])

  const close = () => {
    setAppHistory([])
    history.replace('/blank')
    localStorage.removeItem('sidepanel')
  }

  const back = () => {
    window.scrollTo(0, 0)
    if (appHistory.length > 0) {
      setIndex((prevIndex: number) => {
        const index = prevIndex + 1
        if (isSidepanel('sidepanel')) {
          window.parent.postMessage(`sidepanel_navigate_click:${JSON.stringify(appHistory[index])}`, '*')
        } else {
          history.replace(appHistory[index])
        }
        return index
      })
    }
  }

  const forward = () => {
    window.scrollTo(0, 0)
    if (appHistory.length > 0) {
      setIndex((prevIndex: number) => {
        const index = prevIndex - 1
        if (isSidepanel('sidepanel')) {
          window.parent.postMessage(`sidepanel_navigate_click:${JSON.stringify(appHistory[index])}`, '*')
        } else {
          history.replace(appHistory[index])
        }
        return index
      })
    }
  }

  return {
    close,
    back,
    forward,
    forwardActive,
    backActive,
    currentPath: appHistory.length > 0 ? appHistory[index > -1 ? index : 0]?.pathname : null
  }
}

export default useSidebar
