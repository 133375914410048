import { useCallback, useState, useContext, useEffect } from 'react'

import _ from 'lodash'
import { useHistory } from 'react-router-dom'

import { TeamContext } from '_core/context/TeamContext'

import { LocalTagsListItems } from '_core/components/SelectTagsForm'

import { TaggableType, useLookUpTagValues } from '_core/hooks/useLookup'
import useSidepanelPayloads from '_core/hooks/useSidepanelPayloads'

import { post } from 'utils/httpUtils'

const useTagsAssign = (props: { taggableType: keyof typeof TaggableType; loading: boolean; items: UngroupedTag[]; sidepanel?: SidepanelType }) => {
  const history = useHistory()

  const {
    teamContextValue: { teamNumber }
  } = useContext(TeamContext)

  const { taggableType, loading: initialAppliedTagsLoading, items: initialAppliedTags, sidepanel = true } = props
  const [appliedTags, setAppliedTags] = useState<UngroupedTag[]>(initialAppliedTags)

  const { lookUpTagValues, loading: tagsOptionsLoading, tagValues, forceAbort, reset: tagValuesReset } = useLookUpTagValues(taggableType)

  const [initialTagValues, setInitialTagValues] = useState<typeof tagValues>()

  const filteredTagsList = tagValues?.reduce((acc, { categoryName, tagName, entityCount }) => {
    const isActive = !!appliedTags?.find((appliedTag) => appliedTag.categoryName === categoryName && appliedTag.tagName === tagName)
    return { ...acc, [categoryName]: { ...acc[categoryName], [tagName]: { isActive, entityCount } } }
  }, {} as LocalTagsListItems)

  const tagsList = initialTagValues?.reduce((acc, { categoryName, tagName, entityCount }) => {
    const isActive = !!appliedTags?.find((appliedTag) => appliedTag.categoryName === categoryName && appliedTag.tagName === tagName)
    return { ...acc, [categoryName]: { ...acc[categoryName], [tagName]: { isActive, entityCount } } }
  }, {} as LocalTagsListItems)

  const debouncedLoadTagsOptions = useCallback(
    _.debounce(
      async (searchTerm?: string, appliedTags?: UngroupedTag[]) => {
        const tagsData = await lookUpTagValues(searchTerm)
        return tagsData?.reduce((acc, { categoryName, tagName, entityCount }) => {
          const isActive = !!appliedTags?.find((appliedTag) => appliedTag.categoryName === categoryName && appliedTag.tagName === tagName)
          return { ...acc, [categoryName]: { ...acc[categoryName], [tagName]: { isActive, entityCount } } }
        }, {} as LocalTagsListItems)
      },
      300,
      { leading: true }
    ),
    [lookUpTagValues]
  )

  useEffect(() => {
    if (!initialTagValues && tagValues) {
      setInitialTagValues(tagValues)
    }
  }, [tagValues, initialTagValues])

  const isReady = !!(!initialAppliedTagsLoading && tagsList)

  const { updateParent } = useSidepanelPayloads(sidepanel)

  const flatTags = Object.keys(tagsList || {}).flatMap((categoryName) =>
    Object.keys(tagsList?.[categoryName] || {}).flatMap((tagName) => ({ categoryName, tagName, ...(tagsList?.[categoryName][tagName] || {}) }))
  )
  const activeTags = flatTags.filter(({ isActive }) => isActive)
  const unActiveTags = flatTags.filter(({ isActive }) => !isActive)

  const newAssignedTags = activeTags
    .filter((activeTag) => !appliedTags.find(({ categoryName, tagName }) => activeTag.categoryName === categoryName && activeTag.tagName === tagName))
    .map(({ categoryName, tagName }) => ({ categoryName, tagName }))

  const unAssignedTags = unActiveTags
    .filter((unActiveTag) =>
      appliedTags.find(({ categoryName, tagName }) => unActiveTag.categoryName === categoryName && unActiveTag.tagName === tagName)
    )
    .map(({ categoryName, tagName }) => ({ categoryName, tagName }))

  const totalActive = activeTags.length

  const isDirty = !!(unAssignedTags.length || newAssignedTags.length)

  const items = filteredTagsList
    ? Object.keys(filteredTagsList).reduce(
        (acc, categoryName) => ({ ...acc, [categoryName]: filteredTagsList[categoryName] || {} }),
        {} as LocalTagsListItems
      )
    : {}

  const handleSave = async (identifiers: string[], successUrl: string) => {
    const body = {
      teamNumber,
      taggableType: TaggableType[taggableType],
      tagRequests: identifiers.map((id) => ({ identifier: id, categorizedTags: newAssignedTags })),
      untagRequests: identifiers.map((id) => ({ identifier: id, categorizedTags: unAssignedTags }))
    }

    const res = await post<{ tagResponses: [{ identifier: string; tags: UngroupedTag[] }] }>('/tags/tag', body)

    if (res) {
      updateParent({ action: 'UPDATE_TAGS', value: res.tagResponses })
      history.replace(successUrl)
    }
  }

  const setFilteredTags = useCallback(
    async (searchValue?: string) => {
      if (!initialAppliedTagsLoading) {
        forceAbort()
        debouncedLoadTagsOptions.cancel()
        debouncedLoadTagsOptions(searchValue, appliedTags)
      }
    },
    [initialAppliedTagsLoading, forceAbort, debouncedLoadTagsOptions, appliedTags]
  )

  const clearSelected = () => {
    if (tagsList) {
      setAppliedTags([])
    }
  }

  const toggleActive = (categoryName: string, tagName: string, checked: boolean) => {
    setAppliedTags((prevState) =>
      checked
        ? [...prevState, { categoryName, tagName }]
        : prevState.filter((pTagsData) => !(pTagsData.categoryName === categoryName && pTagsData.tagName === tagName))
    )
  }

  const reset = useCallback(() => {
    tagValuesReset()
    setInitialTagValues(undefined)
    setAppliedTags(initialAppliedTags)
  }, [initialAppliedTags, tagValuesReset])

  return {
    items,
    activeTags,
    itemsLoading: tagsOptionsLoading,
    isReady,
    isDirty,
    totalActive,
    handleSave,
    clearSelected,
    toggleActive,
    reset,
    setFilteredTags,
    initialTagsProvided: !!Object.keys(tagsList || {}).length
  }
}

export default useTagsAssign
