import { useSnackbar } from 'notistack'

import { post } from 'utils/httpUtils'

type EntityInfo = { entityInfo: { md5: string } | { personInfo: Partial<PersonInfo> } | { companyInfo: Partial<CompanyInfo> } }

type AuditSubmitPayload = {
  anchorMd5: AuditEntity['id']
  name: AuditEntity['name']
  entities: ({
    name: AuditEntity['name']
    identifiers: Omit<AuditIdentifier, 'invalidReason'>[]
  } & EntityInfo)[]
  invalidIdentifiers: {
    reasonForBeingInvalid: string
    identifier: Omit<AuditIdentifier, 'invalidReason'>
  }[]
}

const useAuditSubmit = (props: {
  entity: 'people' | 'companies'
  valid: (AuditEntityState & { distilledIdentifiersKeys: string[]; isPrimary: boolean; isActive: boolean })[]
  invalid: (AuditEntityState & { distilledIdentifiersKeys: string[]; isPrimary: boolean; isActive: boolean })[]
  splits: AuditSplits | undefined
}) => {
  const { splits, valid, invalid } = props
  const { enqueueSnackbar } = useSnackbar()

  const auditInfo: AuditSubmitPayload = {
    anchorMd5: valid[0]?.id,
    name: valid[0]?.name,
    entities: valid.map(({ isNew, id, name, identifiers, distilledIdentifiersKeys, isPrimary, isActive, ...entityInfo }) => ({
      name,
      identifiers: identifiers.map(({ invalidReason, ...identifier }) => identifier),
      entityInfo: {
        ...(isNew ? (props.entity === 'people' ? { personInfo: { name, ...entityInfo } } : { companyInfo: { name, ...entityInfo } }) : { md5: id })
      }
    })),
    invalidIdentifiers: invalid.length
      ? invalid[0].identifiers.map(({ invalidReason = '', ...identifier }) => ({
          reasonForBeingInvalid: invalidReason,
          identifier
        }))
      : []
  }

  const payload = {
    auditInfo,
    splits
  }

  const submit = async () => {
    const res = await post<{ ok: boolean; errorMessage: string }>(`/${props.entity}/identityresolutions`, payload)
    if (res && !res.ok) {
      enqueueSnackbar(res.errorMessage, { variant: 'error' })
    }
    return res?.ok
  }
  return submit
}

export default useAuditSubmit
