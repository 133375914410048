import { ReactNode, RefObject } from 'react'

import { useHistory, useLocation } from 'react-router-dom'
import { makeStyles } from 'tss-react/mui'

import { isSidepanel } from '_pages/sidebar'

import { useWide } from '_core/components/layout'

import usePresetScroll from '_core/hooks/usePresetScroll'
import useSidepanel from '_core/hooks/useSidepanel'

const useStyles = makeStyles()(() => ({
  link: {
    cursor: 'pointer',
    maxWidth: '100%'
  }
}))

const SidepanelLink = (props: {
  linkProps: any
  sidepanel?: SidepanelType
  onClick?: () => void
  children: ReactNode
  window?: Window
  linkRef?: RefObject<HTMLAnchorElement>
}) => {
  const { linkProps, sidepanel = true, linkRef } = props
  const wide = useWide()
  const location = useLocation()
  const history = useHistory()
  const { saveScroll } = usePresetScroll()
  const { classes } = useStyles()
  const link = wide ? `${location.pathname}?sidepanel=${linkProps.to}` : linkProps.to
  const { openedUrl } = useSidepanel()

  const handleClick = (e: any, sidepanel: SidepanelType, { index, to }: any) => {
    e.stopPropagation()
    if (to === openedUrl) {
      return
    }

    const name = typeof sidepanel === 'string' ? sidepanel : 'sidepanel'
    if (!wide) {
      saveScroll(index)
      if (isSidepanel('sidepanel')) {
        window.parent.postMessage(`sidepanel_link_click:${to}`, '*')
      } else {
        history.push(link)
      }
    }

    const iframe = (props.window || window).document.querySelector<HTMLIFrameElement>(`iframe[name=${name}]`)
    if (e.shiftKey) {
      history.push(to)
      if (props.onClick) props.onClick()
      e.preventDefault()
      return
    }

    if (wide && sidepanel && iframe) {
      if (typeof sidepanel === 'boolean') {
        window.postMessage(`sidepanel_open:${to}`, '*')
      }
      iframe.contentWindow?.postMessage(`${name}:${to}`, iframe.src)
      if (props.onClick) props.onClick()
      e.preventDefault()
    }
  }

  return (
    <>
      {linkProps.to && (
        <span ref={linkRef} className={classes.link} onClick={(e) => handleClick(e, sidepanel, linkProps)} {...{ ...props.linkProps, to: link }}>
          {props.children}
        </span>
      )}
      {!linkProps.to && props.children}
    </>
  )
}

export default SidepanelLink
