import React, { useEffect } from 'react'

import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react'
import { Route, Redirect, Switch } from 'react-router-dom'

import LogoutPage from '_pages/logout'
import OutlookDialog from '_pages/outlook/dialog'

import TeamContextProvider from '_core/context/TeamContext'

import { withErrors } from '_core/ErrorBoundary'

import Login from 'Auth/Login'
import LoggedoutPage from 'Auth/LogoutPage'

import Paths from 'Paths'

import MainPage from './Layout'
import LayoutContextProvider from './Layout/LayoutContextProvider'

import './fontawesome-library'

const App = ({ isBrowserSupported = true }: { isBrowserSupported?: boolean }) => {
  if (!isBrowserSupported) {
    const type: keyof CustomErrorType = 'notSupportedBrowser'
    throw { type }
  }

  if (window.location.pathname === '/outlook') {
    return <OutlookDialog />
  }

  if (window.location.pathname === '/logout') {
    return <LogoutPage />
  }

  return (
    <>
      <Route exact path={Paths.login} render={withErrors(Login)} />
      <AuthenticatedTemplate>
        <div className="App">
          <LayoutContextProvider>
            <TeamContextProvider>
              <Route path={Paths._home} component={MainPage} />
            </TeamContextProvider>
          </LayoutContextProvider>
        </div>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <Switch>
          <Route exact path={Paths._logoutPage} render={withErrors(LoggedoutPage)} />
          <Redirect to={Paths.login} />
        </Switch>
      </UnauthenticatedTemplate>
    </>
  )
}

export default App
