import React from 'react'

import { Badge as MuiBadge, BadgeProps, Box } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

import Skeleton from '_shared/Skeleton'

type StyleProps = {
  size?: 'lg' | 'md' | 'sm' | 'xs'
}

const useStyles = makeStyles()((theme) => ({
  badge: {
    borderRadius: '100%',
    color: '#FFFFFF',
    cursor: 'default'
  },
  bgBadge: {
    backgroundColor: '#1684A7'
  },
  bgScore: {
    backgroundColor: theme.palette.primary.main
  },
  xs: {
    width: 16,
    minWidth: 16,
    height: 16,
    fontSize: 10,
    lineHeight: '10px'
  },
  sm: {
    width: 25,
    minWidth: 25,
    height: 25,
    fontSize: 12,
    lineHeight: '12px'
  },
  md: {
    width: 32,
    minWidth: 32,
    height: 32,
    fontSize: 16,
    lineHeight: '16px'
  },
  lg: {
    width: 36,
    minWidth: 36,
    height: 36,
    fontSize: 18,
    lineHeight: '18px'
  }
}))

const Badge = ({ size = 'sm', className, ...props }: Exclude<BadgeProps, 'classes'> & StyleProps) => {
  const { classes, cx } = useStyles()

  return <MuiBadge {...props} classes={{ badge: cx(classes.badge, classes.bgBadge, className, classes[size]) }} />
}

export const Score = ({
  value,
  size = 'sm',
  className,
  skClassName,
  condition = !value
}: { value?: string | number; className?: string; skClassName?: string; condition?: boolean } & StyleProps) => {
  const { classes, cx } = useStyles()

  return (
    <Skeleton condition={condition} variant="circular" className={cx(classes[size], skClassName)}>
      {(typeof value === 'string' && !!value) ||
        (typeof value === 'number' && value > -1 && (
          <Box display="flex" alignItems="center" justifyContent="center" className={cx(classes.badge, classes.bgScore, classes[size], className)}>
            {value}
          </Box>
        ))}
    </Skeleton>
  )
}

export default Badge
