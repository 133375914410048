import React, { ComponentProps } from 'react'

import { Box, useMediaQuery } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

import { AvatarWrapper } from '_shared/Avatar'
import Skeleton from '_shared/Skeleton'
import Tooltip from '_shared/Tooltip'
import Typography from '_shared/Typography'

import { AddTagTriggerEl, ExtraTagsPopover, InternalTagPopover, ShowAllTagsLink } from '_core/components/InternalTag'
import MarketDataSummary, { useStyles as useCommonStyles } from '_core/components/MarketDataSummary'
import TagsGroup from '_core/components/TagsGroup'

import { groupTags } from '_core/hooks/useTagsManager'

import { ellipsis } from 'AppTheme'

import { Wide, Narrow, useWide } from './layout'
import MiniProfileSummary from './MiniProfileSummary'

const useStyles = makeStyles<{ flip?: boolean }>()((theme, { flip }) => ({
  byline: {
    width: '100%'
  },
  actions: {
    width: '100%',
    minWidth: '70px',
    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns: 'repeat(auto-fit, minmax(0px, max-content))',
    gridTemplateRows: '1fr',
    marginTop: theme.spacing(2),
    marginBottom: 0
  },
  action: {
    display: 'flex',
    marginRight: '22px',
    '& > a, & > p': {
      ...ellipsis
    },
    '&:last-of-type': {
      marginRight: 0
    }
  },
  wrapper: {
    display: 'flex',
    flexDirection: flip ? 'row-reverse' : 'row',
    margin: '0px auto',
    maxWidth: '90%',
    [theme.breakpoints.up('phone')]: {
      maxWidth: '80%'
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: '100%',
      justifyContent: 'flex-start',
      position: 'relative',
      minWidth: '32%',
      '&::after': {
        content: '""',
        position: 'absolute',
        width: '0.5px',
        height: '100%',
        background: '#ECEEF0',
        left: flip ? 'inherit' : 100,
        right: flip ? 100 : 'inherit'
      }
    }
  },
  container: {
    marginLeft: flip ? 0 : theme.spacing(3),
    marginRight: flip ? theme.spacing(3) : 0,
    paddingTop: theme.spacing(2),
    maxWidth: `calc(100% - 80px - ${theme.spacing(3)})`,
    '& > p': {
      paddingTop: '4px'
    },
    [theme.breakpoints.up('sm')]: {
      maxWidth: '60%',
      marginLeft: flip ? 0 : theme.spacing(5)
    },
    [theme.breakpoints.up('md')]: {
      paddingTop: 0
    }
  },
  avatar__wrapper: {
    width: '80px',
    display: 'flex',
    justifyContent: flip ? 'flex-end' : 'flex-start',
    marginTop: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      width: '100px',
      marginTop: 0
    }
  }
}))

const ProfileSummary = (props: ProfileSummaryProps & ProfileSummaryTagsProps) => {
  const wide = useWide()
  const {
    score,
    title,
    userKey,
    byline,
    byline2,
    actions,
    loading,
    introducers,
    url,
    flip,
    budge,
    variant = 'default',
    editTagsLink,
    showAllTagsLink
  } = props
  const { classes } = useStyles({ flip })
  const {
    classes: { nameWrapper, name, buttons }
  } = useCommonStyles({ flip })
  const minicard = useMediaQuery('(max-height:160px)')

  if (introducers && minicard) return <MiniProfileSummary {...props} />
  if (variant === 'marketData' && editTagsLink && showAllTagsLink) {
    return <MarketDataSummary {...props} showAllTagsLink={showAllTagsLink} editTagsLink={editTagsLink} />
  }

  return (
    <>
      <Box className={classes.wrapper}>
        <Box className={classes.avatar__wrapper}>
          <AvatarWrapper name={loading ? '' : title} score={score} userKey={userKey} size="lg" mode="dark" logoUrl={url} hideName />
        </Box>
        <Box className={classes.container}>
          <Box className={nameWrapper}>
            {(loading || title) && (
              <Skeleton condition={!title} width="100%">
                <Tooltip title={title || ''} placement="bottom-start" disabled={wide}>
                  <Typography variant="h4" className={name} ellipsis semiBold>
                    {title || 'Placeholder Name'}
                  </Typography>
                </Tooltip>
              </Skeleton>
            )}
            {!loading && budge && <>{budge}</>}
            {!loading && editTagsLink && (
              <Wide>
                <Box ml={1.5}>
                  <TagsGroup<ComponentProps<typeof InternalTagPopover>['tagData']>
                    items={props.tags}
                    tagComponent={InternalTagPopover}
                    renderShowAll={({ extraTagsAmount }: { extraTagsAmount: number }) => (
                      <ExtraTagsPopover
                        triggerElement={<ShowAllTagsLink extraTagsAmount={extraTagsAmount} link={showAllTagsLink} />}
                        items={props.tags ? groupTags(props.tags) : props.tags}
                      />
                    )}
                    addTagTriggerEl={<AddTagTriggerEl link={editTagsLink} hasAny={!!props.tags?.length} />}
                  />
                </Box>
              </Wide>
            )}
          </Box>
          {!loading && editTagsLink && showAllTagsLink && (
            <Narrow>
              <Box mt={1}>
                <TagsGroup<ComponentProps<typeof InternalTagPopover>['tagData']>
                  items={props.tags}
                  tagComponent={InternalTagPopover}
                  renderShowAll={({ extraTagsAmount }: { extraTagsAmount: number }) => (
                    <ExtraTagsPopover
                      triggerElement={<ShowAllTagsLink extraTagsAmount={extraTagsAmount} link={showAllTagsLink} />}
                      items={props.tags ? groupTags(props.tags) : props.tags}
                    />
                  )}
                  addTagTriggerEl={<AddTagTriggerEl link={editTagsLink} hasAny={!!props.tags?.length} />}
                />
              </Box>
            </Narrow>
          )}

          {(loading || byline) && (
            <Skeleton condition={loading} width="80%" height={20}>
              <Tooltip title={typeof byline === 'string' ? byline : ''} placement="bottom-start">
                <Typography variant="body1" color="text.secondary" ellipsis className={classes.byline}>
                  {byline}
                </Typography>
              </Tooltip>
            </Skeleton>
          )}

          {(loading || byline2) && (
            <Skeleton condition={loading} width="70%" height={20}>
              {byline2}
            </Skeleton>
          )}

          {actions && (
            <Box className={classes.actions}>
              {actions.map((action, index) => (
                <Box className={classes.action} key={index}>
                  {action}
                </Box>
              ))}
            </Box>
          )}
        </Box>
        {buttons && (
          <Wide>
            <Box className={buttons}>{props.buttons}</Box>
          </Wide>
        )}
      </Box>
      {buttons && (
        <Narrow>
          <Box className={buttons} alignItems="center">
            {props.buttons}
          </Box>
        </Narrow>
      )}
    </>
  )
}

export default ProfileSummary
