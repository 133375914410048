import { useEffect, useState } from 'react'

import useAdmin from '_core/hooks/useAdmin'
import useUserDataVisibility from '_core/hooks/useUserDataVisibility'

const useEnableDownloading = () => {
  const [enableDownloading, setEnableDownloading] = useState<boolean | null>(null)
  const admin = useAdmin()
  const { dataVisibility } = useUserDataVisibility()

  useEffect(() => {
    if (typeof admin === 'boolean') {
      setEnableDownloading(admin || !dataVisibility?.preventNonAdminDownload)
    }
  }, [admin, dataVisibility?.preventNonAdminDownload])

  return enableDownloading
}

export default useEnableDownloading