import React from 'react'

import Page from '_shared/Page'

import RelationshipUserCompany from './[userId]/companies/[companyId]'

const RelationshipsPage = (props: any) => {
  return (
    <Page>
      <RelationshipUserCompany {...props} />
    </Page>
  )
}

export default RelationshipsPage
