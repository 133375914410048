import { useEffect } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box } from '@mui/material'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { makeStyles } from 'tss-react/mui'

import { isSidepanel } from '_pages/sidebar'

import { Button } from '_shared/buttons'
import Typography from '_shared/Typography'

import Success from '_core/components/Success'

import useSidepanelClose from '_core/hooks/useSidepanelClose'
import useSidepanelPayloads from '_core/hooks/useSidepanelPayloads'

import { checkOutlook } from '_core/helpers/outlook'

import { DataAdminSubPaths } from 'Paths'

const useStyles = makeStyles()((theme) => ({
  linkBtn: {
    padding: 0,
    fontSize: theme.spacing(2),
    lineHeight: 1.43
  },
  closeButton: {
    marginTop: theme.spacing(2)
  }
}))

const parentPageIsDataUploads =
  window.name === 'extension' || checkOutlook() || !isSidepanel() ? false : window.parent.location.pathname === DataAdminSubPaths.dataUploads

const UploadSuccess = ({ agent }: { agent: string }) => {
  const { classes } = useStyles()
  const sidepanel = isSidepanel('sidepanel')
  const { updateParent } = useSidepanelPayloads()
  const history = useHistory()
  const match = useRouteMatch()
  const handleClose = useSidepanelClose(DataAdminSubPaths.dataUploads)

  useEffect(() => {
    if (sidepanel && parentPageIsDataUploads) {
      updateParent({ action: 'RELOAD_LIST', value: 'data-uploads' })
    }
  }, [sidepanel, parentPageIsDataUploads])

  const redirectToDataUploads = () => {
    if (sidepanel) window.parent.location.href = DataAdminSubPaths.dataUploads
    handleClose()
  }

  const handleUploadMore = () => {
    history.replace(`${match.url}`.replace('/success', ''))
  }

  return (
    <Success
      variant="centered"
      text={
        <>
          <Typography>Your upload was successfully queued for processing.</Typography>
          {!parentPageIsDataUploads && (
            <Typography>
              You can view this and other uploads by visiting the{' '}
              <Button variant="link" onClick={redirectToDataUploads} className={classes.linkBtn}>
                upload history page
              </Button>
              .
            </Typography>
          )}
        </>
      }
    >
      <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
        <Button
          variant="outlined"
          color="primary"
          startIcon={<FontAwesomeIcon icon={['far', 'cloud-arrow-up']} style={{ fontSize: 14 }} />}
          onClick={handleUploadMore}
        >
          Upload more {agent}
        </Button>
        <Button variant="text" onClick={handleClose} color="secondary" className={classes.closeButton}>
          Close
        </Button>
      </Box>
    </Success>
  )
}

export default UploadSuccess
