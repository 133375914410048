import { useEffect, useState } from 'react'

import { Box, SelectChangeEvent } from '@mui/material'
import { Moment as MomentType } from 'moment'
import { makeStyles } from 'tss-react/mui'

import Select from '_shared/forms/Select'
import Typography from '_shared/Typography'

import useDealsFiltersOptions from '_core/hooks/useDealsFiltersOptions'
import useSearchQuery from '_core/hooks/useSearchQuery'

import { formatDate } from 'utils/Utils'

import { PeriodControl } from './controls'
import { dateFilterOptions, daysOptions, wasOptions } from './data'

import Filters from './index'

const useStyles = makeStyles()((theme) => ({
  container: {
    [theme.breakpoints.up('md')]: {
      width: 275,
      overflow: 'auto',
      height: '100%'
    }
  },
  block: {
    marginBottom: theme.spacing(1.5),
    display: 'flex',
    flexDirection: 'column',
    '& .MuiTypography-h4, & .MuiTypography-body1': {
      marginBottom: theme.spacing(1)
    }
  },
  header: {
    marginBottom: theme.spacing(1)
  },
  label: {
    display: 'flex',
    alignItems: 'center'
  },
  select: {
    maxWidth: 200
  },
  divider: {
    margin: `${theme.spacing(0.5)} -${theme.spacing(2.5)} ${theme.spacing(1.5)}`
  }
}))

export type DealsFiltersType = {
  opened: boolean
  disabled: boolean
  contentLoading: boolean
  total?: number
  className?: string
  anchorEl?: HTMLElement | null
  toggleOpen: () => void
  handleChange: (params: ModifiedDealsPageParams) => void
  reset: () => Promise<ModifiedDealsPageParams | undefined>
  range: Pick<ModifiedDealsPageParams, 'from' | 'to'> | undefined
  handleDateChange: ({ picker, value }: { picker: 'from' | 'to'; value: MomentType | null }) => void
}

const DealsFilters = (props: DealsFiltersType) => {
  const { classes, cx } = useStyles()
  const { total, className, opened, disabled, contentLoading, anchorEl, handleChange, range, handleDateChange, toggleOpen } = props
  const dealsOptions = useDealsFiltersOptions()

  const { queryParams } = useSearchQuery<
    DealsPageParams,
    { modifyProps: [{ roles: IncludeDeals[]; stages: IncludeDeals[]; types: IncludeDeals[] }] }
  >(['roles', 'stages', 'types'])
  const { where, period, days } = queryParams

  const blocks: { title: string; name: keyof Pick<ModifiedDealsPageParams, 'stages' | 'roles' | 'types'> }[] = [
    { title: 'Stage', name: 'stages' },
    { title: 'Role', name: 'roles' },
    { title: 'Type', name: 'types' }
  ]

  const [daysValue, setDaysValue] = useState<{ [key in Lowercase<PeriodOptionsType>]: DaysOptions }>({
    within: daysOptions[0].value,
    after: daysOptions[0].value
  })
  const { within, after } = daysValue

  useEffect(() => {
    if (days && period) {
      setDaysValue((prev) => ({ ...prev, [period.toLowerCase()]: +days }))
    }
  }, [days, period])

  const handleSelect = (e: SelectChangeEvent<unknown>) => {
    const { name, value } = e.target as { name: keyof DealsPageParams; value: DealsPageParams & 'Any' }
    handleChange({ [name]: value === 'Any' ? null : [value] })
  }

  const handleDateFilterChange = (e: SelectChangeEvent<unknown>) => {
    const { name, value } = e.target
    if (name) {
      handleChange({ [name]: value })
    }
  }

  const handleDateError = ({ picker, reason, value }: { picker: 'from' | 'to'; reason: string | null; value: MomentType | null }) => {
    const val = value ? formatDate(value) : null
    if (range && range[picker] === val) {
      handleChange({ [picker]: !reason && value?.isValid() ? formatDate(value) : null })
    }
  }

  const handleDaysChange = (e: SelectChangeEvent<unknown>) => {
    const { name, value } = e.target as { name: string; value: DaysOptions }

    if (name) {
      handleChange({ days: `${value}` })
    }
  }

  const handlePeriodChange = (e: SelectChangeEvent<unknown>) => {
    const { name, value } = e.target as { name: string; value: string }

    const valuesMap: { [key: typeof name]: {} } = {
      Anytime: { [name]: undefined, days: undefined, from: undefined, to: undefined },
      Custom: { [name]: value, days: undefined, from: range?.from, to: range?.to }
    }

    handleChange(
      valuesMap[value]
        ? valuesMap[value]
        : { [name]: value, days: `${daysValue[value.toLowerCase() as keyof typeof daysValue]}`, from: undefined, to: undefined }
    )
  }

  const mappedWasOpts = wasOptions.map(({ value, label }) => ({
    value,
    label,
    disabled: value !== period,
    selectValue: value === 'Within' ? within : after,
    includeSelector: value !== 'Anytime'
  }))

  const periodOptions = [
    ...mappedWasOpts.slice(0, wasOptions.length - 1),
    {
      value: 'Custom',
      label: 'Custom',
      disabled: period !== 'Custom',
      includeRange: true,
      from: range?.from,
      to: range?.to,
      focusTriggered: period === 'Custom'
    },
    ...mappedWasOpts.slice(-1)
  ]

  const reset = async () => {
    const newSearchQuery = await props.reset()

    if (newSearchQuery) {
      const { days, period } = newSearchQuery
      if (period && period !== 'Custom' && days) {
        const prd = period.toLowerCase() as keyof typeof daysValue
        const daysWithoutCurPrd = { ...daysValue }
        delete daysWithoutCurPrd[prd]

        const restKeys = Object.keys(daysWithoutCurPrd)
          .filter((key) => key !== period?.toLowerCase())
          .reduce((acc, key) => ({ ...acc, [key]: 7 }), daysWithoutCurPrd)

        setDaysValue({ ...{ [prd]: days }, ...restKeys })
      } else {
        setDaysValue(Object.keys(daysValue).reduce((acc, key) => ({ ...acc, [key]: 7 }), daysValue))
      }
    }
  }

  return (
    <Filters
      opened={opened}
      className={cx(classes.container, className)}
      contentLoading={contentLoading}
      total={total}
      anchorEl={anchorEl || null}
      disabled={disabled}
      reset={reset}
      toggleOpen={toggleOpen}
    >
      <Box className={classes.block}>
        {dealsOptions[blocks[0].name as keyof typeof dealsOptions].length > 0 &&
          blocks.map((block) => {
            const options = dealsOptions[block.name as keyof typeof dealsOptions]
            return (
              <Box className={classes.block} key={block.name}>
                <Typography variant="h4" semiBold className={classes.header}>
                  {block.title}
                </Typography>
                <Select
                  size="small"
                  name={block.name}
                  disabled={disabled || !options.length}
                  options={options}
                  value={queryParams[block.name as keyof DealsPageParams]?.[0] || options[0]?.value}
                  onChange={handleSelect}
                  fullWidth
                />
              </Box>
            )
          })}
      </Box>

      <Box className={classes.block}>
        <Typography variant="h4" semiBold classes={{ root: classes.label }} component="label">
          Where the date&nbsp;
          <Select
            fullWidth
            size="small"
            variant="standard"
            value={where}
            name="where"
            options={dateFilterOptions}
            onChange={handleDateFilterChange}
            className={classes.select}
            disabled={disabled}
          />
          &nbsp;was
        </Typography>
      </Box>
      <Box className={classes.block}>
        <PeriodControl
          options={periodOptions}
          disabled={disabled}
          period={period || 'Anytime'}
          handlePeriodChange={handlePeriodChange}
          handleDaysChange={handleDaysChange}
          handleDateChange={handleDateChange}
          handleDateError={handleDateError}
        />
      </Box>
    </Filters>
  )
}

export { Controller } from './index'
export default DealsFilters
