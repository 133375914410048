import { SyntheticEvent, useCallback, useEffect, useState } from 'react'

import { AutocompleteInputChangeReason } from '@mui/material'
import _ from 'lodash'

const useAsyncCombobox = <T, Multiple extends boolean | undefined = false>(props: {
  loadOnFocus?: boolean
  initialValue?: (Multiple extends true ? T[] : T) | null
  loadOptions: (searchTerm: string, value?: (Multiple extends true ? T[] : T) | null) => Promise<T[] | undefined>
  forceAbort: () => void
}) => {
  const { initialValue = null, loadOnFocus = false, loadOptions, forceAbort } = props

  const [open, setOpen] = useState(false)
  const [value, setValue] = useState<(Multiple extends true ? T[] : T) | null>(initialValue)
  const [inputValue, setInputValue] = useState('')
  const [isResetChangeInputReason, setIsResetChangeInputReason] = useState(false)

  const [{ options, loading }, setState] = useState<{ options: T[]; loading: boolean }>({ options: [], loading: false })

  const setLoading = () => {
    setState({ options: [], loading: true })
  }

  const setResult = (data: typeof options) => {
    setState({ loading: false, options: data })
  }

  const clearOptions = () => {
    setState((prevState) => ({ ...prevState, options: [] }))
  }

  const loadOptionsDebounced = useCallback(
    _.debounce(async (searchTerm: string = '') => {
      const result = await loadOptions(searchTerm, value)
      if (result) {
        setResult(result)
      }
    }, 300),
    [loadOptions, value]
  )

  const doFetchOnOpen = loadOnFocus && open && options.length === 0

  useEffect(() => {
    if (!inputValue && !loadOnFocus) {
      forceAbort()
      clearOptions()
      return
    }
    if (doFetchOnOpen) {
      forceAbort()
      setLoading()
      loadOptionsDebounced()
    }
  }, [doFetchOnOpen, forceAbort, inputValue, loadOnFocus, loadOptionsDebounced])

  useEffect(() => {
    if (!isResetChangeInputReason) {
      if (!loadOnFocus && inputValue) {
        forceAbort()
        setLoading()
        loadOptionsDebounced(inputValue)
      }
    }
  }, [forceAbort, inputValue, isResetChangeInputReason, loadOnFocus, loadOptionsDebounced])

  useEffect(() => {
    if (loading) {
      clearOptions()
    }
  }, [loading])

  const updateValue = (newValue: (Multiple extends true ? T[] : T) | null) => {
    setValue(newValue)
  }

  const updateInputValue = (newInputValue: string) => {
    setInputValue(newInputValue)
  }

  const handleFocus = () => {
    if (loadOnFocus) {
      setOpen(true)
    }
  }

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleInputChange = (event: SyntheticEvent<Element, Event>, newInputValue: string, reason: AutocompleteInputChangeReason) => {
    setIsResetChangeInputReason(reason === 'reset')
    updateInputValue(newInputValue)
    // The reason why clear value is done manually: if the combobox is not free solo, there no "clear" reason on the inputValue clear.
    if (!value) {
      updateValue(null)
    }
  }

  const handleValueChange = (e: SyntheticEvent<Element, Event>, value: (Multiple extends true ? T[] : T) | null) => {
    updateValue(value)
  }

  const filterOptions = !loadOnFocus ? (options: T[]) => options : undefined

  return {
    open,
    inputValue,
    value,
    options,
    optionsLoading: loading,
    clearOptions,
    handleOpen,
    handleClose,
    handleFocus,
    handleInputChange,
    handleValueChange,
    updateInputValue,
    updateValue,
    filterOptions
  }
}

export default useAsyncCombobox
