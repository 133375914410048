import {
  ReactNode,
  Fragment,
  ReactElement,
  useRef,
  cloneElement,
  useState,
  CSSProperties,
  useCallback,
  forwardRef,
  memo,
  ForwardedRef,
  SyntheticEvent
} from 'react'

import { IconName, IconPrefix, IconProp } from '@fortawesome/fontawesome-svg-core'
import { Box, SelectChangeEvent, Chip as MUIChip } from '@mui/material'
import { VariableSizeList as List, VariableSizeListProps } from 'react-window'
import { makeStyles } from 'tss-react/mui'

import { isSidepanel } from '_pages/sidebar'

import AvatarGroup from '_shared/AvatarGroup'
import { IconButton } from '_shared/buttons'
import Card, { CardContent } from '_shared/Card'
import Select from '_shared/forms/Select'
import Icon from '_shared/Icon'
import Skeleton from '_shared/Skeleton'
import Typography from '_shared/Typography'

import CollapseContainer from '_core/components/CollapseContainer'
import { Controller as FilterController } from '_core/components/filters'
import { ActivitiesFiltersType } from '_core/components/filters/Activities'
import InternalTag from '_core/components/InternalTag'
import { Narrow, Column, Columns, useWide, WideStrict, Middle } from '_core/components/layout'
import { useAutoHideOnScrollStyles } from '_core/components/layout/autohide-on-scroll'
import Timeline, { TimeLineItem, TimeContainer } from '_core/components/lists/Timeline'
import NameLink from '_core/components/NameLink'
import ProfileItem from '_core/components/ProfileItem'
import SidepanelLink from '_core/components/SidepanelLink'
import Widget from '_core/components/Widget'

import { LocalActivityItem } from '_core/hooks/useActivitiesGroups'
import useIconsUpdate from '_core/hooks/useIconsUpdate'
import useScrollPosition from '_core/hooks/useScrollPosition'
import useSearchQuery from '_core/hooks/useSearchQuery'
import useVirtualized from '_core/hooks/useVirtualized'

import { checkOutlook } from '_core/helpers/outlook'

import { formatTime } from 'utils/Utils'

import Paths from 'Paths'

export type ActivityRow = { [date: string]: { [id: string]: LocalActivityItem } } | Record<string, never>

const useStyles = makeStyles<void | Partial<{ containerWidth: number }>>()((theme, props) => ({
  headerItem: {
    flex: 1
  },
  headersWidget: {
    textAlign: 'end',
    zIndex: 3,
    marginBottom: 0
  },
  card: {
    margin: theme.spacing(1)
  },
  hiddenActivitySlot: {
    opacity: '0'
  },
  columns: {
    alignItems: 'center'
  },
  column: {
    '&:last-of-type': {
      alignItems: 'flex-end'
    }
  },
  pickFirstContainer: {
    width: props?.containerWidth,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: 'calc(100vh - 139px)',
    [theme.breakpoints.up('md')]: {
      minHeight: 'calc(100% - 107px)'
    }
  },
  chip: {
    display: 'flex',
    flexDirection: 'row-reverse',
    borderRadius: 20,
    height: 38,
    marginTop: theme.spacing(0.5),
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(0.5)
  },
  colorPrimary: {
    backgroundColor: theme.palette.secondary.main,
    '&:hover, &:focus': {
      backgroundColor: theme.palette.secondary.main
    }
  },
  colorSecondary: {
    backgroundColor: theme.palette.secondary.light,
    color: theme.palette.text.primary
  },
  clickableColorSecondary: {
    '&:hover, &:focus': {
      backgroundColor: theme.palette.hover.secondary
    }
  },
  chipLabel: {
    fontSize: 14,
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2)
  },
  collapseContainer: {
    padding: theme.spacing(2),
    margin: `0 -${theme.spacing(2)} -${theme.spacing(2)}`
  },
  moveIconHint: {
    position: 'fixed',
    bottom: 10,
    right: '50%'
  },
  timeContainer: {
    marginTop: theme.spacing(2)
  },
  timelineItem: {
    paddingRight: 0
  },
  listWrapper: {
    marginTop: 100
  }
}))

const groupByOptions: { value: ActivitiesInit['groupBy']; label: string }[] = [
  { value: 'None', label: 'No grouping' },
  { value: 'Contributor', label: 'Contributor' },
  { value: 'Contact', label: 'Contact' },
  { value: 'Company', label: 'Company' }
]

const viewOptions: { value: ActivitiesInit['view']; label: string }[] = [
  { value: 'Top', label: 'Top' },
  { value: 'Recent', label: 'Recent' },
  { value: 'Custom', label: 'Custom' }
]

export const TimeCard = memo(({ time, className }: { time: string; className?: string }) => {
  const { classes, cx } = useStyles()
  return (
    <div className={cx(classes.timeContainer, className)}>
      <Skeleton condition={!time} height={28}>
        <TimeContainer>{time || 'placeholder'}</TimeContainer>
      </Skeleton>
    </div>
  )
})

export const GroupControllers = ({
  handleChange,
  disabled
}: {
  handleChange: (params: Omit<ActivitiesPageParams, 'customList'>) => void
  disabled: boolean
}) => {
  const { classes } = useStyles()
  const { queryParams } = useSearchQuery<ActivitiesPageParams, { modifyProps: [{ customList: string[] }] }>(['customList'])
  const { groupBy = 'None', view } = queryParams

  const isViewSelectorVisible = groupBy === 'Contact' || groupBy === 'Company'

  const handleGroupByChange = (e: SelectChangeEvent<unknown>) => {
    const { name, value } = e.target as { name: string; value: ActivitiesInit['groupBy'] }
    handleChange({ [name]: value === 'None' ? undefined : value })
  }

  const handleViewChange = (e: SelectChangeEvent<unknown>) => {
    const { name, value } = e.target as { name: string; value: ActivitiesInit['view'] }
    handleChange({ [name]: value })
  }

  return (
    <>
      <WideStrict>
        <Box display="flex" flex={1} justifyContent="flex-end">
          {isViewSelectorVisible && (
            <Box mr={2}>
              <Select value={view} name="view" options={viewOptions} disabled={disabled} onChange={handleViewChange} />
            </Box>
          )}
          <Select value={groupBy || 'None'} name="groupBy" options={groupByOptions} disabled={disabled} onChange={handleGroupByChange} />
        </Box>
      </WideStrict>
      <Middle>
        <Box display="flex" flex={1} justifyContent="flex-end">
          {isViewSelectorVisible && (
            <Box mr={2} flex={1}>
              <Select fullWidth value={view} name="view" options={viewOptions} disabled={disabled} onChange={handleViewChange} />
            </Box>
          )}
          <Box flex={isViewSelectorVisible ? 1 : 0}>
            <Select
              fullWidth={isViewSelectorVisible}
              value={groupBy || 'None'}
              name="groupBy"
              options={groupByOptions}
              disabled={disabled}
              onChange={handleGroupByChange}
            />
          </Box>
        </Box>
      </Middle>
      <Narrow>
        <Typography bold>Group By</Typography>
        <Box display="flex" flexWrap="wrap" my={1.5}>
          {groupByOptions.map(({ value, label }) => (
            <MUIChip
              key={value}
              label={label}
              color={groupBy === value ? 'primary' : 'secondary'}
              size="medium"
              onClick={() => handleChange({ groupBy: value === 'None' ? undefined : value })}
              classes={{
                root: classes.chip,
                colorPrimary: classes.colorPrimary,
                colorSecondary: classes.colorSecondary,
                clickableColorSecondary: classes.clickableColorSecondary,
                label: classes.chipLabel
              }}
            />
          ))}
        </Box>
        {isViewSelectorVisible && (
          <>
            <Typography bold>View</Typography>
            <Box display="flex" flexWrap="wrap" mt={1.5} mb={-0.5}>
              {viewOptions.map(({ value, label }) => (
                <MUIChip
                  key={value}
                  label={label}
                  color={view === value ? 'primary' : 'secondary'}
                  size="medium"
                  onClick={() => handleChange({ view: value })}
                  classes={{
                    root: classes.chip,
                    colorPrimary: classes.colorPrimary,
                    colorSecondary: classes.colorSecondary,
                    clickableColorSecondary: classes.clickableColorSecondary,
                    label: classes.chipLabel
                  }}
                />
              ))}
            </Box>
          </>
        )}
      </Narrow>
    </>
  )
}

export const Heading = ({
  filters,
  filtersProps,
  children,
  handleChange
}: {
  children: ReactNode
  filters: ReactElement
  handleChange: (params: Omit<ActivitiesPageParams, 'customList'>) => void
  filtersProps: Pick<ActivitiesFiltersType, 'opened' | 'disabled' | 'toggleOpen'>
}) => {
  const { classes } = useStyles()
  const { opened: filtersOpened, toggleOpen: toggleFilterOpen, disabled } = filtersProps
  const { autoHideClassName } = useAutoHideOnScrollStyles(true)
  const hasSidebar = isSidepanel() || checkOutlook()
  const anchorRef = useRef<HTMLDivElement | null>(null)
  const [collapsed, setCollapsed] = useState(true)

  const toggleViewOpened = () => {
    setCollapsed((prevState: boolean) => !prevState)
  }

  return (
    <Widget className={autoHideClassName} sticky={hasSidebar ? 88 : 60}>
      <div ref={anchorRef}>
        <Box display="flex">
          {children}
          <IconButton
            disableRipple
            color={collapsed ? 'secondary' : 'primary'}
            hint={collapsed ? 'Show view option' : 'Hide view option'}
            size="small"
            onClick={toggleViewOpened}
            icon={['far', 'sliders']}
            disabled={disabled}
          />
          <FilterController opened={filtersOpened} toggleOpen={toggleFilterOpen} disabled={disabled} />
        </Box>
      </div>
      {cloneElement(filters, { anchorEl: anchorRef.current })}
      <CollapseContainer collapsed={collapsed} className={classes.collapseContainer}>
        <GroupControllers handleChange={handleChange} disabled={disabled} />
      </CollapseContainer>
    </Widget>
  )
}

export const ActivityDetails = (
  props: Omit<LocalActivityItem, 'action' | 'companies'> & {
    isInternal: boolean
    actionText: string
    icon: IconProp | null
    loading: boolean
    detailsLink: string | null
    companies: string[]
    time: string
    className?: string
  }
) => {
  const { from, to, time, actionText, icon, loading, isInternal, detailsLink, companies, className } = props
  const { classes, cx } = useStyles()
  const [detailsPath, detailsSearch] = detailsLink?.split('?') || []

  const fromCutted = from.slice(0, 2)
  const fromDiff = from.length - fromCutted.length

  const toCutted = to.slice(0, 2)
  const toDiff = to.length - toCutted.length

  const timeEl = (
    <Skeleton condition={loading}>
      <Typography color="text.secondary">{time}</Typography>
    </Skeleton>
  )

  const messageEl = (
    <Skeleton condition={loading}>
      <Typography>
        {fromCutted.map(({ id, name }, i) => (
          <Fragment key={id}>
            <NameLink url={`${Paths._people}/${id}`} name={name} />
            {fromDiff === 0 && from.length - i === 2 ? ' and ' : fromCutted.length - i > 1 ? ', ' : ' '}
          </Fragment>
        ))}
        {fromDiff > 0 && `and ${fromDiff} more ${fromDiff > 1 ? 'people' : 'person'} `}
        {actionText || 'long placeholder'}{' '}
        {toCutted.map(({ id, name }, i) => (
          <Fragment key={id}>
            <NameLink url={`${Paths._people}/${id}`} name={name} />
            {toDiff === 0 && to.length - i === 2 ? ' and ' : toCutted.length - i > 1 ? ', ' : ''}
          </Fragment>
        ))}
        {toDiff > 0 && `and ${toDiff} more ${toDiff > 1 ? 'people' : 'person'} `}
      </Typography>
    </Skeleton>
  )

  return (
    <SidepanelLink linkProps={{ to: detailsLink }}>
      <Card className={cx({ [classes.hiddenActivitySlot]: !actionText && !loading }, classes.card, className)}>
        <CardContent>
          <Columns spacing={0}>
            <Column md={6} xs={6}>
              {icon && <Icon icon={icon} contained containerSize="lg" />}
            </Column>
            <Column md={6} xs={6} className={classes.column}>
              {!loading && <InternalTag label={isInternal ? 'Internal' : 'External'} />}
            </Column>
          </Columns>
          <Box pt={0.5} pb={1}>
            {timeEl}
            {messageEl}
          </Box>
          <Columns spacing={0}>
            <Column md={6} xs={6}>
              <AvatarGroup
                max={3}
                skeleton={{ size: 3, loading }}
                hideName={true}
                sidepanel={true}
                size="xs"
                seeAllLink={detailsPath && detailsSearch ? `${detailsPath}/participants?${detailsSearch}` : ''}
                items={[...from.slice(0, 1), ...to.slice(0, 1), ...from.slice(1), ...to.slice(1)].map((item) => ({
                  name: item.name,
                  userKey: item.email,
                  link: `${Paths._people}/${item.id}`
                }))}
              />
            </Column>
            <Column md={6} xs={6} className={classes.column}>
              <AvatarGroup
                max={3}
                skeleton={{ size: 3, loading }}
                hideName={true}
                sidepanel={true}
                size="xs"
                seeAllLink={detailsPath && detailsSearch ? `${detailsPath}/companies${detailsSearch}` : ''}
                items={companies.map((domain) => ({
                  name: domain,
                  logoUrl: domain,
                  link: `${Paths._companies}/${domain}`
                }))}
              />
            </Column>
          </Columns>
        </CardContent>
      </Card>
    </SidepanelLink>
  )
}

export const PickFirstContainer = ({ width, children }: { width: number; children: ReactNode }) => {
  const { classes } = useStyles({ containerWidth: width })
  return <div className={classes.pickFirstContainer}>{children}</div>
}

export const ColumnHeader = (props: {
  name: string
  md5: string
  userKey?: string
  logoUrl?: string
  link: string
  isLastChild: boolean
  style?: CSSProperties
  onDelete: (md5: string) => void
  actionButton: ReactElement | null
}) => {
  const { name, md5, userKey, logoUrl, link, onDelete, isLastChild, style, actionButton } = props
  const { classes } = useStyles()

  const handleDelete = (e: SyntheticEvent) => {
    e.stopPropagation()
    onDelete(md5)
  }

  return (
    <div style={style}>
      <div style={{ display: 'flex' }}>
        <div className={classes.headerItem}>
          <SidepanelLink linkProps={{ to: link }}>
            <Card className={classes.card}>
              <ProfileItem
                name={name || ''}
                userKey={userKey}
                logoUrl={logoUrl}
                byline={userKey || logoUrl}
                {...(actionButton && md5
                  ? {
                      icons: <IconButton icon={['far', 'times']} size="small" onClick={handleDelete} />
                    }
                  : {})}
              />
            </Card>
          </SidepanelLink>
        </div>
        {actionButton && isLastChild && actionButton}
      </div>
    </div>
  )
}

export const MoveIconHint = () => {
  const { classes } = useStyles()
  const scrollPosition = useScrollPosition()

  const icon = useIconsUpdate([
    ['far', 'computer-mouse'],
    ['far', 'chevrons-down'],
    ['far', 'computer-mouse'],
    ['far', 'chevrons-right']
  ] as [IconPrefix, IconName][])

  const windowHeight = window.innerHeight
  const documentHeight = document.documentElement.scrollHeight

  const isBottom = scrollPosition + windowHeight >= documentHeight

  return !isBottom ? <Icon contained className={classes.moveIconHint} icon={icon} /> : null
}

export const TimeLineEl = memo(
  ({ style, index, data: { loading, rows } }: { style: CSSProperties; index: number; data: { loading: boolean; rows: ActivityRow[] } }) => {
    const { classes } = useStyles()
    const [dateTime] = Object.keys(rows[index])
    return (
      <TimeLineItem
        style={style}
        component={TimeCard}
        loading={loading}
        item={{ time: dateTime ? formatTime(dateTime) : dateTime, className: classes.timelineItem }}
        index={index}
      />
    )
  }
)

export const ActivitiesTimeline = forwardRef(
  (
    {
      rows,
      rowHeight,
      width,
      loading
    }: {
      rows: ActivityRow[]
      rowHeight: VariableSizeListProps['itemSize']
      width: VariableSizeListProps['width']
      loading: boolean
    },
    ref: ForwardedRef<List<any>>
  ) => {
    const { classes } = useStyles()
    const { outerElementType, itemData } = useVirtualized<{ loading: boolean; rows: ActivityRow[] }>(true, { rows, loading })

    const innerElementType = useCallback(
      ({ children, style }) => (
        <div style={style}>
          <Timeline position="left" loading={loading}>
            {children}
          </Timeline>
        </div>
      ),
      [loading]
    )

    return (
      <Box className={classes.listWrapper}>
        <List
          itemCount={rows.length}
          itemSize={rowHeight}
          width={width}
          height={window.innerHeight}
          ref={ref}
          itemData={itemData}
          innerElementType={innerElementType}
          outerElementType={outerElementType}
        >
          {TimeLineEl}
        </List>
      </Box>
    )
  }
)

export const ActivitiesLayout = (props: { header?: ReactElement; children?: ReactNode }) => {
  const { classes, cx } = useStyles()
  const wide = useWide()
  const { autoHideClassName } = useAutoHideOnScrollStyles(true)

  const { header, children } = props

  return (
    <Box>
      <Widget sticky={wide ? 129 : 139} scope="none" className={cx({ [autoHideClassName]: !wide }, classes.headersWidget)}>
        <Box m={-2}>{header}</Box>
      </Widget>
      <div>{children}</div>
    </Box>
  )
}

export default ActivitiesLayout
