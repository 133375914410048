import { ChangeEvent, useEffect, useMemo, useState } from 'react'

import { Divider, Fade, Box, SelectChangeEvent } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

import Typography from '_shared/Typography'

import Paths from 'Paths'

import { TouchpointControl, PeriodControl, PerspectiveControl, Switcher, IncludeControl, TagsControl } from './controls'

import Filters, { wasOptions, daysOptions, scheduledOptions, andOptions, interactionsHelpText } from './index'

const useStyles = makeStyles()((theme) => ({
  container: {
    [theme.breakpoints.up('md')]: {
      width: 270,
      maxHeight: 'unset',
      overflow: 'auto',
      height: '100%'
    }
  },
  block: {
    marginBottom: theme.spacing(1.5)
  },
  divider: {
    margin: `${theme.spacing(0.5)} -${theme.spacing(2.5)} ${theme.spacing(1.5)}`
  },
  helpText: {
    width: `calc(100% - 5px)`
  }
}))

export const perspectiveOptions: { value: PerspectiveType; label: string }[] = [
  { value: 'Team', label: 'Companies we know as a team' },
  { value: 'User', label: 'Companies I know' }
]
export const dealsOptions: { name: IncludeDealsType; label: string }[] = [{ name: 'hadDeal', label: `Where we've done one or more deals` }]

export type CompaniesFiltersType = {
  isContributor?: boolean
  total?: number
  opened: boolean
  disabled: boolean
  contentLoading: boolean
  reset: () => Promise<Modify<CompaniesPageParams, { dealsChecked?: IncludeDealsType[] }> | undefined>
  anchorEl?: HTMLElement | null
  interactionsFiltersShown: boolean
  queryData: Modify<CompaniesPageParams, { dealsChecked?: IncludeDealsType[] }>
  toggleOpen: () => Promise<void | undefined> | undefined
  handleChange: (params: Modify<CompaniesPageParams, { dealsChecked?: IncludeDealsType[] }>) => Promise<void | undefined>
  toggleDealSwitch: (event: React.ChangeEvent<HTMLInputElement>) => Promise<void | undefined>
  toggleInteractionSwitch: (event: React.ChangeEvent<HTMLInputElement>) => Promise<void | undefined>
}

const CompaniesFilters = (props: CompaniesFiltersType) => {
  const { classes } = useStyles()
  const {
    isContributor,
    total,
    opened,
    disabled,
    contentLoading,
    anchorEl,
    interactionsFiltersShown,
    handleChange,
    queryData,
    toggleDealSwitch,
    toggleInteractionSwitch,
    toggleOpen
  } = props
  const { perspective, where, interaction, days, period, and, filterByDeal, includeTags, excludeTags, dealsChecked = [], sort } = queryData
  const [daysValue, setDaysValue] = useState<{ [key in Lowercase<PeriodOptionsType>]: DaysOptions }>({
    within: daysOptions[0].value,
    after: daysOptions[0].value
  })
  const { within, after } = daysValue

  const interactionsActive = !!where
  const dealsActive = !!filterByDeal && JSON.parse(filterByDeal) === true

  const inclTags: TagsSearchParam | undefined = useMemo(() => (includeTags ? JSON.parse(includeTags) : includeTags), [includeTags])
  const exclTags: TagsSearchParam | undefined = useMemo(() => (excludeTags ? JSON.parse(excludeTags) : excludeTags), [excludeTags])

  useEffect(() => {
    if (days && period) {
      setDaysValue((prev) => ({ ...prev, [period.toLowerCase()]: +days }))
    }
  }, [days, period])

  const toggleAnd = (e: ChangeEvent<{ name: unknown }>) => {
    const { name } = e.target
    handleChange({ and: and ? undefined : (name as CompaniesPageParams['and']) })
  }

  const handlePerspectiveChange = (e: ChangeEvent<{ name: string; value: unknown }>) => {
    const { name, value } = e.target
    handleChange({ [name]: value })
  }

  const handleInteractionChange = (e: ChangeEvent<{ name: string; value: unknown }>) => {
    const { name, value } = e.target
    handleChange({ [name]: value === 'Any' ? null : value })
  }

  const handleDaysChange = (e: SelectChangeEvent<unknown>) => {
    const { name, value } = e.target as { name: string; value: DaysOptions }

    if (name) {
      handleChange({ days: `${value}` })
    }
  }

  const handlePeriodChange = (e: SelectChangeEvent<unknown>) => {
    const { name, value } = e.target as { name: string; value: string }
    handleChange(
      value === 'Anytime'
        ? { [name]: undefined, days: undefined }
        : { [name]: value, days: `${daysValue[value.toLowerCase() as keyof typeof daysValue]}` }
    )
  }

  const handleWhereChange = (e: SelectChangeEvent<unknown>) => {
    const { name, value } = e.target
    if (name) {
      const index = (where === 'Next' ? scheduledOptions : wasOptions).findIndex(
        (opt: { value: PeriodOptionsType | 'Anytime' }) => opt.value === (period || 'Anytime')
      )
      const { value: periodVal } = (value === 'Next' ? scheduledOptions : wasOptions)[index]

      handleChange({
        [name]: value,
        period: periodVal === 'Anytime' ? undefined : periodVal,
        ...((value as TouchpointDateType) === 'Next' ? { interaction: 'Meeting' } : {})
      })
    }
  }

  const toggleDealInclude = (e: ChangeEvent<{ name: unknown }>) => {
    const { name } = e.target as { name: IncludeDealsType }

    handleChange({
      dealsChecked: dealsChecked?.includes(name) ? dealsChecked?.filter((v: IncludeDealsType) => v !== name) : [...dealsChecked, name]
    })
  }

  const reset = async () => {
    const newSearchQuery = await props.reset()

    if (newSearchQuery) {
      const { period, days } = newSearchQuery
      if (period && days) {
        const prd = period.toLowerCase() as keyof typeof daysValue
        const daysWithoutCurPrd = { ...daysValue }
        delete daysWithoutCurPrd[prd]

        const restKeys = Object.keys(daysWithoutCurPrd)
          .filter((key) => key !== period?.toLowerCase())
          .reduce((acc, key) => ({ ...acc, [key]: 7 }), daysWithoutCurPrd)

        setDaysValue({ ...{ [prd]: days }, ...restKeys })
      } else {
        setDaysValue(Object.keys(daysValue).reduce((acc, key) => ({ ...acc, [key]: 7 }), daysValue))
      }
    }
  }

  return (
    <Filters
      opened={opened}
      className={classes.container}
      contentLoading={contentLoading}
      disabled={disabled}
      total={total}
      reset={reset}
      anchorEl={anchorEl}
      toggleOpen={toggleOpen}
      {...(isContributor ? { shift: 16 } : {})}
    >
      <>
        {isContributor || (
          <Box className={classes.block}>
            <PerspectiveControl value={perspective} disabled={disabled} handleChange={handlePerspectiveChange} options={perspectiveOptions} />
          </Box>
        )}
        <TagsControl
          taggableType="companies"
          manageTagsLink={`${Paths._tags}/companies`}
          appliedInclude={inclTags}
          appliedExclude={exclTags}
          handleChange={handleChange}
          disabled={!sort || disabled}
          blockClassName={classes.block}
        />

        <Switcher
          checked={dealsActive}
          disabled={disabled}
          label="Filter by deal activity"
          toggle={toggleDealSwitch}
          informText="You can filter companies using data about deal activity."
        />
        <Box className={classes.block}>
          <Fade in={dealsActive} unmountOnExit>
            <div>
              <Divider className={classes.divider} />
              <IncludeControl value={dealsChecked} disabled={disabled} toggle={toggleDealInclude} options={dealsOptions} />
            </div>
          </Fade>
        </Box>
        {interactionsFiltersShown && (
          <>
            <Switcher
              checked={!!where}
              disabled={disabled}
              label="Filter by interactions"
              toggle={toggleInteractionSwitch}
              informText={
                <>
                  You can filter companies using data about inbound, outbound and meeting interactions.
                  {!!interactionsActive && (
                    <>
                      <br /> <br /> {interactionsHelpText}
                    </>
                  )}
                </>
              }
            />
            {!!interactionsActive || <Typography classes={{ root: classes.helpText }}>{interactionsHelpText}</Typography>}

            <Fade in={interactionsActive} unmountOnExit>
              <div>
                <Divider className={classes.divider} />
                <Box className={classes.block}>
                  <TouchpointControl
                    where={where}
                    interaction={interaction}
                    disabled={disabled}
                    handleWhereChange={handleWhereChange}
                    handleInteractionChange={handleInteractionChange}
                  />
                </Box>

                {where !== 'Next' && (
                  <Box className={classes.block}>
                    <Box className={classes.block}>
                      <PeriodControl
                        label="Was"
                        options={wasOptions.map(({ value, label }) => ({
                          value,
                          label,
                          disabled: value !== period,
                          selectValue: value === 'Within' ? within : after,
                          includeSelector: value !== 'Anytime'
                        }))}
                        disabled={disabled}
                        period={period || 'Anytime'}
                        handlePeriodChange={handlePeriodChange}
                        handleDaysChange={handleDaysChange}
                      />
                    </Box>
                    <IncludeControl value={[and || '']} disabled={disabled} toggle={toggleAnd} options={andOptions} label="And" />
                  </Box>
                )}
                {where === 'Next' && (
                  <Box className={classes.block}>
                    <PeriodControl
                      label="Is scheduled"
                      options={scheduledOptions.map(({ value, label }) => ({
                        value,
                        label,
                        disabled: value !== period,
                        selectValue: value === 'Within' ? within : after,
                        includeSelector: value !== 'Anytime'
                      }))}
                      disabled={disabled}
                      period={period || 'Anytime'}
                      handlePeriodChange={handlePeriodChange}
                      handleDaysChange={handleDaysChange}
                    />
                  </Box>
                )}
              </div>
            </Fade>
          </>
        )}
      </>
    </Filters>
  )
}

export { Controller } from './index'
export default CompaniesFilters
