import { useEffect, useMemo, useState } from 'react'

import { RouteComponentProps } from 'react-router'
import { makeStyles } from 'tss-react/mui'

import Page from '_shared/Page'

import { useWide } from '_core/components/layout'
import TeamProfile, { TeamProfileProps } from '_core/components/team/TeamProfile'

import { apiBase } from '_core/hooks/useTeam'

import DynamicEntity from '_core/DynamicEntity'

const useStyles = makeStyles()(() => ({
  wrapper: {
    overflow: 'auto'
  }
}))

const TeamPage = (props: RouteComponentProps<{ id: string }>) => {
  const [reload, setReload] = useState(false)
  const { id: teamId } = props.match.params
  const { classes } = useStyles()
  const wide = useWide()

  useEffect(() => {
    if (reload) setReload(false)
  }, [reload])

  const memoUrls = useMemo(
    () =>
      [
        {
          key: 'teamInfo',
          url: `${apiBase}/${teamId}`,
          condition: true
        },
        {
          key: 'groups',
          url: `${apiBase}/${teamId}/membershipCredentials?roleTypes=Member&credentialTypes=ActiveDirectoryTeamMember`,
          condition: true
        },
        {
          key: 'members',
          url: `${apiBase}/${teamId}/members?roleTypes=Member&credentialTypes=ActiveDirectoryTeamMember&userTypes=StandardUser&Take=10`,
          condition: !wide
        },
        {
          key: 'otherRoles',
          url: `${apiBase}/${teamId}/membershipCredentials?roleTypes=Reader&roleTypes=Curator`,
          condition: true
        }
      ].filter((item) => item.condition),
    [teamId, wide]
  )

  return (
    <Page className={classes.wrapper}>
      <DynamicEntity<{ extraProps: { addprops: Pick<TeamProfileProps, 'teamId' | 'reload' | 'setReload'> } }>
        urls={!reload ? memoUrls : null}
        component={TeamProfile}
        addprops={{ teamId, reload, setReload }}
        id="team_profile"
        keepMounted
      />
    </Page>
  )
}

export default TeamPage
