import { makeStyles } from 'tss-react/mui'

import { Score } from '_shared/Badge'

const useStyles = makeStyles()((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    margin: `0 ${theme.spacing(2)}`,
    flex: '1 1 auto'
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column'
  },
  connection: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    '& > *:first-of-type, & > *:last-of-type': {
      width: '50%'
    },
    '& > *:first-of-type': {
      position: 'relative',
      '&:after': {
        content: '""',
        width: `100%`,
        height: 1,
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1.5)
      }
    },
    '& > *:last-of-type': {
      position: 'relative',
      '&:after': {
        content: '""',
        width: `100%`,
        height: 1,
        position: 'absolute',
        left: theme.spacing(1),
        top: theme.spacing(1.5)
      }
    }
  },
  connectionStyle: {
    '& > div': {
      '&:after': {
        background: 'rgba(27, 149, 187, 0.87)'
      }
    }
  },
  name: {
    fontSize: theme.spacing(2),
    flex: '1 1 auto',
    width: '100%',
    marginBottom: theme.spacing(2),
    boxSizing: 'border-box',
    lineHeight: '1.5rem'
  },
  link: {
    marginTop: theme.spacing(0.5),
    color: 'rgba(27, 149, 187, 0.87)',
    textAlign: 'center'
  },
  title: {
    color: theme.palette.text.hint,
    textAlign: 'center'
  }
}))

const RelationshipScore = ({ score }: { score: number | string | undefined }) => {
  const { classes, cx } = useStyles()

  return (
    <div className={classes.root}>
      <div className={cx(classes.connection, classes.connectionStyle)}>
        <div></div>
        <Score value={score} size="sm" />
        <div></div>
      </div>
    </div>
  )
}

export default RelationshipScore
