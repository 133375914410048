import React, { useContext, useEffect } from 'react'

import { useParams } from 'react-router-dom'

import Page from '_shared/Page'

import { Narrow } from '_core/components/layout'
import CompanyMarketData from '_core/components/market-data/CompanyMarketData'

import { useCompanyMarketData } from '_core/hooks/useCompanyMarketData'
import useSearchQuery from '_core/hooks/useSearchQuery'

import { LayoutContext } from 'Layout/LayoutContextProvider'

const CompanyMarketDataPage = () => {
  const { companyId: name } = useParams<any>()
  const { queryParams } = useSearchQuery<any>()
  const { website } = queryParams
  const { marketData, getMarketData, error } = useCompanyMarketData(name, website)
  const { setMobileHeader } = useContext(LayoutContext)

  useEffect(() => {
    if (name) {
      setMobileHeader(name)
    }
  }, [name])

  useEffect(() => {
    if (!marketData && name) {
      getMarketData()
    }
  }, [name, marketData])

  return (
    <Page>
      <Narrow>
        <CompanyMarketData error={error} company={marketData} />
      </Narrow>
    </Page>
  )
}

export default CompanyMarketDataPage
