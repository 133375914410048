import React, { ComponentProps } from 'react'

import { Box } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

import { AvatarWrapper } from '_shared/Avatar'
import Skeleton from '_shared/Skeleton'
import Tooltip from '_shared/Tooltip'
import Typography from '_shared/Typography'

import { AddTagTriggerEl, ExtraTagsPopover, InternalTagPopover, ShowAllTagsLink } from '_core/components/InternalTag'
import TagsGroup from '_core/components/TagsGroup'

import { groupTags } from '_core/hooks/useTagsManager'

import { Wide, Narrow, useWide } from './layout'

export const useStyles = makeStyles<{ flip?: boolean }>()((theme, { flip }) => ({
  name: {
    fontSize: 18,
    marginBottom: 0,
    wordBreak: 'break-word'
  },
  nameWrapper: {
    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns: 'auto auto 1fr'
  },
  buttons: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    maxWidth: '80%',
    margin: '16px auto 0',
    [theme.breakpoints.up('md')]: {
      marginRight: theme.spacing(1),
      marginTop: 0,
      position: 'absolute',
      right: flip ? 'auto' : -8,
      left: flip ? -8 : 'auto',
      bottom: 0
    },
    '& .MuiButton-root': {
      minWidth: 'auto',
      [theme.breakpoints.up('tablet')]: {
        marginRight: theme.spacing(1)
      }
    }
  },
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: flip ? 'row-reverse' : 'row',
    margin: '0px auto',
    maxWidth: '90%',
    marginTop: theme.spacing(1),
    [theme.breakpoints.up('phone')]: {
      maxWidth: '80%'
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: '100%',
      justifyContent: 'flex-start',
      marginTop: 0,
      position: 'relative',
      minWidth: '32%',
      '&::after': {
        content: '""',
        position: 'absolute',
        width: '0.5px',
        height: '100%',
        background: '#ECEEF0',
        left: flip ? 'inherit' : 100,
        right: flip ? 100 : 'inherit'
      }
    }
  },
  container: {
    marginLeft: flip ? 0 : theme.spacing(3),
    marginRight: flip ? theme.spacing(3) : 0,
    maxWidth: `calc(100% - 80px - ${theme.spacing(3)})`,
    '& > p': {
      paddingTop: '4px'
    },
    [theme.breakpoints.up('sm')]: {
      marginLeft: flip ? 0 : theme.spacing(5)
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: '60%',
      paddingTop: 0,
      paddingBottom: 0
    }
  },
  avatar__wrapper: {
    width: '80px',
    display: 'flex',
    justifyContent: flip ? 'flex-end' : 'flex-start',
    marginTop: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      width: '100px',
      marginTop: 0
    }
  },
  actions: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: theme.spacing(2),
    marginBottom: 0,
    [theme.breakpoints.up('md')]: {
      maxWidth: '100%',
      justifyContent: 'start'
    }
  },
  action: {
    marginRight: theme.spacing(3),
    [theme.breakpoints.up('phone')]: {
      marginRight: theme.spacing(4)
    },
    [theme.breakpoints.up('sm')]: {
      marginRight: theme.spacing(5)
    },
    [theme.breakpoints.up('md')]: {
      marginRight: theme.spacing(3)
    },
    '&:last-of-type': {
      marginRight: 0
    }
  }
}))

const MarketDataSummary = (props: ProfileSummaryProps & ProfileSummaryTagsProps) => {
  const upSmall = useWide('sm')
  const wide = useWide()
  const { score, title, userKey, byline, byline2, actions, loading, url, buttons, flip, budge, showAllTagsLink, editTagsLink, tags } = props
  const { classes } = useStyles({ flip })

  return (
    <>
      <Box className={classes.wrapper}>
        <Box className={classes.avatar__wrapper}>
          <AvatarWrapper name={loading ? '' : title} score={score} userKey={userKey} logoUrl={url} size="lg" mode="dark" hideName />
        </Box>
        <Box className={classes.container}>
          <Box className={classes.nameWrapper}>
            {(loading || title) && (
              <Skeleton condition={!title} width="100%">
                <Tooltip title={title || ''} placement="bottom-start" disabled={wide}>
                  <Typography variant="h4" className={classes.name} ellipsis semiBold>
                    {title || 'Placeholder Name'}
                  </Typography>
                </Tooltip>
              </Skeleton>
            )}
            {!loading && budge && <>{budge}</>}

            {!loading && editTagsLink && showAllTagsLink && (
              <Wide>
                <Box ml={1.5}>
                  <TagsGroup<ComponentProps<typeof InternalTagPopover>['tagData']>
                    items={tags}
                    max={4}
                    tagComponent={InternalTagPopover}
                    renderShowAll={({ extraTagsAmount }: { extraTagsAmount: number }) => (
                      <ExtraTagsPopover
                        triggerElement={<ShowAllTagsLink extraTagsAmount={extraTagsAmount} link={showAllTagsLink} />}
                        items={tags ? groupTags(tags) : tags}
                      />
                    )}
                    addTagTriggerEl={<AddTagTriggerEl link={editTagsLink} hasAny={!!tags?.length} />}
                  />
                </Box>
              </Wide>
            )}
          </Box>
          {!loading && showAllTagsLink && editTagsLink && (
            <Narrow>
              <Box mt={1}>
                <TagsGroup<ComponentProps<typeof InternalTagPopover>['tagData']>
                  max={upSmall ? 4 : 1}
                  items={tags}
                  tagComponent={InternalTagPopover}
                  renderShowAll={({ extraTagsAmount }: { extraTagsAmount: number }) => (
                    <ExtraTagsPopover
                      triggerElement={<ShowAllTagsLink extraTagsAmount={extraTagsAmount} link={showAllTagsLink} />}
                      items={tags ? groupTags(tags) : []}
                    />
                  )}
                  addTagTriggerEl={<AddTagTriggerEl link={editTagsLink} hasAny={!!tags?.length} />}
                />
              </Box>
            </Narrow>
          )}
          {(loading || byline) && (
            <Skeleton condition={loading} width="80%" height={20}>
              {byline}
            </Skeleton>
          )}

          {(loading || byline2) && (
            <Skeleton condition={loading} width="70%" height={20}>
              {byline2}
            </Skeleton>
          )}

          {actions && (
            <Wide>
              <Box className={classes.actions}>
                {actions.map((action: any, index: number) => (
                  <Box className={classes.action} key={index}>
                    {action}
                  </Box>
                ))}
              </Box>
            </Wide>
          )}
        </Box>
        {buttons && (
          <Wide>
            <Box className={classes.buttons}>{buttons}</Box>
          </Wide>
        )}
      </Box>
      {actions && (
        <Narrow>
          <Box className={classes.actions}>
            {actions.map((action: any, index: number) => (
              <Box className={classes.action} key={index}>
                {action}
              </Box>
            ))}
            {buttons && <Box>{buttons}</Box>}
          </Box>
        </Narrow>
      )}
    </>
  )
}

export default MarketDataSummary
