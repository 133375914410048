import React, { isValidElement, ReactElement, ReactNode } from 'react'

import { Box } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

import { Score } from '_shared/Badge'
import Skeleton from '_shared/Skeleton'
import Tooltip from '_shared/Tooltip'
import Typography from '_shared/Typography'

export const itemHeight = 82

const useStyles = makeStyles()((theme) => ({
  item: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    minHeight: itemHeight,
    boxSizing: 'border-box',
    padding: `${theme.spacing(1)} ${theme.spacing(2)}`
  },
  expandable: {
    height: 'auto'
  },
  default: {
    height: itemHeight
  },
  itemContent: {
    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns: 'minmax(0, 1fr) auto',
    gap: theme.spacing(2)
  },
  title: {
    fontSize: '15px',
    lineHeight: '22px',
    textAlign: 'start',
    maxWidth: '100%'
  },
  byline: {
    lineHeight: '22px',
    textAlign: 'start',
    maxWidth: '100%'
  },
  info: {
    maxWidth: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'start'
  },
  badge: {
    marginLeft: theme.spacing(1)
  }
}))

type SummaryContentProps = {
  title: string
  priorLine?: string | ReactNode
  byline?: string | ReactNode
  byline2?: string | ReactNode
  icons?: ReactElement
  className?: string
  score?: number | string
}

export type SummaryItemProps = {
  byline3?: string | ReactNode
  variant?: 'expandable'
} & SummaryContentProps

export const SummaryContent = ({ title, priorLine, byline, byline2, icons, score, className }: SummaryContentProps) => {
  const { classes } = useStyles()

  return (
    <>
      <Box className={className}>
        {title && priorLine && (
          <>
            {isValidElement(priorLine) && priorLine}
            {typeof priorLine === 'string' && (
              <Tooltip title={priorLine}>
                <Typography className={classes.byline} color="text.secondary" ellipsis semiBold>
                  {priorLine}
                </Typography>
              </Tooltip>
            )}
          </>
        )}
        <Skeleton condition={!title} width="150px">
          <Tooltip title={title}>
            <Typography className={classes.title} semiBold ellipsis>
              {title || 'Placeholder title'}
            </Typography>
          </Tooltip>
        </Skeleton>
        <Skeleton condition={!title && !byline} width="140px">
          {isValidElement(byline) && byline}
          {typeof byline === 'string' && (
            <Tooltip title={byline}>
              <Typography className={classes.byline} ellipsis>
                {byline}
              </Typography>
            </Tooltip>
          )}
        </Skeleton>
        <Skeleton condition={!title && !byline2} width="140px">
          {isValidElement(byline2) && byline2}
          {typeof byline2 === 'string' && (
            <Tooltip title={byline2}>
              <Typography className={classes.byline} ellipsis>
                {byline2}
              </Typography>
            </Tooltip>
          )}
        </Skeleton>
      </Box>
      <Box display="flex" alignItems="center">
        {icons}
        <Score condition={!title && typeof score === 'undefined'} value={score} size="sm" className={classes.badge} />
      </Box>
    </>
  )
}

const SummaryItem = ({ byline3, variant, ...props }: SummaryItemProps) => {
  const { classes, cx } = useStyles()

  return (
    <Box className={cx(classes.item, { [classes.expandable]: variant === 'expandable', [classes.default]: !variant })}>
      <Box className={classes.itemContent}>
        <SummaryContent {...props} />
      </Box>
      {isValidElement(byline3) && (
        <Box maxWidth="100%" overflow="hidden">
          {byline3}
        </Box>
      )}
    </Box>
  )
}

export default SummaryItem
