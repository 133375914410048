import { useState, useRef } from 'react'

const useLatestSearchValue = () => {
  const [isTyping, setIsTyping] = useState<boolean>(false)
  const inputValueRef = useRef<string>()
  const { current: inputValue } = inputValueRef

  return {
    isTyping,
    setIsTyping,
    inputValue,
    inputValueRef
  }
}

export default useLatestSearchValue
