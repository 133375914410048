import { useContext, useEffect, useState } from 'react'

import { TeamContext } from '_core/context/TeamContext'

import { post } from 'utils/httpUtils'

type Contributor = {
  contributorKey: string
  sources: IdentifierSource[] | PhoneSource[]
}

type Request = {
  identifier: string
  contributorKeys: string[]
  type: string
}

type DSAuditRes = {
  request: Request
  results: Contributor[]
}

const useAuditSources = (
  payload: { identifier: string; type: AuditIdentifierSourceRequestType },
  contributors: string[] | undefined,
  loading: boolean
) => {
  const [sources, setSources] = useState<Contributor[]>()
  const { teamContextValue } = useContext(TeamContext)

  useEffect(() => {
    setSources(undefined)
  }, [loading])

  useEffect(() => {
    if (contributors?.length && !loading) {
      let requestActive = true
      const abortController = new AbortController()
      const requestSources = async () => {
        const resp = await post<DSAuditRes>('/adminauditing/dsaudit', {
          ...payload,
          contributorKeys: contributors,
          teamNumber: teamContextValue.teamNumber
        })
        if (requestActive && resp) {
          setSources(resp.results)
        }
      }
      requestSources()

      return () => {
        requestActive = false
        abortController.abort()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contributors?.length, loading])

  return sources?.map(({ contributorKey, sources }) => ({ contributor: contributorKey, sourceTypes: sources || [] }))
}

export default useAuditSources
