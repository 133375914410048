import { Redirect, Route, RouteProps } from 'react-router-dom'

import Typography from '_shared/Typography'

import ErrorBoundaries, { withErrors } from '_core/ErrorBoundary'

import Paths from 'Paths'

const RedirectToHome = () => <Redirect to={Paths._home} />

const PrivateRoute = ({
  component,
  children,
  render,
  hasAccess,
  deniedComponent: DeniedComponent = RedirectToHome,
  ...props
}: RouteProps & { hasAccess: boolean | undefined; deniedComponent?: () => JSX.Element }) => {
  if (typeof hasAccess !== 'boolean') {
    return <Typography>Loading...</Typography>
  }

  return (
    <Route
      {...props}
      {...(component ? { component: hasAccess ? component : DeniedComponent } : {})}
      {...(render ? { render: hasAccess ? render : withErrors(DeniedComponent) } : {})}
    >
      {children ? (
        hasAccess ? (
          children
        ) : (
          <ErrorBoundaries>
            <DeniedComponent />
          </ErrorBoundaries>
        )
      ) : (
        children
      )}
    </Route>
  )
}

export default PrivateRoute
