import { useRef, useEffect, ComponentProps, ReactElement } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  CircularProgress,
  Paper,
  AutocompleteProps,
  Autocomplete,
  ChipTypeMap,
  AutocompleteRenderInputParams,
  InputAdornment,
  PaperProps,
  Box,
  Chip
} from '@mui/material'
import { makeStyles } from 'tss-react/mui'

import TextField from '_shared/forms/TextField'

const useStyles = makeStyles<{ size: ComponentProps<typeof TextField>['size']; hideNoOptions: boolean } | void>()((theme, params) => ({
  inputRoot: {
    '&.MuiOutlinedInput-root': {
      padding: `${theme.spacing(params?.size === 'small' ? 0.5 : 1.25)} ${theme.spacing(1.5)}`,
      '& .MuiAutocomplete-input': {
        padding: 0
      }
    }
  },
  popupIndicator: {
    marginRight: 0
  },
  option: {
    '&[data-focus="true"], &[aria-selected="true"]': {
      background: `${theme.palette.background.light} !important`
    },
    '&[aria-selected="true"]': {
      color: `${theme.palette.secondary.main} !important`
    },
    '&:hover': {
      background: `${theme.palette.hover.secondary} !important`
    }
  },
  listBox: {
    padding: '0!important'
  },
  loadingIconPosition: {
    position: 'absolute',
    right: 13
  },
  paper: {
    '&:empty': {
      boxShadow: 'none'
    }
  },
  chipRoot: {
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.secondary.light,
    maxWidth: '100%',
    marginTop: theme.spacing(0.5),
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(0.5)
  },
  noOptions: {
    display: params?.hideNoOptions ? 'none' : 'block'
  }
}))

export const ChipsPickList = ({
  items,
  disabled
}: {
  items: { label: string | ReactElement; handleDelete: (label: string) => void }[]
  disabled: boolean
}) => {
  const { classes } = useStyles()

  return (
    <Box display="flex" flexWrap="wrap" mt={0.5} mb={-0.5}>
      {items.map(({ label, handleDelete }, i: number) => {
        return (
          <Chip
            key={i}
            label={label}
            onDelete={handleDelete}
            disabled={disabled}
            classes={{ root: classes.chipRoot }}
            deleteIcon={<FontAwesomeIcon icon={['far', 'times']} />}
          />
        )
      })}
    </Box>
  )
}

const CustomPaper = (props: PaperProps) => {
  return <Paper {...props} />
}

const Combobox = <
  T,
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = Multiple extends true ? true : false,
  FreeSolo extends boolean | undefined = false,
  ChipComponent extends React.ElementType = ChipTypeMap['defaultComponent']
>({
  autoFocus,
  icon,
  iconEnd,
  label,
  name,
  errorMessage,
  placeholder,
  filterOptions,
  ...props
}: Omit<AutocompleteProps<T, Multiple, DisableClearable, FreeSolo, ChipComponent>, 'renderInput'> &
  Pick<ComponentProps<typeof TextField>, 'autoFocus' | 'icon' | 'iconEnd' | 'label' | 'errorMessage' | 'placeholder' | 'name'>) => {
  const hideNoOptions = !props.inputValue && !props.loading && !props.options.length
  const { classes } = useStyles({ size: props.size, hideNoOptions })

  const inputRef = useRef<HTMLInputElement>(null)
  const { forcePopupIcon = !(typeof props.loading === 'boolean'), disableClearable = !!props.multiple } = props

  useEffect(() => {
    if (!props.disabled && autoFocus) {
      inputRef.current?.focus()
    }
  }, [props.disabled, autoFocus])

  const renderInput = (params: AutocompleteRenderInputParams) => {
    const { InputProps, ...rest } = params
    const { startAdornment, endAdornment, ...restInputProps } = InputProps

    return (
      <TextField
        {...rest}
        name={name}
        label={label}
        inputRef={inputRef}
        autoComplete="off"
        placeholder={placeholder}
        icon={icon}
        errorMessage={errorMessage}
        InputProps={{
          ...restInputProps,
          startAdornment: props.multiple && !icon ? null : startAdornment,
          endAdornment: props.loading ? (
            <InputAdornment position="end" classes={{ root: classes.loadingIconPosition }}>
              <CircularProgress color="primary" size={14} />
            </InputAdornment>
          ) : (
            endAdornment
          )
        }}
      />
    )
  }

  return (
    <Autocomplete<T, Multiple, DisableClearable, FreeSolo, ChipComponent>
      forcePopupIcon={forcePopupIcon} // if options opened on input focus => arrowIcon should be shown
      disableClearable={disableClearable as DisableClearable}
      fullWidth
      PaperComponent={CustomPaper}
      slotProps={{ paper: { elevation: hideNoOptions ? 0 : 8 } }}
      classes={{
        popupIndicator: classes.popupIndicator,
        inputRoot: classes.inputRoot,
        listbox: classes.listBox,
        option: classes.option,
        paper: classes.paper,
        noOptions: classes.noOptions
      }}
      renderInput={renderInput}
      filterOptions={filterOptions}
      {...props}
    />
  )
}

// TEST

export default Combobox
