import React, { useContext, useEffect, useMemo, useState } from 'react'

import { GridColDef } from '@mui/x-data-grid-pro'
import { useParams } from 'react-router-dom'

import { TeamContext } from '_core/context/TeamContext'

import Filters from '_core/components/filters/Introducers'
import {
  companyColumn as gridCompanyColumn,
  jobTitleColumn as gridJobTitleColumn,
  lastInboundColumn,
  lastMeetingColumn,
  lastOutboundColumn,
  nameColumn,
  scoreColumn,
  nextFutureMeetingColumn
} from '_core/components/grid/columns'
import GridPageFrame from '_core/components/GridPageFrame'
import IntroducersList, { IntroducersListProps, Heading } from '_core/components/IntroducersList'
import { Narrow } from '_core/components/layout'
import { sortMap } from '_core/components/sort'
import Topbar from '_core/components/Topbar'

import { DownloadBulkParams } from '_core/hooks/useDownloadBulk'
import useInteractionsPeriodEndpointParams from '_core/hooks/useInteractionsPeriodEndpointParams'
import useIntroducersUserSettings from '_core/hooks/useIntroducersUserSettings'
import useSearchQuery from '_core/hooks/useSearchQuery'
import useSortStatsEndpointParams from '_core/hooks/useSortStatsEndpointParams'
import useTagsEndpointParams from '_core/hooks/useTagsEndpointParams'

import DynamicEntity from '_core/DynamicEntity'
import UserSettings from '_core/UserSettings'

import { getBinary, mergeUrlWithParams } from 'utils/httpUtils'

import { LayoutContext } from 'Layout/LayoutContextProvider'

import Paths from 'Paths'

const jobTitleColumn = {
  ...gridJobTitleColumn,
  flex: 0.6
}

const companyColumn = {
  ...gridCompanyColumn,
  flex: 0.6
}

type ComponentProps = {
  onLoading: (loading: boolean, result: { total_item_count: number } | undefined) => void
  onPageSizeChange: (rowsPerPage: NumberToString<RowPerPageOptionsType>) => void
  updateSort: (sort: ScoreType | StatSortType) => void
  columns: GridColDef[]
}

type AddProps = Pick<IntroducersListProps, 'sort' | 'sortByField' | 'updateSort' | 'viewMode' | 'columns' | 'download'>

const Introducers = (props: Modify<IntroducersListProps, { items: CompanyIntroducerListItem[] }>) => {
  const { setSubHeader } = useContext(LayoutContext)

  useEffect(() => {
    setSubHeader(`Introducers ${props.total ? `· ${props.total}` : ''}`)
  }, [props.total])

  const { id } = useParams<{ id: string }>()

  const items = useMemo(
    () =>
      props.items?.map((intro) => {
        const lastInbound = intro.Stats?.LastInboundMsg || ''
        const lastOutbound = intro.Stats?.LastOutboundMsg || ''
        const lastMeeting = intro.Stats?.LastMeeting || ''
        const nextFutureMeeting = intro.Stats?.NextFutureMeeting || ''

        return {
          id: intro.UserKeyMd5 || intro.UserPersonKeyMd5,
          name: intro.UserName,
          byline: intro.UserBestJobTitleText || '',
          byline2: intro.UserBestJobMatchedCompanyName || intro.UserBestJobCorpLevelCompanyName || '',
          score: intro.ScorePoints,
          title: intro.UserBestJobTitleText || '',
          company: {
            name: intro.UserBestJobMatchedCompanyName || intro.UserBestJobCorpLevelCompanyName || '',
            link: `${Paths._companies}/${intro.UserBestJobCompanyMd5}`,
            sidepanel: true as SidepanelType
          },
          link: `${Paths._relationships}/${intro.UserKeyMd5}/companies/${id}`,
          sidepanel: true as SidepanelType,
          variant: 'expandable',
          lastInbound,
          lastOutbound,
          lastMeeting,
          nextFutureMeeting,
          tags: intro.Tags
        }
      }),
    [props.loading, props.items.length, id]
  )

  return <IntroducersList {...props} items={items} />
}

const Component = (props: ComponentProps) => {
  const { id } = useParams<any>()
  const { teamContextValue } = useContext(TeamContext)
  const { queryParams } = useSearchQuery<IntroducersPageParams>()
  const { keyword, rowsPerPage = '20', sort, viewMode, includeTags, excludeTags } = queryParams

  const interactionsPeriodParams = useInteractionsPeriodEndpointParams(queryParams)
  const tagsParams = useTagsEndpointParams('people', includeTags, excludeTags)
  const sortStatsParams = useSortStatsEndpointParams(sort)

  const sortByField = Object.keys(sortMap).find((key) => sortMap[key].asc === sort || sortMap[key].desc === sort)

  const payload = {
    TeamNumber: `${teamContextValue.teamNumber}`,
    IncludeStats: `${true}`,
    WithPersonTags: `${true}`,
    ...[tagsParams, sortStatsParams, interactionsPeriodParams]
      .flat()
      .filter(({ value }) => !!value)
      .reduce((acc, { name, value }) => ({ ...acc, [name]: `${value}` }), {})
  }

  const url = sort ? mergeUrlWithParams(`/companies/${id}/introducers`, payload) : null

  const download = {
    fileName: 'company_introducers',
    requestBinary: (data: DownloadBulkParams) => getBinary(`/companies/${id}/introducersxl`, { ...payload, ...data })
  }

  return (
    <DynamicEntity<{ extraProps: { addprops: AddProps } }>
      url={url}
      addprops={{
        sortByField,
        updateSort: props.updateSort,
        viewMode: viewMode || 'collapsed',
        columns: props.columns,
        download
      }}
      component={Introducers}
      onLoading={props.onLoading}
      updatePageSize={props.onPageSizeChange}
      pageSize={+rowsPerPage}
      list
      infinite
      search
      keepMounted
      autoHideOnScroll
      empty="No results found"
      emptySubtitle={keyword ? `No results found for your search '${keyword}'` : ''}
      id="company_introducers"
    />
  )
}

const CompanyIntroducers = () => {
  const { queryParams, updateQuery } = useSearchQuery<IntroducersPageParams>()

  const [total, setTotal] = useState<number>()
  const [contentLoading, setContentLoading] = useState<boolean>(false)

  const {
    setInitial,
    handleChange,
    reset,
    loading: filtersLoading,
    opened,
    interactionsFiltersShown,
    interactionsColumnsShown,
    toggleInteractionSwitch,
    toggleOpen: toggleFilterOpen
  } = useIntroducersUserSettings()

  const { sort, viewMode, excludeEmpty } = queryParams

  const disabled = filtersLoading || interactionsFiltersShown === null

  const columns = useMemo(
    () =>
      [
        { column: nameColumn },
        { column: jobTitleColumn },
        { column: companyColumn },
        { column: lastInboundColumn, condition: !!interactionsColumnsShown },
        { column: lastOutboundColumn, condition: !!interactionsColumnsShown },
        { column: lastMeetingColumn, condition: !!interactionsColumnsShown },
        { column: nextFutureMeetingColumn, condition: !!interactionsColumnsShown },
        { column: scoreColumn }
      ]
        .filter(({ condition }) => typeof condition !== 'boolean' || !!condition)
        .map(({ column }) => column),
    [interactionsColumnsShown]
  )

  const sortItems = columns.filter(({ sortable }) => sortable).map(({ headerName, field }) => ({ label: headerName || '', field }))

  const onPageSizeChange = (rowsPerPage: NumberToString<RowPerPageOptionsType>) => {
    handleChange({ rowsPerPage })
  }

  const updateSort = (sort: ScoreType | StatSortType) => {
    handleChange({ sort })
  }

  const toggleExclude = () => {
    updateQuery({ excludeEmpty: excludeEmpty === 'true' ? 'false' : 'true' })
  }

  const updateViewMode = (viewMode: ViewModeType) => {
    handleChange({ viewMode })
  }

  const onLoading = (loading: boolean, result: { total_item_count: number } | undefined) => {
    setContentLoading(loading)
    setTotal(result?.total_item_count)
  }

  const filtersProps = {
    toggleOpen: toggleFilterOpen,
    interactionsFiltersShown: !!interactionsFiltersShown,
    disabled,
    opened,
    total,
    contentLoading,
    reset,
    queryData: queryParams,
    handleChange,
    toggleInteractionSwitch
  }

  const searchPlaceholder = 'Search for introducers'
  return (
    <UserSettings endpoint="/usersettings/introducersfilter" setInitial={setInitial}>
      <Narrow>
        <Topbar nativeBack autoHideOnScroll />
      </Narrow>
      <GridPageFrame
        loading={typeof total !== 'number'}
        gridTitle="Introducers"
        gridHeadingIcon={['far', 'address-book']}
        searchPlaceholder={searchPlaceholder}
        disabledSearch={filtersProps.disabled}
        filters={<Filters {...filtersProps} />}
        heading={
          <Heading
            filters={<Filters {...filtersProps} />}
            filtersProps={{ opened: filtersProps.opened, toggleOpen: filtersProps.toggleOpen, disabled: filtersProps.disabled }}
            sortProps={{ sort, updateSort, items: sortItems, toggleExclude, excludeEmpty: excludeEmpty === 'true' }}
            viewProps={{ viewMode, updateViewMode }}
            searchPlaceholder={searchPlaceholder}
          />
        }
        component={<Component columns={columns} onLoading={onLoading} updateSort={updateSort} onPageSizeChange={onPageSizeChange} />}
      />
    </UserSettings>
  )
}
export default CompanyIntroducers

export type CompanyIntroducerListItem = {
  CompanyMd5: string
  CompanyName: string
  UserKeyMd5: string
  ScorePoints: number
  UserName: string
  UserPersonKeyMd5: string
  UserBestJobCompanyMd5: string
  UserBestJobCorpLevelCompanyName: string
  UserBestJobMatchedCompanyName: string
  UserBestJobTitleText: string
  Stats?: {
    UserKeyMd5: string
    CompanyMd5: string
    FirstInboundMsg: string
    FirstOutboundMsg: string
    FirstMeeting: string
    NextFutureMeeting?: string
    LastInboundMsg: string
    LastOutboundMsg: string
    LastMeeting: string
    LastActivity: string
  }
  Tags: {
    IdProperty: string
    TagNames: {
      Md5: string
      Category: string
      Tag: string
    }[]
  }[]
}
