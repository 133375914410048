import { useState, useEffect, useContext, useRef, Dispatch, SetStateAction } from 'react'

import { Box } from '@mui/material'
import { Moment as MomentType } from 'moment'
import { useLocation } from 'react-router-dom'
import { makeStyles } from 'tss-react/mui'

import { TeamContext } from '_core/context/TeamContext'

import Page from '_shared/Page'
import Typography from '_shared/Typography'

import Today, { NextDateSwitch, PrevDateSwitch, WeekDateTitle } from '_core/components/DateSwitcher'
import EventsList, { TodayEmpty, OtherDayEmpty, TogglePast } from '_core/components/EventsList'
import Filters, { Controller } from '_core/components/filters/Events'
import { Column, Columns, Narrow, Wide, useWide } from '_core/components/layout'
import NoAccess from '_core/components/MailboxNoAccess'
import Preview from '_core/components/Preview'
import Widget from '_core/components/Widget'

import useEvents from '_core/hooks/useEvents'
import useEventsUserSettings from '_core/hooks/useEventsUserSettings'
import useNavBarMenuItemsShown from '_core/hooks/useNavBarMenuItemsShown'
import useOnboarding from '_core/hooks/useOnboarding'
import useSearchQuery from '_core/hooks/useSearchQuery'
import useSidepanel from '_core/hooks/useSidepanel'
import useTagsEndpointParams from '_core/hooks/useTagsEndpointParams'

import DynamicEntity from '_core/DynamicEntity'
import UserSettings from '_core/UserSettings'

import { mergeUrlWithParams } from 'utils/httpUtils'
import { formatDate, getLocal } from 'utils/Utils'

import { LayoutContext } from 'Layout/LayoutContextProvider'

import Paths from 'Paths'

const useStyles = makeStyles()((theme) => ({
  noAccessBlock: {
    maxWidth: 540,
    margin: '0 auto'
  },
  columns: {
    width: 'unset',
    [theme.breakpoints.up('md')]: {
      height: 'calc(100vh - 119px)',
      overflow: 'hidden'
    }
  },
  column: {
    height: '100%'
  },
  preview: {
    borderLeft: '1px solid #f2f2f2',
    height: '100%',
    padding: `0px ${theme.spacing(2)}`
  },
  filters: {
    borderRight: '1px solid #f2f2f2',
    flex: 1,
    overflow: 'auto'
  },
  dateContainer: {
    padding: 0,
    position: 'sticky',
    top: 0,
    zIndex: 3,
    marginBottom: theme.spacing(1),
    display: 'flex',
    flex: 0,
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.up('md')]: {
      padding: `${theme.spacing(2)} 0px`
    }
  },
  today: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& .MuiTypography-h3': {
      lineHeight: '24px',
      marginBottom: 0,
      marginRight: theme.spacing(0.5)
    }
  }
}))

type ComponentType = {
  loading: boolean
  forceLoading: boolean
  toggleHide: () => void
  clearFilters: () => void
  items?: EventType[]
  setFilteredTotal?: Dispatch<SetStateAction<number | undefined>>
}

const format = 'MM-DD-YYYY'

const Component = (props: ComponentType) => {
  const { queryParams } = useSearchQuery<EventsPageParams, { modifyProps: [{ checked?: IncludeEvents[] }] }>(['checked'])

  const { checked = [], date } = queryParams
  const { startOnboarding } = useOnboarding()
  const loading = props.loading || props.forceLoading
  const showPast = JSON.parse(queryParams.showPast || 'false')
  const formattedDate = getLocal(date, format)
  const { items, total, update } = useEvents({ checked, date: formattedDate, showPast, loading, items: props.items })
  const { setFilteredTotal } = props

  useEffect(() => {
    if (setFilteredTotal && items) {
      setFilteredTotal(items.length)
    }
  }, [items, setFilteredTotal])

  useEffect(() => {
    if (!loading) {
      startOnboarding(Paths._events)
    }
  }, [loading])

  const isTodayOpened = formattedDate.diff(getLocal().startOf('day'), 'days') === 0

  return (
    <EventsList
      items={items}
      action={isTodayOpened ? <TogglePast toggle={props.toggleHide} showPast={showPast} disabled={loading} /> : null}
      update={update}
      empty={
        isTodayOpened ? (
          <TodayEmpty toggleHide={props.toggleHide} clearFilters={props.clearFilters} total={total} items={items} />
        ) : (
          <OtherDayEmpty clearFilters={props.clearFilters} total={total} />
        )
      }
    />
  )
}

type ErrorResult = {
  results: {
    errorMessage: string
  }
}

type AddProps = { extraProps: { addprops: Pick<ComponentType, 'forceLoading' | 'toggleHide' | 'clearFilters'> } }
const EventsPage = () => {
  const {
    classes: { columns, column, preview, dateContainer, today, filters }
  } = useStyles()
  const { setMobileHeader } = useContext(LayoutContext)
  const { search } = useLocation()
  const { queryParams, updateQuery } = useSearchQuery<EventsPageParams, { modifyProps: [{ checked?: IncludeEvents[] }] }>(['checked'])
  const { date, checked, showPast = 'true', includeTags, excludeTags } = queryParams

  const { hiddenNavbarItems } = useNavBarMenuItemsShown()
  const isEventsTabShown = hiddenNavbarItems && !hiddenNavbarItems.includes('events')
  const [loading, setLoading] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [filteredTotal, setFilteredTotal] = useState<number>()
  const { setInitial, handleChange, reset } = useEventsUserSettings({ setLoading })

  const { teamContextValue } = useContext(TeamContext)
  const { openUrl } = useSidepanel('preview')
  const wide = useWide()
  const [opened, setOpen] = useState(wide)
  const controllerRef = useRef<HTMLButtonElement | null>(null)

  const tagsParams = useTagsEndpointParams('meetings', includeTags, excludeTags)

  useEffect(() => {
    setMobileHeader('Events')
  }, [setMobileHeader])

  useEffect(() => {
    openUrl('/blank')
  }, [checked?.length, date, teamContextValue.teamNumber])

  const setDay = (day: MomentType) => {
    updateQuery({ date: formatDate(day) })
  }

  const toggleOpen = () => {
    setOpen((prev) => !prev)
  }

  const onInitialLoading = (loading: boolean, result: ErrorResult) => {
    setLoading(loading)
    const { errorMessage } = result?.results || {}
    if (errorMessage) {
      setErrorMessage(errorMessage)
    }
  }

  const toggleHide = async () => {
    await handleChange({ ...queryParams, showPast: `${!JSON.parse(showPast)}` })
  }

  const formattedDate = date ? getLocal(date, format) : getLocal()
  const dateTime = formattedDate.startOf('day').format()
  if (errorMessage) return <NoAccess />

  const dateSwitcher = (
    <Widget scope="none" className={dateContainer}>
      <PrevDateSwitch day={formattedDate} setDay={setDay} showText={wide} />
      <Box className={today}>
        <Box display="flex" alignItems="center">
          <WeekDateTitle day={formattedDate} />
          <Today day={formattedDate} setDay={setDay} />
        </Box>
        <Typography variant="h3" semiBold>
          {formatDate(formattedDate, 'ddd D, MMM')}
        </Typography>
      </Box>
      <NextDateSwitch day={formattedDate} setDay={setDay} showText={wide} />
    </Widget>
  )

  const params: { name: string; value?: string | number | boolean }[] = [
    { name: 'teamNumber', value: teamContextValue?.teamNumber },
    { name: 'dateTime', value: dateTime },
    { name: 'take', value: 100 },
    { name: 'skip', value: 0 },
    { name: 'withTags', value: true },
    ...tagsParams
  ]

  const payload = params.filter(({ value }) => !!value).reduce((acc, { name, value }) => ({ ...acc, [name]: `${value}` }), {})
  const eventsUrl = date ? mergeUrlWithParams(`/meetings/meetingswithparticipants`, payload) : null

  return (
    <UserSettings setInitial={setInitial} endpoint={isEventsTabShown && !search ? '/usersettings/homePage' : ''}>
      <Page>
        <Wide>
          <Columns className={columns}>
            <Column md={3} className={column}>
              <Box className={filters}>
                <Filters opened={opened} reset={reset} contentLoading={loading} setLoading={setLoading} handleChange={handleChange} />
              </Box>
            </Column>
            <Column md={5} className={column}>
              <Box flex={1} overflow="auto">
                {dateSwitcher}
                <DynamicEntity<AddProps>
                  url={eventsUrl}
                  id="events"
                  list={true}
                  onLoading={onInitialLoading}
                  addprops={{ forceLoading: loading || !date, clearFilters: reset, toggleHide }}
                  component={Component}
                />
              </Box>
            </Column>
            <Column md={4} className={column}>
              <Box className={preview}>
                <Preview empty="Please click on an event to see its details" />
              </Box>
            </Column>
          </Columns>
        </Wide>
        <Narrow>
          <Controller variant="text" opened={opened} toggleOpen={toggleOpen} ref={controllerRef} />
          <Filters
            opened={opened}
            contentLoading={loading}
            setLoading={setLoading}
            anchorEl={controllerRef.current}
            total={filteredTotal}
            handleChange={handleChange}
            reset={reset}
          />
          <Box mt={1}>
            {dateSwitcher}
            <DynamicEntity<AddProps & { extraProps: { addprops: Pick<ComponentType, 'setFilteredTotal'> } }>
              url={eventsUrl}
              id="events"
              list={true}
              onLoading={onInitialLoading}
              addprops={{ forceLoading: loading || !showPast, clearFilters: reset, toggleHide, setFilteredTotal }}
              component={Component}
            />
          </Box>
        </Narrow>
      </Page>
    </UserSettings>
  )
}

export default EventsPage
