import { useState, useEffect, useContext, useMemo, useRef, Dispatch, SetStateAction } from 'react'

import { useMsal } from '@azure/msal-react'
import { ElementSize, GridColDef } from '@mui/x-data-grid-pro'

import { TeamContext } from '_core/context/TeamContext'

import DownloadBulkControl from '_core/components/DownloadBulkControl'
import Filters, { getColleagueFlag } from '_core/components/filters/People'
import {
  companyColumn,
  jobTitleColumn,
  nameColumn,
  bestIntroducerColumn,
  scoreColumn,
  lastMeetingColumn,
  lastInboundColumn,
  lastOutboundColumn,
  nextFutureMeetingColumn
} from '_core/components/grid/columns'
import GridPageFrame from '_core/components/GridPageFrame'
import PeopleList, { PeopleListProps, Heading, GridHeadingButtons } from '_core/components/PeopleList'
import { sortMap } from '_core/components/sort'

import { DownloadBulkType, DownloadBulkParams } from '_core/hooks/useDownloadBulk'
import useInteractionsPeriodEndpointParams from '_core/hooks/useInteractionsPeriodEndpointParams'
import usePeopleUserSettings from '_core/hooks/usePeopleUserSettings'
import useSearchQuery from '_core/hooks/useSearchQuery'
import useSortStatsEndpointParams from '_core/hooks/useSortStatsEndpointParams'
import useTagsEndpointParams from '_core/hooks/useTagsEndpointParams'

import DynamicEntity from '_core/DynamicEntity'
import UserSettings from '_core/UserSettings'

import { getBinary, mergeUrlWithParams } from 'utils/httpUtils'

import { LayoutContext } from 'Layout/LayoutContextProvider'

import Paths from 'Paths'

type AddProps = {
  extraProps: {
    addprops: Pick<PeopleListProps, 'sort' | 'sortByField' | 'updateSort' | 'onResize' | 'forceLoading' | 'viewMode' | 'columns'> & {
      download: DownloadBulkType
    }
  }
}

const gridToolsHeight = 240
const underTopBarPos = 31

const People = ({
  download,
  ...props
}: Modify<PeopleListProps, { items: PersonListItem[] }> & {
  download: DownloadBulkType
}) => {
  const items = useMemo(
    () =>
      props.items?.map((person) => {
        const workExperience = {
          companyIdentity: person.BestJobCompanyMd5 || '',
          companyName: person.BestJobMatchedCompanyName || person.BestJobCorpLevelCompanyName || '',
          jobTitle: person.BestJobTitleText || '',
          currentAsOf: person.BestJobCurrentAsOf || ''
        }

        const formerJob = !!person.BestJobIsFormer
        const id = person.MyUserKeyMd5 || person.BestEmailAddrText?.replaceAll("'", '%27') || person.ContactUserKeyMd5 || person.PersonMd5
        const lastInbound = person.Stats?.LastInboundMsg || ''
        const lastOutbound = person.Stats?.LastOutboundMsg || ''
        const lastMeeting = person.Stats?.LastMeeting || ''
        const nextFutureMeeting = person.Stats?.NextFutureMeeting || ''

        return {
          id,
          name: person.PersonNameText,
          byline: workExperience.jobTitle,
          byline2: workExperience.companyName,
          title: workExperience.jobTitle,
          score: person.Score,
          link: `${Paths._people}/${person.MyUserKeyMd5 || person.BestEmailAddrText || person.ContactUserKeyMd5 || person.PersonMd5}`,
          email: person.BestEmailAddrText,
          userKey: person.BestEmailAddrText,
          company: {
            name: person.BestJobMatchedCompanyName || person.BestJobCorpLevelCompanyName || '',
            link: `${Paths._companies}/${workExperience.companyIdentity}`,
            sidepanel: true as SidepanelType
          },
          bestIntroducer: {
            name: person.BestKnowerNameText,
            link: `${Paths._people}/${person.BestKnowerUserKeyMd5}`,
            sidepanel: true as SidepanelType
          },
          formerJob,
          workExperience,
          sidepanel: true as SidepanelType,
          lastInbound,
          lastOutbound,
          lastMeeting,
          nextFutureMeeting,
          variant: 'expandable',
          privacy: person.Privacy,
          tags: person.Tags || []
        }
      }),
    [props.loading, props.items.length]
  )

  return (
    <PeopleList
      {...props}
      items={items}
      downloadAllControl={<DownloadBulkControl totalRows={props.total} disabled={props.loading} download={download} />}
    />
  )
}

type CProps = {
  onLoading: (loading: boolean, result: { total_item_count: number } | undefined) => void
  setHeight: Dispatch<SetStateAction<number | undefined>>
  onPageSizeChange: (rowsPerPage: NumberToString<RowPerPageOptionsType>) => void
  updateSort: (sort: ScoreType | StatSortType) => void
  columns: GridColDef[]
}

const Component = (props: CProps) => {
  const { teamContextValue } = useContext(TeamContext)
  const { queryParams } = useSearchQuery<PeoplePageParams, { modifyProps: [{ checked: IncludePeopleType[] }] }>(['checked'])

  const { accounts } = useMsal()
  const { username } = accounts[0] || {}

  const { setHeight } = props
  const { checked = [], sort, rowsPerPage = '10', keyword, perspective, and, viewMode, includeTags, excludeTags } = queryParams

  const interactionsPeriodParams = useInteractionsPeriodEndpointParams(queryParams)
  const sortStatsParams = useSortStatsEndpointParams(sort)
  const tagsParams = useTagsEndpointParams('people', includeTags, excludeTags)

  const prevColleagueFlag = useRef<ColleagueFlag>()
  const colleagueFlag = getColleagueFlag(checked)
  const [forceLoading, setForceLoading] = useState<boolean>(false)

  useEffect(() => {
    if (prevColleagueFlag.current === colleagueFlag) {
      setForceLoading(true)
      const timoutId = setTimeout(() => setForceLoading(false), 1000)

      return () => {
        setForceLoading(false)
        clearTimeout(timoutId)
      }
    }
    prevColleagueFlag.current = colleagueFlag
  }, [colleagueFlag, setForceLoading, checked.length])

  const onResize = (containerSize: ElementSize) => {
    if (setHeight && containerSize?.height) {
      setHeight(window.innerHeight - 89 > containerSize?.height + gridToolsHeight ? containerSize?.height + gridToolsHeight : 0)
    }
  }

  const sortByField = Object.keys(sortMap).find((key) => sortMap[key].asc === sort || sortMap[key].desc === sort)

  const params: { name: string; value?: any }[] = [
    { name: 'teamNumber', value: teamContextValue?.teamNumber },
    { name: 'ColleagueFlag', value: colleagueFlag },
    { name: 'WhereNoneNextFutureMeeting', value: !!and },
    { name: 'IncludeStats', value: true },
    { name: 'WithPersonTags', value: true },
    ...sortStatsParams,
    ...interactionsPeriodParams,
    ...tagsParams
  ]

  const payload = params.filter(({ value }) => !!value).reduce((acc, { name, value }) => ({ ...acc, [name]: `${value}` }), {})

  const url = username && perspective ? mergeUrlWithParams(perspective === 'User' ? `/users/${username}/people` : '/people', payload) : null

  const download = {
    fileName: perspective === 'User' ? 'user_people' : 'people',
    requestBinary: (data: DownloadBulkParams) =>
      getBinary('/people/xl', {
        ...(perspective === 'User' ? { relationOfUserKey: username } : {}),
        ...payload,
        ...data,
        numIntroducers: '5',
        numEmails: '5',
        numPhones: '3',
        numJobs: '3'
      })
  }

  return (
    <DynamicEntity<AddProps>
      url={url}
      addprops={{
        forceLoading,
        sort,
        onResize,
        updateSort: props.updateSort,
        viewMode: viewMode || 'collapsed',
        sortByField: sortByField || 'score',
        columns: props.columns,
        download
      }}
      pageSize={+rowsPerPage}
      updatePageSize={props.onPageSizeChange}
      component={People}
      onLoading={props.onLoading}
      autoHideOnScroll
      list
      infinite
      search
      keepMounted
      empty="No results found"
      emptySubtitle={keyword ? `No results found for your search '${keyword}'` : ''}
      id="people_list"
    />
  )
}

const PeoplePage = () => {
  const { setMobileHeader } = useContext(LayoutContext)
  const { queryParams, updateQuery } = useSearchQuery<PeoplePageParams, { modifyProps: [{ checked: IncludePeopleType[] }] }>(['checked'])

  const [height, setHeight] = useState<number>()
  const [total, setTotal] = useState<number>()
  const [contentLoading, setContentLoading] = useState<boolean>(false)

  const {
    setInitial,
    reset,
    interactionsFiltersShown,
    interactionsColumnsShown,
    handleChange,
    toggleInteractionSwitch,
    loading: filtersLoading,
    opened,
    toggleOpen: toggleFilterOpen
  } = usePeopleUserSettings()

  const disabled = filtersLoading || interactionsFiltersShown === null

  const { sort, viewMode, excludeEmpty } = queryParams

  useEffect(() => {
    setMobileHeader('People')
  }, [setMobileHeader])

  useEffect(() => {
    window.scrollTo({ top: window.scrollY < underTopBarPos ? window.scrollY : underTopBarPos })
  }, [total])

  const updateViewMode = (viewMode: ViewModeType) => {
    handleChange({ viewMode })
  }

  const updateSort = (sort: ScoreType | StatSortType) => {
    handleChange({ sort })
  }

  const toggleExclude = () => {
    updateQuery({ excludeEmpty: excludeEmpty === 'true' ? 'false' : 'true' })
  }

  const onPageSizeChange = (rowsPerPage: NumberToString<RowPerPageOptionsType>) => {
    handleChange({ rowsPerPage })
  }

  const onLoading = (loading: boolean, result: { total_item_count: number } | undefined) => {
    setContentLoading(loading)
    setTotal(result?.total_item_count)
  }

  const filtersProps = {
    toggleOpen: toggleFilterOpen,
    interactionsFiltersShown: !!interactionsFiltersShown,
    disabled,
    opened,
    total,
    contentLoading,
    queryData: queryParams,
    handleChange,
    reset,
    toggleInteractionSwitch
  }

  const columns = useMemo(
    () =>
      [
        { column: nameColumn },
        { column: jobTitleColumn },
        { column: companyColumn },
        { column: bestIntroducerColumn },
        { column: lastInboundColumn, condition: !!interactionsColumnsShown },
        { column: lastOutboundColumn, condition: !!interactionsColumnsShown },
        { column: lastMeetingColumn, condition: !!interactionsColumnsShown },
        { column: nextFutureMeetingColumn, condition: !!interactionsColumnsShown },
        { column: scoreColumn }
      ]
        .filter(({ condition }) => typeof condition !== 'boolean' || !!condition)
        .map(({ column }) => column),
    [interactionsColumnsShown]
  )

  const sortItems = columns.filter(({ sortable }) => sortable).map(({ headerName, field }) => ({ label: headerName || '', field }))

  const searchPlaceholder = 'Search for people'
  return (
    <UserSettings endpoint="/usersettings/peoplesearchfilter" setInitial={setInitial}>
      <GridPageFrame
        stickFilters
        loading={typeof total !== 'number'}
        filterHeight={height}
        gridTitle="People"
        searchPlaceholder={searchPlaceholder}
        disabledSearch={disabled}
        filters={<Filters {...filtersProps} />}
        heading={
          <Heading
            filters={<Filters {...filtersProps} />}
            filtersProps={{ opened: filtersProps.opened, toggleOpen: filtersProps.toggleOpen, disabled: filtersProps.disabled }}
            sortProps={{ sort, updateSort, items: sortItems, toggleExclude, excludeEmpty: excludeEmpty === 'true' }}
            viewProps={{ viewMode, updateViewMode }}
            searchPlaceholder={searchPlaceholder}
          />
        }
        headingAdornmentEnd={GridHeadingButtons}
        component={
          <Component columns={columns} setHeight={setHeight} onLoading={onLoading} updateSort={updateSort} onPageSizeChange={onPageSizeChange} />
        }
      />
    </UserSettings>
  )
}

export default PeoplePage
