import React, { SyntheticEvent, useCallback, useEffect, useState } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box } from '@mui/material'
import { useSnackbar } from 'notistack'
import { makeStyles } from 'tss-react/mui'

import { Button, IconButton } from '_shared/buttons'
import FloatingButton from '_shared/buttons/FloatingButton'
import Dialog, { DialogActions, DialogContent, DialogTitle } from '_shared/Dialog'
import TextField from '_shared/forms/TextField'

import { SharingLevel } from '_core/components/dialogs/SharingLevel'
import { SidepanelNarrow, SidepanelWide } from '_core/components/layout'

import useSharingLevel from '_core/hooks/useSharingLevel'
import useSidepanelPayloads from '_core/hooks/useSidepanelPayloads'
import useTeam, { TeamCreationData, TeamUpdateData } from '_core/hooks/useTeam'
import { UserTeamRelations } from '_core/hooks/useUserMembership'

type TeamModalProps = {
  isOpen: boolean
  close: (e: SyntheticEvent) => void
  handleAddClick?: (arg: TeamCreationData) => void
  handleUpdateClick?: (arg: TeamUpdateData) => void
  team?: Team
  mode: 'creating' | 'updating'
  userRelations?: UserTeamRelations[]
  userMembership?: UserTeamRelations
  reload?: any
}

const useStyles = makeStyles()(() => ({
  floatingButton: {
    fontSize: '30px',
    lineHeight: '30px',
    zIndex: 1
  }
}))

const TriggerCreateEl = ({ variant, open }: { open: () => void; variant?: 'default' | 'fab' }) => {
  const { classes } = useStyles()
  return (
    <>
      {variant === 'default' && (
        <Button color="primary" onClick={open} startIcon={<FontAwesomeIcon icon={['far', 'plus']} style={{ fontSize: 18 }} />}>
          CREATE TEAM
        </Button>
      )}

      {variant === 'fab' && (
        <FloatingButton className={classes.floatingButton} onClick={open}>
          +
        </FloatingButton>
      )}
    </>
  )
}

const TriggerUpdateEl = ({ loading, className, open }: { loading: boolean; className: string; open: (e: SyntheticEvent) => void }) => {
  return (
    <>
      <SidepanelWide>
        <Button
          color="primary"
          disabled={loading}
          startIcon={<FontAwesomeIcon icon={['far', 'edit']} style={{ fontSize: 14 }} />}
          onClick={open}
          className={className}
        >
          Edit team
        </Button>
      </SidepanelWide>
      <SidepanelNarrow>
        <IconButton
          color="primary"
          hint="Edit team"
          loading={loading}
          icon={['far', 'edit']}
          style={{ fontSize: 14 }}
          onClick={open}
          className={className}
        />
      </SidepanelNarrow>
    </>
  )
}

const TeamModal = (props: TeamModalProps) => {
  const maxLength = 32
  const { isOpen, close, team, userRelations, mode, userMembership, handleAddClick, reload } = props

  const [teamName, setTeamName] = useState<string>(props?.team?.name || '')
  const [counter, setCounter] = useState<number>(props?.team?.name?.length || 0)
  const [load, setLoad] = useState<boolean>(false)

  const { updateParent } = useSidepanelPayloads()
  const { enqueueSnackbar } = useSnackbar()
  const { updateTeam } = useTeam()

  const getUserSharing = useCallback((): SharingDataRes | undefined => {
    if (!userRelations || !userMembership) {
      return {
        shareRelationships: true,
        shareContactInfo: true,
        shareInteractionStats: true,
        shareContactInfoWithAdminsAndCurators: true
      }
    }

    return {
      shareRelationships: userMembership.isSharing || false,
      shareContactInfo: userMembership.isSharingContactInfo || false,
      shareInteractionStats: userMembership.isSharingInteractionStats || false,
      shareContactInfoWithAdminsAndCurators: userMembership.isSharingContactInfoWithAdminsAndCurators || false
    }
  }, [userRelations, userMembership])

  const { userSharing, sharingContactInfoLevel, handleInteractionClick, handleSelect, handleSharingClick } = useSharingLevel(getUserSharing)

  const isCreating = mode === 'creating'
  const isUpdating = mode === 'updating'

  useEffect(() => {
    if (team?.name) {
      setTeamName(team?.name)
    }
  }, [team])

  const handleSubmit = async (e: SyntheticEvent) => {
    if (userSharing) {
      if (isCreating && handleAddClick) {
        handleAddClick({ teamName, ...userSharing })
        close(e)
      }
      if (isUpdating) {
        setLoad(true)
        try {
          const data = await updateTeam({ teamName, teamNumber: team!.id, ...userSharing })
          if (data) {
            setTimeout(() => {
              updateParent({ action: 'EDIT_TEAM', value: data })
              enqueueSnackbar(`Team "${data.name}" has been updated`, {
                variant: 'default'
              })
              setLoad(false)
              close(e)
              if (reload) reload()
            }, 1000)
          }
        } catch (e) {
          console.log(e)
          setLoad(false)
        }
      }
    }
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    setTeamName(value)
    setCounter(value.length)
  }

  const handleBlur = () => {
    setTeamName((prevState) => prevState.trim())
    setCounter(teamName.trim().length)
  }

  return (
    <Dialog
      open={isOpen}
      onClose={close}
      loading={load}
      title={
        <DialogTitle
          title={isCreating ? 'Create a team' : 'Update team'}
          titleLink="https://help.dotalign.com/article/m3k4f5u6cz-about-sharing"
          titleLinkTooltip="Learn more about data sharing"
        />
      }
    >
      <DialogContent>
        <Box mb={2}>
          <TextField
            id="team-name"
            value={teamName}
            onChange={handleChange}
            placeholder="Team name"
            fullWidth
            autoFocus={isCreating}
            onBlur={handleBlur}
            errorMessage={counter > maxLength ? 'Name is too long' : ''}
            helperText={`${counter} / ${maxLength}`}
          />
        </Box>
        <SharingLevel
          userSharing={userSharing}
          sharingContactInfoLevel={sharingContactInfoLevel}
          handleInteractionClick={handleInteractionClick}
          handleSelect={handleSelect}
          handleSharingClick={handleSharingClick}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={close} color="secondary">
          Cancel
        </Button>
        <Button variant="text" onClick={handleSubmit} disablePR disabled={teamName.length > maxLength || teamName.length === 0}>
          {isCreating ? 'Create' : 'Save'}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

TeamModal.TriggerUpdateEl = TriggerUpdateEl
TeamModal.TriggerCreateEl = TriggerCreateEl

export default TeamModal
