import useAdmin from '_core/hooks/useAdmin'
import usePrivateFeatures from '_core/hooks/usePrivateFeatures'
import useUserDataVisibility from '_core/hooks/useUserDataVisibility'

type ShownMenuItems = 'events' | 'activities' | 'apiConsole' | 'introductions'

const useNavBarMenuItemsShown = () => {
  const { dataVisibility, dataVisibilityLoading } = useUserDataVisibility()
  const {
    loading: privateFeaturesLoading,
    introductionsEnabled,
    activitiesStreamEnabled,
    userIntroductionsAccess,
    userActivitiesAccess
  } = usePrivateFeatures()
  const admin = useAdmin()
  const { showEventsTab, restrictAPIConsoleAccess } = dataVisibility || {}

  const menuItemsToHide: { itemId: ShownMenuItems; condition: boolean }[] = [
    { itemId: 'activities', condition: !activitiesStreamEnabled || !userActivitiesAccess },
    { itemId: 'events', condition: !showEventsTab },
    { itemId: 'apiConsole', condition: restrictAPIConsoleAccess === true && !admin },
    { itemId: 'introductions', condition: !introductionsEnabled || !userIntroductionsAccess }
  ]
  const loading = dataVisibilityLoading || privateFeaturesLoading || typeof admin === 'undefined'
  const hiddenNavbarItems = menuItemsToHide.filter(({ condition }) => !!condition).map(({ itemId }) => itemId)

  if (loading) {
    return {
      hiddenNavbarItemsLoading: loading
    }
  }

  return {
    hiddenNavbarItemsLoading: loading,
    hiddenNavbarItems
  }
}

export default useNavBarMenuItemsShown
