import { useContext, useState } from 'react'

import { Box } from '@mui/material'

import { PrivateFeaturesContext } from '_core/context/PrivateFeatures'

import { IconButton } from '_shared/buttons'
import Checkbox, { useStyles as useCheckboxStyles } from '_shared/forms/Checkbox'

import Settings, { useStyles } from '_core/components/settings'

import { SaveAction } from '_core/hooks/useSaveSettings'
import useUserDataVisibility from '_core/hooks/useUserDataVisibility'

const DataVisibilitySettings = () => {
  const { dataVisibility, saveDataVisibility, dataVisibilityLoading: isSaving } = useUserDataVisibility()

  const [settingsData, setSettingsData] = useState(dataVisibility)
  const {
    classes: { icon, itemWrapper },
    cx
  } = useStyles()
  const { classes: checkboxClasses } = useCheckboxStyles({ align: 'top' })

  const save = async (action: SaveAction) => {
    const actionText = 'Data visibility settings'
    if (settingsData) {
      try {
        await saveDataVisibility(settingsData)
        action(actionText)
      } catch {
        setSettingsData(dataVisibility)
        action(actionText, 'error')
      }
    }
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target
    setSettingsData((prevSettingsData) => (prevSettingsData ? { ...prevSettingsData, [name]: checked } : prevSettingsData))
  }

  return (
    <Settings isSaving={isSaving} save={save}>
      {settingsData && (
        <Box width="100%">
          <Box display="flex" alignItems="center" className={itemWrapper}>
            <Checkbox checked={settingsData.showEventsTab} name="showEventsTab" onChange={handleChange} label="Show events tab" disabled={isSaving} />
            <IconButton
              classes={{ root: icon }}
              component="a"
              target="_blank"
              href="https://help.dotalign.com/article/7t2k2kpfzt-events-page"
              icon={['far', 'question-circle']}
              hint="Learn more about events and interactions"
              disabled={isSaving}
            />
          </Box>
          <Box display="flex" alignItems="center" className={itemWrapper}>
            <Checkbox
              checked={settingsData.showInteractionsInProfile}
              name="showInteractionsInProfile"
              onChange={handleChange}
              label="Show interactions inline with profile"
              disabled={isSaving}
            />
            <IconButton
              classes={{ root: icon }}
              component="a"
              target="_blank"
              href="https://help.dotalign.com/article/7t2k2kpfzt-events-page"
              icon={['far', 'question-circle']}
              hint="Learn more about events and interactions"
              disabled={isSaving}
            />
          </Box>
          <Checkbox
            checked={settingsData.allowFilteringByInteractions}
            name="allowFilteringByInteractions"
            onChange={handleChange}
            label="Allow filtering of people and companies by interaction data"
            classes={{ ...checkboxClasses, root: cx(checkboxClasses.root, itemWrapper) }}
            disabled={isSaving}
          />
          <Checkbox
            checked={settingsData.hideColleagueRelationships}
            name="hideColleagueRelationships"
            onChange={handleChange}
            label="Hide other contributors' relationship lists"
            classes={{ ...checkboxClasses, root: cx(checkboxClasses.root, itemWrapper) }}
            disabled={isSaving}
          />
          <Checkbox
            checked={settingsData.preventNonAdminDownload}
            name="preventNonAdminDownload"
            onChange={handleChange}
            label="Prevent non-admins from downloading relationship lists"
            classes={{ ...checkboxClasses, root: cx(checkboxClasses.root, itemWrapper) }}
            disabled={isSaving}
          />
          <Checkbox
            checked={settingsData.restrictAPIConsoleAccess}
            name="restrictAPIConsoleAccess"
            onChange={handleChange}
            label="Restrict the API console only to admins"
            classes={{ ...checkboxClasses, root: cx(checkboxClasses.root, itemWrapper) }}
            disabled={isSaving}
          />
        </Box>
      )}
    </Settings>
  )
}

export default DataVisibilitySettings
