import React, { useState, useCallback } from 'react'

import { Box, Divider, Switch, SelectChangeEvent } from '@mui/material'
import { useSnackbar } from 'notistack'
import { makeStyles } from 'tss-react/mui'

import { Button } from '_shared/buttons'
import Dialog, { DialogActions, DialogTitle, DialogContent } from '_shared/Dialog'
import Select, { OptionType } from '_shared/forms/Select'
import Skeleton from '_shared/Skeleton'
import Typography from '_shared/Typography'

import { useWide } from '_core/components/layout'

import useSharingLevel from '_core/hooks/useSharingLevel'
import useTeam from '_core/hooks/useTeam'
import { UserTeamRelations } from '_core/hooks/useUserMembership'

type SharingLevelProps = {
  userSharing: SharingDataRes | undefined
  sharingContactInfoLevel: number
  handleInteractionClick: () => Promise<void>
  handleSelect: (event: SelectChangeEvent<unknown>) => void
  handleSharingClick: () => Promise<void>
}

type SharingLevelDialogProps = {
  isOpen: boolean
  handleRefresh?: () => void
  team: Team
  userRelations?: UserTeamRelations[]
  userMembership?: UserTeamRelations
  setUserMembership?: any
  close: (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | React.FormEvent<HTMLFormElement>) => void
}

const useStyles = makeStyles()((theme) => ({
  modalContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      height: '100%',
      maxWidth: 'unset',
      maxHeight: 'unset',
      margin: 0,
      borderRadius: 0,
      justifyContent: 'flex-start'
    }
  },
  teamName: {
    display: 'flex',
    marginTop: '15px'
  },
  cardAvatar: {
    width: '50px',
    height: '50px',
    fontSize: '36px',
    lineHeight: '42px',
    backgroundColor: '#451BBB',
    color: 'white',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: '16px'
  },
  checkboxContainer: {
    marginTop: '15px',
    marginLeft: '-10px',
    display: 'flex'
  },
  checkboxInfo: {
    marginLeft: '43px'
  },
  checkbox: {
    marginRight: '15px',
    minWidth: '32px',
    minHeight: '32px',
    alignSelf: 'center'
  },
  settingsRow: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '16px',
    '& > div:first-of-type': {
      width: '70%'
    },
    '& .MuiSelect-outlined': {
      [theme.breakpoints.down('md')]: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        paddingLeft: theme.spacing(2)
      }
    }
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  settingsColumn: {
    '@media (max-width:500px)': {
      flexDirection: 'column',
      '& > div:nth-of-type(1)': {
        width: '100%'
      },
      '& > div:nth-of-type(2)': {
        margin: '16px 0 0 0',
        width: '100%'
      }
    }
  },
  sharingHeading: {
    display: 'flex',
    alignItems: 'center'
  },
  sharingText: {
    paddingTop: '5px'
  },
  icon: {
    fontSize: '20px',
    marginRight: '5px',
    color: 'rgba(0,0,0,0.6)'
  },
  headingContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottom: '1px solid #ECEEF0'
  },
  heading: {
    fontWeight: 600,
    color: '#000',
    padding: '8px 0'
  },
  switch: {
    marginRight: `-${theme.spacing(1)}`
  },
  subtitle: {
    lineHeight: '18px'
  }
}))

export const SharingLevel = (props: SharingLevelProps) => {
  const { userSharing, sharingContactInfoLevel, handleInteractionClick, handleSelect, handleSharingClick } = props

  const { classes, cx } = useStyles()

  const shareContactInfoOptions: OptionType[] = [
    {
      value: 1,
      label: 'No sharing'
    },
    {
      value: 2,
      label: 'Share with all members'
    },
    {
      value: 3,
      label: 'Share only with admins and curators'
    }
  ]

  return (
    <>
      <Box className={classes.settingsRow} style={{ marginTop: 0 }}>
        <Box>
          <Typography variant="h4">Share relationships</Typography>
          <Typography color="text.secondary" className={classes.subtitle}>
            Share the people and companies you know, along with relationship scores
          </Typography>
        </Box>
        <Switch color="primary" checked={!!userSharing?.shareRelationships} onChange={handleSharingClick} classes={{ root: classes.switch }} />
      </Box>
      <Divider classes={{ root: classes.divider }} />
      <Typography variant="h4" semiBold>
        Include
      </Typography>
      <Box className={classes.settingsRow}>
        <Box>
          <Typography variant="h4">Share interaction statistics</Typography>
          <Typography color="text.secondary" className={classes.subtitle}>
            Share touchpoint data, for example when was the latest inbound and outbound email message
          </Typography>
        </Box>
        <Switch color="primary" checked={!!userSharing?.shareInteractionStats} onChange={handleInteractionClick} classes={{ root: classes.switch }} />
      </Box>
      <Box className={cx(classes.settingsRow, classes.settingsColumn)}>
        <Box>
          <Typography variant="h4">Share contact information</Typography>
          <Typography color="text.secondary" className={classes.subtitle}>
            Share email addresses and phone numbers
          </Typography>
        </Box>
        <Select options={shareContactInfoOptions} onChange={handleSelect} value={sharingContactInfoLevel} size="small" />
      </Box>
    </>
  )
}

const TriggerEl = ({ loading, open }: { loading: boolean; open: () => void }) => {
  return (
    <Skeleton variant="text" condition={loading}>
      <Button size="small" variant="text" color="primary" onClick={open} disablePR>
        Edit
      </Button>
    </Skeleton>
  )
}

const SharingLevelDialog = ({ isOpen, close, userRelations, team, userMembership, handleRefresh, setUserMembership }: SharingLevelDialogProps) => {
  const { enqueueSnackbar } = useSnackbar()
  const { editSharingStatus } = useTeam()
  const wide = useWide()

  const getUserSharing = useCallback((): SharingDataRes | undefined => {
    if (!userRelations || !userMembership) {
      return undefined
    }

    return {
      shareRelationships: userMembership.isSharing || false,
      shareContactInfo: userMembership.isSharingContactInfo || false,
      shareInteractionStats: userMembership.isSharingInteractionStats || false,
      shareContactInfoWithAdminsAndCurators: userMembership.isSharingContactInfoWithAdminsAndCurators || false
    }
  }, [userRelations, userMembership])

  const { userSharing, sharingContactInfoLevel, handleInteractionClick, handleSelect, handleSharingClick } = useSharingLevel(getUserSharing)

  const [loading, setLoading] = useState(false)

  const confirmSharingClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent> | React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (userSharing) {
      const newSharing = {
        teamNumber: team!.id,
        shareRelationships: userSharing.shareRelationships,
        shareContactInfo: userSharing.shareContactInfo,
        shareInteractionStats: userSharing.shareInteractionStats,
        shareContactInfoWithAdminsAndCurators: userSharing.shareContactInfoWithAdminsAndCurators
      }
      setLoading(true)
      editSharingStatus(newSharing)
        .then(() => {
          enqueueSnackbar(`Your sharing level has been updated successfully`)
        })
        .catch((error) => console.log(error))
        .finally(() => {
          setLoading(false)
          if (close) {
            close(e)
          }
          setUserMembership((prevState: any) => {
            return {
              ...prevState,
              isSharing: newSharing.shareRelationships,
              isSharingContactInfo: newSharing.shareContactInfo,
              isSharingInteractionStats: newSharing.shareInteractionStats,
              isSharingContactInfoWithAdminsAndCurators: newSharing.shareContactInfoWithAdminsAndCurators
            }
          })
          if (wide && handleRefresh) {
            handleRefresh()
          }
        })
    }
  }

  return (
    <Dialog
      open={isOpen}
      onClose={close}
      loading={loading}
      title={
        <DialogTitle
          title="Choose your sharing level"
          titleLink="https://help.dotalign.com/article/m3k4f5u6cz-about-sharing"
          titleLinkTooltip="Learn more about data sharing"
        />
      }
    >
      <form onSubmit={confirmSharingClick}>
        <DialogContent>
          <SharingLevel
            userSharing={userSharing}
            sharingContactInfoLevel={sharingContactInfoLevel}
            handleInteractionClick={handleInteractionClick}
            handleSelect={handleSelect}
            handleSharingClick={handleSharingClick}
          />
        </DialogContent>
        <DialogActions>
          <Button variant="text" onClick={close} color="secondary">
            Cancel
          </Button>
          <Button variant="text" type="submit" disablePR>
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}

SharingLevelDialog.TriggerEl = TriggerEl

export default SharingLevelDialog
