import React, { useCallback, useEffect, useState, forwardRef, ElementType } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link, TextFieldProps, Box, InputProps } from '@mui/material'
import { DatePicker as MuiDatePicker, DatePickerProps } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { PickersActionBarProps } from '@mui/x-date-pickers/PickersActionBar'
import moment from 'moment'
import { makeStyles, withStyles } from 'tss-react/mui'

import TextField from '_shared/forms/TextField'

import { getLocal } from 'utils/Utils'

import Typography from './Typography'

const useStyles = makeStyles()((theme) => ({
  root: {
    borderRadius: '50%'
  },
  backToToday: {
    cursor: 'pointer',
    textDecoration: 'none'
  },
  adornedEnd: {
    paddingRight: 0
  },
  notchedOutline: {
    borderWidth: '2px',
    borderColor: theme.palette.primary.main
  }
}))

moment.updateLocale('en', {
  week: {
    dow: 1
  }
})

const OpenPickerIcon = () => <FontAwesomeIcon icon={['far', 'calendar-alt']} style={{ fontSize: 14 }} />

const Picker = forwardRef(
  (
    props: Modify<DatePickerProps<any, any>, { renderInput?: DatePickerProps<any, any>['renderInput'] }> &
      Pick<InputProps, 'size' | 'fullWidth' | 'placeholder'> & {
        ActionBar?: ElementType<PickersActionBarProps> | null
      },
    ref: any
  ) => {
    const { classes, cx } = useStyles()
    const [selectedDate, setSelectedDate] = useState<unknown>()

    const renderDefaultInput = useCallback(
      ({ inputProps, ...params }: TextFieldProps) => {
        return (
          <TextField
            {...params}
            InputProps={{
              ...params.InputProps,
              classes: {
                notchedOutline: cx({ [classes.notchedOutline]: props.open })
              }
            }}
            size={props.size || 'small'}
            fullWidth={props.fullWidth}
            helperText=""
            disabled={props.disabled}
            inputProps={{ ...inputProps, placeholder: props.placeholder }}
          />
        )
      },
      [classes.notchedOutline, props.open, props.disabled, props.placeholder, props.size, props.fullWidth]
    )
    const backToToday = () => {
      onChange(getLocal())
      onClose && onClose()
    }

    const DefaultActionBar = () => (
      <Box p={2} display="flex" justifyContent="flex-end">
        <Typography>
          <Link classes={{ root: classes.backToToday }} onClick={backToToday}>
            today
          </Link>
        </Typography>
      </Box>
    )

    const {
      inputFormat,
      value,
      onChange,
      onError,
      onOpen,
      renderInput = renderDefaultInput,
      open,
      onClose,
      mask,
      size,
      fullWidth,
      ActionBar = DefaultActionBar,
      ...rest
    } = props

    useEffect(() => {
      if ((selectedDate || selectedDate === '') && onError) {
        onError(null, selectedDate || null)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedDate])

    const handleChange = (date: unknown, keyboardInputValue?: string | undefined) => {
      onChange(date, keyboardInputValue)
      setSelectedDate(!keyboardInputValue ? date || '' : null)
    }

    const components = {
      OpenPickerIcon,
      ...(ActionBar === null ? {} : { ActionBar })
    }

    return (
      <LocalizationProvider dateLibInstance={moment} dateAdapter={AdapterMoment}>
        <DatePicker
          ref={ref}
          open={open}
          value={value}
          onChange={handleChange}
          onClose={onClose}
          onOpen={onOpen}
          renderInput={renderInput}
          onError={onError}
          inputFormat={inputFormat || 'MM/DD/YYYY'}
          mask={mask || '__/__/____'}
          showToolbar={false}
          components={components}
          {...rest}
        />
      </LocalizationProvider>
    )
  }
)

export default Picker

const DatePicker = withStyles(MuiDatePicker, (theme) => ({
  root: {
    [theme.breakpoints.down('phone')]: {
      '&[class*="MuiCalendarOrClockPicker"] > div': {
        width: '100vw',
        maxWidth: 320
      },
      '.MuiCalendarPicker-root': {
        width: '100%'
      },
      '.MuiMonthPicker-root': {
        boxSizing: 'border-box',
        width: '100vw',
        maxWidth: 320,
        margin: 0,
        padding: '0 4px'
      },
      '.PrivatePickersYear-root': {
        flexBasis: '33%'
      },
      '.PrivatePickersYear-button': {
        width: '100%'
      }
    }
  }
}))
