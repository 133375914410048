import React, { ReactElement } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'

import NameLink from '_core/components/NameLink'

import Paths from 'Paths'

export type StatsType<EntityMd5 extends { [key: string]: string }> = EntityMd5 & {
  FirstInboundMsg: string
  FirstInboundMsgContactMd5: string
  FirstInboundMsgUserKeyMd5: string
  FirstMeeting: string
  FirstMeetingContactMd5: string
  FirstMeetingUserKeyMd5: string
  FirstOutboundMsg: string
  FirstOutboundMsgContactMd5: string
  FirstOutboundMsgUserKeyMd5: string
  LastInboundMsg: string
  LastInboundMsgContactMd5: string
  LastInboundMsgUserKeyMd5: string
  LastMeeting: string
  LastMeetingContactMd5: string
  LastMeetingUserKeyMd5: string
  LastOutboundMsg: string
  LastOutboundMsgContactMd5: string
  LastOutboundMsgUserKeyMd5: string
  LastActivity: string
  LastActivityUserKeyMd5: string
  NumInboundMsgs: number
  NumMeetings: number
  NumOutboundMsgs: number
  LastMeetingContactName: string
  LastOutboundMsgContactName: string
  LastInboundMsgContactName: string
  FirstMeetingContactName: string
  FirstOutboundMsgContactName: string
  FirstInboundMsgContactName: string
  LastMeetingUserName: string
  LastOutboundMsgUserName: string
  LastInboundMsgUserName: string
  FirstMeetingUserName: string
  FirstOutboundMsgUserName: string
  FirstInboundMsgUserName: string
  NextFutureMeeting?: string
  NextFutureMeetingContactMd5?: string
  NextFutureMeetingUserKeyMd5?: string
  NextFutureMeetingContactName?: string
  NextFutureMeetingUserName?: string
}

const TouchpointRelations = (stats: StatsType<{ CompanyMd5: string } | { PersonMd5: string }>, key: 'First' | 'Last' | 'NextFuture') =>
  stats &&
  (
    [
      stats[`${key}Meeting`] && {
        title: 'Meeting',
        date: stats[`${key}Meeting`],
        description: (
          <>
            <FontAwesomeIcon icon={['far', 'calendar-alt']} size="sm" color="#1B95BB" style={{ marginRight: '8px' }} />
            <NameLink url={`${Paths._people}/${stats[`${key}MeetingUserKeyMd5`]}`} name={stats[`${key}MeetingUserName`] || ''} />
            <NameLink
              url={`${Paths._people}/${stats[`${key}MeetingContactMd5`]}`}
              name={stats[`${key}MeetingContactName`] || ''}
              preposition={`${key === 'NextFuture' ? 'is meeting' : 'met'}`}
            />
          </>
        ),
        blurb: moment(stats[`${key}Meeting`]).format('DD MMMM YYYY')
      },
      key !== 'NextFuture' &&
        stats[`${key}InboundMsg`] && {
          title: 'Message received',
          date: stats[`${key}InboundMsg`],
          description: (
            <>
              <FontAwesomeIcon icon={['fas', 'comment-alt-lines']} size="sm" color="#1B95BB" style={{ marginRight: '8px' }} />
              <NameLink url={`${Paths._people}/${stats[`${key}InboundMsgUserKeyMd5`]}`} name={stats[`${key}InboundMsgUserName`]} />
              <NameLink
                url={`${Paths._people}/${stats[`${key}InboundMsgContactMd5`]}`}
                name={stats[`${key}InboundMsgContactName`]}
                preposition="from"
              />
            </>
          ),
          blurb: moment(stats[`${key}InboundMsg`]).format('DD MMMM YYYY')
        },
      key !== 'NextFuture' &&
        stats[`${key}OutboundMsg`] && {
          title: 'Message sent',
          date: stats[`${key}OutboundMsg`],
          description: (
            <>
              <FontAwesomeIcon icon={['fas', 'comment-alt-lines']} size="sm" color="#1B95BB" style={{ marginRight: '8px' }} />
              <NameLink url={`${Paths._people}/${stats[`${key}OutboundMsgUserKeyMd5`]}`} name={stats[`${key}OutboundMsgUserName`]} />
              <NameLink
                url={`${Paths._people}/${stats[`${key}OutboundMsgContactMd5`]}`}
                name={stats[`${key}OutboundMsgContactName`]}
                preposition="to"
              />
            </>
          ),
          blurb: moment(stats[`${key}OutboundMsg`]).format('DD MMMM YYYY')
        }
    ].filter((key) => key) as { title: string; date: string; description: ReactElement; blurb: string }[]
  ).sort((a, b) => ((key === 'Last' ? a.date < b.date : a.date > b.date) ? 1 : -1))

export default TouchpointRelations
