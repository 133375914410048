import React, { useContext, useEffect, useMemo, useState } from 'react'

import { Box } from '@mui/material'
import { GridSelectionModel } from '@mui/x-data-grid-pro'
import { makeStyles } from 'tss-react/mui'

import { PrivilegedContact } from '_pages/data-administration/privileged-companies/contacts/add'

import { TeamContext } from '_core/context/TeamContext'

import { IMenuAction } from '_shared/buttons/FloatingMenuButton'

import RemoveSelectedDialog from '_core/components/dialogs/RemoveSelected'
import { ExpandableContent as Content } from '_core/components/ExpandableDetails'
import { DataGrid, GridTypes } from '_core/components/grid'
import { dateColumn, editorColumn, emailColumn, nameColumn } from '_core/components/grid/columns'
import GridPageFrame from '_core/components/GridPageFrame'
import { Narrow, Wide } from '_core/components/layout'
import Repeater from '_core/components/lists/Repeater'
import { Buttons, SearchHeading } from '_core/components/PrivilegedHeading'
import ProfileItem from '_core/components/ProfileItem'
import Widget from '_core/components/Widget'

import useDialog from '_core/hooks/useDialog'
import { useLookUpPeopleByIds } from '_core/hooks/useLookup'
import usePrivilegedPage from '_core/hooks/usePrivilegedPage'

import DynamicEntity from '_core/DynamicEntity'

import { del } from 'utils/httpUtils'
import { formatDateTime } from 'utils/Utils'

import Paths, { DataAdminSubPaths } from 'Paths'

const useStyles = makeStyles()((theme) => ({
  content: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    [theme.breakpoints.up('sidepanel')]: {
      rowGap: theme.spacing(1),
      columnGap: theme.spacing(2),
      gridTemplateColumns: 'minmax(0, 1fr) minmax(0, 1fr)'
    }
  },
  widget: {
    minHeight: 'calc(100vh - 255px)'
  }
}))

type ContactsProps = {
  loading: boolean
  items: PrivilegedContact[]
  paging: GridTypes['paging']
  setPageSize: GridTypes['setPageSize']
  total: number
  teamNumber: number
  handleReload: () => void
}

const Action = () => {
  const action: IMenuAction = {
    label: 'Add contacts',
    icon: ['far', 'user-plus'],
    link: `${DataAdminSubPaths.privilegedCompanies}/contacts/add`,
    sidepanel: true
  }

  return <Buttons action={action} />
}

//there are exception contacts (not colleagues) who are visible to every colleague even though those contacts work at a special company.
const PrivilegedContactsPage = () => {
  const {
    teamContextValue: { teamNumber }
  } = useContext(TeamContext)
  const { onLoading, onPageSizeChange, reload, handleReload, loading, rowsPerPage, keyword } = usePrivilegedPage()
  const memoUrl = useMemo(() => `/people/privwhitelistpeople?teamNumber=${teamNumber}`, [teamNumber])
  const { classes } = useStyles()

  const Component = (
    <DynamicEntity<{ extraProps: { addprops: Pick<ContactsProps, 'teamNumber' | 'handleReload'> } }>
      url={!reload ? memoUrl : null}
      component={ContactsList}
      onLoading={onLoading}
      addprops={{ teamNumber, handleReload }}
      pageSize={+rowsPerPage}
      updatePageSize={onPageSizeChange}
      empty="No contacts found"
      emptySubtitle={keyword ? `No results found for your search '${keyword}'` : ''}
      list
      infinite
      search
      keepMounted
      id="priv-companies-contacts"
    />
  )

  return (
    <GridPageFrame
      loading={loading}
      gridTitle="Contacts"
      searchPlaceholder="Search for contacts by email"
      filters={<></>}
      disabledSearch={loading}
      heading={<SearchHeading loading={loading} />}
      headingAdornmentEnd={Action}
      component={Component}
      widgetClassName={classes.widget}
    />
  )
}

const ContactsList = ({ teamNumber, handleReload, ...props }: ContactsProps) => {
  const [selected, setSelected] = useState<number[]>([])
  const { isDialogOpened: isRemoveDialogOpened, openDialog: openRemoveDialog, closeDialog: closeRemoveDialog, openSuccess, successMode } = useDialog()
  const { lookUpPeopleByIds, peopleByIds: fullData, loading: fullDataLoading } = useLookUpPeopleByIds()
  const { classes } = useStyles()

  useEffect(() => {
    if (props.items.length) {
      const ids = props.items.filter((item) => !item.personName).map((item) => item.personMd5)
      ids.length && lookUpPeopleByIds(ids)
    }
  }, [props.items])

  const showFullInfo = props.items.length && props.items.some((item) => item.personName && item.personEmail)
  const loading = props.loading || fullDataLoading

  const items = props.items.map((contact) => {
    const fullPersonData = fullData?.find(({ PersonMd5, MyUserKeyMd5 }) => PersonMd5 === contact.personMd5 || MyUserKeyMd5 === contact.personMd5)
    const email = contact.personEmail || (contact.idText?.includes('@') ? contact.idText : fullPersonData?.BestEmailAddrText)
    const name = fullPersonData?.PersonNameText || contact.personName || contact.idText || contact.personMd5
    const hasExtraInfo = Boolean(contact.viaWhomEmail || contact.lastModified)

    const commonProps = {
      id: contact.rowNumber,
      name,
      email,
      userKey: email,
      personMd5: contact.personMd5,
      byline: name === email ? '' : email,
      link: `${Paths._companies}/${contact.personMd5 || email}`,
      sidepanel: true,
      variant: 'expandable'
    }

    if (hasExtraInfo) {
      return {
        ...commonProps,
        editor: contact.viaWhomEmail,
        editorLink: `${Paths._people}/${contact.viaWhomEmail}`,
        date: contact.lastModified,
        byline3: (
          <Box className={classes.content}>
            <Content
              items={[
                {
                  field: 'date',
                  value: formatDateTime(contact.lastModified),
                  title: 'Date',
                  icon: ['far', 'calendar-alt']
                },
                { field: 'editor', value: contact.viaWhomEmail, title: 'Updated by', icon: ['fas', 'user-tie'] }
              ]}
              isExpandedView
            />
          </Box>
        )
      }
    }

    return commonProps
  })

  const handleSelect = (selectionModel: GridSelectionModel) => {
    setSelected(selectionModel as number[])
  }

  const handleRemove = async () => {
    const payload = selected
      .map((id) => {
        const company = items.find((item) => item.id === id)
        return company?.personMd5
      })
      .filter(Boolean)
    await del(`/people/privwhitelistpeople?teamNumber=${teamNumber}`, payload)
    openSuccess()
  }

  const handleSuccessClose = () => {
    closeRemoveDialog()
    handleReload()
  }

  const columns = useMemo(
    () =>
      [
        { column: nameColumn },
        { column: emailColumn },
        { column: editorColumn, condition: showFullInfo },
        { column: dateColumn, condition: showFullInfo }
      ]
        .filter(({ condition }) => typeof condition !== 'boolean' || condition)
        .map(({ column }) => column),
    [showFullInfo]
  )

  const removeContacts = useMemo(
    () => (
      <>
        <RemoveSelectedDialog.TriggerEl open={openRemoveDialog} disabled={!selected.length} />
        <RemoveSelectedDialog
          content="Selected contacts who work at restricted companies will be invisible."
          isOpened={isRemoveDialogOpened}
          close={closeRemoveDialog}
          successClose={handleSuccessClose}
          handleRemove={handleRemove}
          success={successMode}
        />
      </>
    ),
    [selected.length, isRemoveDialogOpened, closeRemoveDialog, openRemoveDialog, successMode, selected.length]
  )

  return (
    <>
      <Wide>
        <DataGrid
          rows={!props.loading && !fullDataLoading ? items : []}
          columns={columns}
          controls={[removeContacts]}
          loading={loading}
          setPageSize={props.setPageSize}
          paging={props.paging}
          total={props.total}
          onSelect={handleSelect}
          checkboxSelection
        />
      </Wide>
      <Narrow>
        <Widget scope="card">
          <Repeater
            direction="vertical"
            variant="card"
            component={ProfileItem}
            skeleton={{ size: 5, loading }}
            items={items}
          />
        </Widget>
      </Narrow>
    </>
  )
}

export default PrivilegedContactsPage
