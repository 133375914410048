import React, { ForwardedRef, forwardRef } from 'react'

import { Card as MuiCard, CardContent, CardActionArea, CardActions, CardHeader, CardMedia, CardProps as MuiCardProps } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles()((theme) => ({
  root: {
    '& .MuiCardContent-root': {
      padding: theme.spacing(2),
      '&:last-child': {
        paddingBottom: theme.spacing(2)
      }
    }
  },
  round: {
    borderRadius: theme.spacing(1)
  }
}))

type CardProps = Omit<MuiCardProps, 'classes'> & { round?: boolean; className?: string }

const CardInner = ({ round, children, className, ...other }: CardProps, ref: ForwardedRef<HTMLDivElement>) => {
  const { classes, cx } = useStyles()

  return (
    <MuiCard ref={ref} classes={{ root: cx(classes.root, className, { [classes.round]: round }) }} {...other}>
      {children}
    </MuiCard>
  )
}

const Card = forwardRef(CardInner) as (props: CardProps & { ref?: ForwardedRef<HTMLDivElement> }) => ReturnType<typeof CardInner>

export { CardContent, CardActionArea, CardActions, CardHeader, CardMedia }
export default Card
