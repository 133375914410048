import { request } from './fetchUtils'

export type UrlParams<T = string> = {
  [param: string]: T | undefined
}

export function get<T>(url: string, params?: UrlParams): Promise<T | undefined> {
  return request<T>(mergeUrlWithParams(url, params), {
    method: 'GET'
  })
}

export function post<T>(url: string, data?: any, params?: UrlParams): Promise<T | undefined> {
  return request<T>(mergeUrlWithParams(url, params), {
    method: 'POST',
    body: transformBody(data)
  })
}

export function postBinary(url: string, data?: any, params?: UrlParams): Promise<Blob | undefined> {
  return request<Blob>(
    mergeUrlWithParams(url, params),
    {
      method: 'POST',
      body: transformBody(data)
    },
    true
  )
}

export function postRaw<T>(url: string, data?: any, params?: UrlParams): Promise<T | undefined> {
  return request<T>(mergeUrlWithParams(url, params), {
    method: 'POST',
    body: data,
    headers: {
      ContentType: 'multipart/form-data'
    }
  })
}

export function getBinary(url: string, params?: UrlParams): Promise<Blob | undefined> {
  return request<Blob>(
    mergeUrlWithParams(url, params),
    {
      method: 'GET'
    },
    true
  )
}

export function patch<T>(url: string, data?: any, params?: UrlParams): Promise<T | undefined> {
  return request<T>(mergeUrlWithParams(url, params), {
    method: 'PATCH',
    body: transformBody(data)
  })
}

export function put<T>(url: string, data?: any, params?: UrlParams): Promise<T | undefined> {
  return request<T>(mergeUrlWithParams(url, params), {
    method: 'PUT',
    body: transformBody(data)
  })
}

export function del<T>(url: string, data?: any, params?: UrlParams): Promise<T | undefined> {
  return request<T>(mergeUrlWithParams(url, params), {
    method: 'DELETE',
    body: transformBody(data)
  })
}

function transformBody(data: any): string {
  return JSON.stringify(data)
}

export function mergeUrlWithParams(url: string, params?: UrlParams<string | string[]>): string {
  if (!params || !Object.keys(params).length) {
    return encodeURI(url)
  } else {
    const parameters: string[] = []
    for (const key in params) {
      const param = params[key]
      if (param && typeof param === 'string') {
        parameters.push(key + '=' + encodeURIComponent(param))
      }
      if (param && Array.isArray(param)) {
        param.forEach((p) => parameters.push(key + '=' + encodeURIComponent(p)))
      }
    }
    const fullUrl = parameters.length ? url + '?' + parameters.join('&') : url
    return fullUrl
  }
}
