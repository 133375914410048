import { useContext, useEffect, useState } from 'react'

import useAbortableFetch from '_core/hooks/useAbortableFetch'

import { mergeUrlWithParams } from 'utils/httpUtils'

import { LayoutContext } from 'Layout/LayoutContextProvider'

export const useCompanyMarketData = (name: string, website?: string, unsettedHeader?: boolean) => {
  const [marketData, setMarketData] = useState<{ [key: string]: any } | null>(null)
  const [error, setError] = useState('')
  const { setMobileHeader } = useContext(LayoutContext)
  const { fetchWithAbort } = useAbortableFetch<{ [key: string]: any }>()

  useEffect(() => {
    if (name && !unsettedHeader) {
      setMobileHeader(name)
    }
  }, [name, unsettedHeader])

  useEffect(() => {
    if (name) {
      return () => {
        setMarketData(null)
        setError('')
      }
    }
  }, [name])

  const getMarketData = async () => {
    try {
      const res = await fetchWithAbort({
        url: mergeUrlWithParams('/companies/enrich', {
          name,
          website,
          titlecase: 'true'
        })
      })
      if (res) setMarketData(res)
    } catch (e: any) {
      setMarketData({})
      setError(e?.status === 500 ? 'No data found' : e?.error)
      console.log('error during fetching company market data', e)
    }
  }

  return {
    marketData,
    setMarketData,
    getMarketData,
    error
  }
}
