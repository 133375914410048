import { createContext, ReactNode, useEffect, useState } from 'react'

import useAbortableFetch from '_core/hooks/useAbortableFetch'
import useActivitiesAccess, { ActivitiesVisibility } from '_core/hooks/useActivitiesAccess'
import useIntroductionsAccess, { IntroductionVisibility } from '_core/hooks/useIntroductionsAccess'

type FeaturesSettingsType = {
  loading: boolean
  activitiesStreamEnabled?: boolean
  introductionsEnabled?: boolean
  userIntroductionsAccess?: boolean
  userActivitiesAccess?: boolean
  setUserIntroductionsAccess: (visibility: IntroductionVisibility, isAdmin: boolean, userEmail: string, managers: { emailAddress: string }[]) => void
  setUserActivitiesAccess: (visibility: ActivitiesVisibility, isAdmin: boolean) => void
}

export const PrivateFeaturesContext = createContext({} as FeaturesSettingsType)

type ConfigType = { introductions: boolean; activityStream: boolean }

const PrivateFeaturesProvider = (props: { children?: ReactNode }) => {
  const [config, setConfig] = useState<ConfigType>()
  const { fetchWithAbort } = useAbortableFetch<ConfigType>()

  useEffect(() => {
    ;(async () => {
      try {
        const resp = await fetchWithAbort({ url: '/app/controlledfeatures' })
        setConfig(resp)
      } catch {
        setConfig({ introductions: false, activityStream: false })
      }
    })()
  }, [])

  const { userIntroductionsAccess, getUserIntroductionsAccess, setUserIntroductionsAccess } = useIntroductionsAccess()
  const { userActivitiesAccess, getUserActivitiesAccess, setUserActivitiesAccess } = useActivitiesAccess()
  const loading =
    !!config &&
    ((config.activityStream && typeof userActivitiesAccess === 'undefined') ||
      (config.introductions && typeof userIntroductionsAccess === 'undefined'))

  const settings = {
    loading,
    activitiesStreamEnabled: config?.activityStream,
    introductionsEnabled: config?.introductions,
    userIntroductionsAccess,
    setUserIntroductionsAccess,
    userActivitiesAccess,
    setUserActivitiesAccess
  }

  useEffect(() => {
    if (settings.introductionsEnabled) {
      getUserIntroductionsAccess()
    }
    if (settings.activitiesStreamEnabled) {
      getUserActivitiesAccess()
    }
  }, [settings.introductionsEnabled, settings.activitiesStreamEnabled])

  return <PrivateFeaturesContext.Provider value={settings}>{props.children}</PrivateFeaturesContext.Provider>
}

export default PrivateFeaturesProvider
