import { useContext, useEffect, useMemo, useRef, useState } from 'react'

import { useLocation } from 'react-router-dom'

import { TeamContext } from '_core/context/TeamContext'

import useEntityEndpoint from '_core/hooks/useEntityEndpoint'
import { interactionsFilterData } from '_core/hooks/useInteractionsPeriodEndpointParams'
import useSearchQuery from '_core/hooks/useSearchQuery'

import { updateQuerystring } from '_core/helpers/browser'

import { defaultSettings } from 'utils/fetchUtils'
import { mergeUrlWithParams } from 'utils/httpUtils'
import { formatDate, formatDateTime, getLocal } from 'utils/Utils'

import { apiHost } from 'config'

type RemoteActivityItem = {
  ContactMd5: string
  UserKeyMd5: string
  LastCalGlobalKey: string
  NextCalGlobalKey: string
  FirstInboundMsg: string
  FirstOutboundMsg: string
  FirstMeeting: string
  NextFutureMeeting: string
  LastInboundMsg: string
  LastOutboundMsg: string
  LastMeeting: string
  LastInteraction: string
  LastActivity: string
  NumInboundMsgs: number
  NumOutboundMsgs: number
  NumReciprocatedMsgs: number
  NumMeetings: number
  NumInteractions: number
  User?: {
    UserKeyMd5: string
    UserFullName: string
    UserEmail: string
    BestJobCompanyMd5?: string
  }
  Contact?: {
    PersonMd5: string
    UserKeyMd5?: string
    PersonNameText: string
    BestEmailAddrText?: string
    BestJobTitleText: string
    BestJobCorpLevelCompanyName: string
    BestJobMatchedCompanyName: string
    BestJobCompanyMd5: string
    BestJobMatchedUrlText: string
  }
  KnoweePersonMd5: string
  ScorePoints: number
}

export type ActivityItem = Required<RemoteActivityItem>

const format = 'MM-DD-YYYY'

const useActivitiesSlots = (listParams?: { key: string; list?: string[] }) => {
  const { teamContextValue } = useContext(TeamContext)
  const { search } = useLocation()

  const clearKey = updateQuerystring(search, 'page', '')
  const { queryParams } = useSearchQuery<ActivitiesPageParams, { modifyProps: [{ customList: string[] }] }>(['customList'])
  const { date, interaction: qInteraction } = queryParams
  const formattedDate = date ? getLocal(date, format) : getLocal()
  const [reqParams, setReqParams] = useState<{
    minDate: string
    maxDate: string
    period: Exclude<TouchpointDateType, 'First'>
    interaction: ActivitiesPageParams['interaction'] | null
  }>()

  const { minDate, maxDate, period, interaction } = reqParams || {}

  const { teamNumber } = teamContextValue

  const { name: interactionFilterName = 'LastActivity' } =
    interactionsFilterData.find(({ wh, type: intType }) => wh === period && intType === interaction) || {}

  // check how to improve useEntityEndpoint
  const take = 150

  const params = useMemo(
    () => ({
      teamNumber: `${teamNumber}`,
      returnAllStatFields: `${true}`,
      filterType: `${interactionFilterName}`,
      sortType: `${interactionFilterName}`,
      sortAscending: 'true',
      take: `${take}`,
      minDate,
      maxDate
    }),
    [teamNumber, interactionFilterName, take, minDate, maxDate]
  )

  const url =
    period && interaction !== null && (!listParams || listParams.list)
      ? mergeUrlWithParams(`/p2pstats`, !listParams ? params : { ...params, [listParams?.key]: listParams?.list })
      : ''

  const { result, loading, more, reload } = useEntityEndpoint<{
    data: RemoteActivityItem[]
    are_more: boolean
    total_item_count: number
  }>(url, null, take, false, true)

  const { data = [], are_more } = result || {}

  const lastIndex = data.length - 1
  const lastItemTime = data[lastIndex]?.[interactionFilterName]
  const prevLastIndex = useRef<number>(0)

  const initial = useMemo(
    () => ({
      slots: [],
      hasMore: are_more
    }),
    []
  )

  const [slots, setSlots] = useState<{ [x: string]: { [x: string]: ActivityItem[] }[] }[]>(initial.slots)
  const [hasMore, setHasMore] = useState(initial.hasMore)
  const isTodayOrPastDay = getLocal(date, format).isSameOrBefore(getLocal(), 'day')

  useEffect(() => {
    if (date) {
      setReqParams((prevState) => ({
        ...prevState,
        minDate: formattedDate.startOf('day').toISOString(),
        maxDate: formattedDate.endOf('day').toISOString(),
        period: isTodayOrPastDay ? 'Latest' : 'Next',
        interaction: isTodayOrPastDay ? qInteraction : !qInteraction || qInteraction === 'Meeting' ? 'Meeting' : null
      }))
    }
  }, [date, clearKey])

  useEffect(() => {
    setSlots(initial.slots)
    setHasMore(initial.hasMore)
    prevLastIndex.current = 0
    reload()
  }, [clearKey])

  const logActivityWithMissedData = ({ UserKeyMd5, ContactMd5 }: RemoteActivityItem) => {
    fetch(`${apiHost}/api/log`, {
      method: 'POST',
      body: JSON.stringify({
        actionType: 'userOrContactMissing',
        details: JSON.stringify({ UserKeyMd5, ContactMd5 })
      }),
      ...defaultSettings
    })
  }

  const getMissedParticipantsWithTheDifferentLatestActivity = (item: ActivityItem, items: RemoteActivityItem[]) => {
    const participants = [item.Contact.UserKeyMd5 || item.Contact.PersonMd5, item.UserKeyMd5]

    return items.filter((item) => {
      const { UserKeyMd5, Contact, User, ...itm } = item
      if (!Contact || !User) {
        logActivityWithMissedData(item)
        return false
      }

      return (
        itm[interactionFilterName] !== item[interactionFilterName] &&
        [itm.LastActivity, itm.LastInboundMsg, itm.LastOutboundMsg, itm.LastMeeting].includes(item[interactionFilterName]) &&
        participants.find((pMd5) => [Contact.UserKeyMd5 || Contact.PersonMd5, UserKeyMd5].includes(pMd5))
      )
    }) as ActivityItem[]
  }

  const getSlots = (items: RemoteActivityItem[]) => {
    const groups = items.reduce((acc, initialItem, i) => {
      if (!initialItem.Contact || !initialItem.User) {
        logActivityWithMissedData(initialItem)
        return acc
      }

      const item = initialItem as ActivityItem
      const isTheSamePerson = item.Contact.UserKeyMd5 === item.UserKeyMd5

      const interactionFilterData = interactionsFilterData.find(({ wh, type: intType }) => wh === period && intType === interaction)
      if (isTheSamePerson || !interactionFilterData) {
        return acc
      }

      const date = formatDate(item[interactionFilterData.name])
      const time = item[interactionFilterData.name]

      const foundMissed = getMissedParticipantsWithTheDifferentLatestActivity(item, items.slice(i))
      const newItem = [...(acc.get(date)?.get(time) || []), item]

      const data = (acc.get(date) || new Map()).set(time, foundMissed.length ? [...newItem, ...foundMissed] : newItem)

      return acc.set(date, data)
    }, new Map<string, Map<string, ActivityItem[]>>())

    return Array.from(groups).map(([date, timesMap]) => ({ [date]: Array.from(timesMap).map(([time, items]) => ({ [time]: items })) }))
  }

  const isTodayOpened = formattedDate.diff(getLocal().startOf('day'), 'days') === 0
  const shouldFetchTodayUpcoming = useMemo(() => isTodayOpened && period === 'Latest' && (interaction === 'Meeting' || !interaction), [loading])

  const next = () => {
    if (shouldFetchTodayUpcoming) {
      setReqParams((prevState) => (prevState ? { ...prevState, period: 'Next', interaction: 'Meeting' } : prevState))
      prevLastIndex.current = 0
    } else {
      setHasMore(false)
    }
  }

  useEffect(() => {
    if (lastIndex > -1 && !loading) {
      if (!are_more) {
        const lastFullGroup = data.slice(prevLastIndex.current)
        setSlots((prevState) => [...prevState, ...getSlots(lastFullGroup)])
        return next()
      } else {
        const croppedGroupStartIndex = data.findIndex((item) => formatDateTime(item[interactionFilterName]) === formatDateTime(lastItemTime))
        if (croppedGroupStartIndex === 0) {
          more()
        } else {
          const lastFullGroup = data.slice(prevLastIndex.current, croppedGroupStartIndex)
          setSlots((prevState) => [...prevState, ...getSlots(lastFullGroup)])
          prevLastIndex.current = lastIndex
        }
        setHasMore(true)
      }
    }
  }, [lastIndex, loading])

  useEffect(() => {
    if (result && lastIndex === -1 && !loading) {
      next()
    }
  }, [result, lastIndex, loading])

  /* This useMemo hook calculates the common loading based on two conditions:
      1. Whether there is a need to fetch upcoming activities.
      2. Whether the slots have already been updated. */

  const commonLoading = shouldFetchTodayUpcoming || loading
  // const commonLoading = useMemo(() => {
  //   return shouldFetchTodayUpcoming || loading
  // }, [shouldFetchTodayUpcoming, slots, hasMore])

  if (interaction === null) {
    return {
      slots: [],
      hasMore: false,
      loading: false,
      total: 0,
      dataLength: 0
    }
  }

  return {
    slots,
    hasMore: !!hasMore,
    loading: commonLoading,
    more,
    reload
  }
}

export default useActivitiesSlots
