import React, { ComponentProps, HTMLAttributes, ReactNode, useCallback, useEffect, useState } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconButton } from '@mui/material'
import { useHistory, useParams } from 'react-router-dom'
import { makeStyles } from 'tss-react/mui'

import { CompanyIntroducerListItem } from '_pages/companies/[id]/introducers'

import { Button } from '_shared/buttons'
import Dialog, { DialogTitle, DialogActions, DialogContent } from '_shared/Dialog'
import Combobox from '_shared/forms/Combobox'

import PickSuggestionsList from '_core/components/PickSuggestionsList'
import ProfileItem from '_core/components/ProfileItem'

import useAsyncCombobox from '_core/hooks/useAsyncCombobox'
import { useLookUpCompanyIntroducers, useLookUpUserCompanies } from '_core/hooks/useLookup'

const useStyles = makeStyles()((theme) => ({
  pickerButton: {
    background: '#E7F6FF',
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1)
    }
  }
}))

const TriggerEl = (props: { open: () => void }) => {
  const {
    classes: { pickerButton }
  } = useStyles()
  const { open } = props
  return (
    <IconButton onClick={open} className={pickerButton} color="primary">
      <FontAwesomeIcon icon={['far', 'search']} style={{ fontSize: 16 }} />
    </IconButton>
  )
}

const RelationshipPickerDialog = ({
  isOpened,
  title,
  children: content,
  close
}: {
  isOpened: boolean
  title: string
  children: ReactNode
  close: () => void
}) => {
  return (
    <Dialog open={isOpened} onClose={close} title={<DialogTitle title={title || ''} />}>
      <DialogContent>{content}</DialogContent>
      <DialogActions>
        <Button variant="text" color="secondary" onClick={close} disablePR>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export const PickAColleagueRelationshipDialog = ({
  isOpened,
  close
}: Pick<ComponentProps<typeof RelationshipPickerDialog>, 'isOpened' | 'close'>) => {
  const { to } = useParams<{ from: string; to: string }>()
  const history = useHistory()

  const { lookUpCompanyIntroducers: lookUpCompanyIntroducersOptions, forceAbort } = useLookUpCompanyIntroducers()
  const { lookUpCompanyIntroducers: lookUpCompanyIntroducersSuggestions } = useLookUpCompanyIntroducers()

  const loadOptions = useCallback(
    (searchTerm: string) => {
      return lookUpCompanyIntroducersOptions(to, searchTerm)
    },
    [to, lookUpCompanyIntroducersOptions]
  )

  const {
    inputValue,
    value,
    open,
    options,
    optionsLoading,
    handleClose,
    handleOpen,
    handleFocus,
    handleInputChange,
    handleValueChange,
    filterOptions
  } = useAsyncCombobox({
    loadOptions,
    forceAbort
  })

  const [suggestionsList, setSuggetionsList] = useState<CompanyIntroducerListItem[]>()

  const handlePick = (id: string) => {
    history.push(`/relationships/${id}/companies/${to}`)
  }

  useEffect(() => {
    const getSuggestionsList = async () => {
      const result = await lookUpCompanyIntroducersSuggestions(to, '', [], 4)
      if (result) {
        setSuggetionsList(result)
      }
    }
    if (isOpened) {
      setSuggetionsList(undefined)
      getSuggestionsList()
    }
  }, [isOpened, to, lookUpCompanyIntroducersSuggestions])

  useEffect(() => {
    if (value) {
      handlePick(value.UserKeyMd5)
    }
  }, [value])

  const getOptionLabel = (option: CompanyIntroducerListItem) => option.UserName

  const renderOption = (props: HTMLAttributes<HTMLLIElement>, introducer: CompanyIntroducerListItem) => {
    return (
      <li {...props}>
        <ProfileItem name={introducer.UserName} byline={introducer.UserBestJobTitleText} />
      </li>
    )
  }

  return (
    <RelationshipPickerDialog isOpened={isOpened} title="Pick a colleague" close={close}>
      <Combobox<CompanyIntroducerListItem>
        autoFocus
        open={open}
        loading={optionsLoading}
        options={options}
        inputValue={inputValue}
        placeholder="Search by name or email"
        value={value}
        onChange={handleValueChange}
        getOptionLabel={getOptionLabel}
        renderOption={renderOption}
        onInputChange={handleInputChange}
        onOpen={handleOpen}
        onClose={handleClose}
        onFocus={handleFocus}
        filterOptions={filterOptions}
      />

      <PickSuggestionsList
        loading={!suggestionsList}
        suggestions={suggestionsList?.map(({ UserKeyMd5, UserName, UserBestJobTitleText }) => ({
          name: UserName,
          byline: UserBestJobTitleText,
          onClick: () => handlePick(UserKeyMd5)
        }))}
      />
    </RelationshipPickerDialog>
  )
}

export const PickACompanyRelationshipDialog = ({ isOpened, close }: Pick<ComponentProps<typeof RelationshipPickerDialog>, 'isOpened' | 'close'>) => {
  const { from } = useParams<{ from: string; to: string }>()
  const history = useHistory()

  const { lookupUserCompanies: lookupUserCompaniesOptions, forceAbort } = useLookUpUserCompanies()

  const { lookupUserCompanies: lookupUserCompaniesSuggestions } = useLookUpUserCompanies()

  const [suggestionsList, setSuggetionsList] = useState<CompaniesListItem[]>()

  useEffect(() => {
    const getSuggestionsList = async () => {
      const result = await lookupUserCompaniesSuggestions(from, '', [], 4)
      if (result) {
        setSuggetionsList(result)
      }
    }
    if (isOpened) {
      setSuggetionsList(undefined)
      getSuggestionsList()
    }
  }, [from, lookupUserCompaniesSuggestions, isOpened])

  const loadOptions = useCallback(
    (searchTerm: string) => {
      return lookupUserCompaniesOptions(from, searchTerm)
    },
    [from, lookupUserCompaniesOptions]
  )

  const {
    inputValue,
    value,
    open,
    options,
    optionsLoading,
    handleClose,
    handleOpen,
    handleFocus,
    handleInputChange,
    handleValueChange,
    filterOptions
  } = useAsyncCombobox({
    loadOptions,
    forceAbort
  })

  const handlePick = (id: string) => {
    history.push(`/relationships/${from}/companies/${id}`)
  }

  useEffect(() => {
    if (value) {
      handlePick(value.CompanyMd5)
    }
  }, [value])

  const getOptionLabel = (option: CompaniesListItem) => option.CompanyNameText

  const renderOption = (props: HTMLAttributes<HTMLLIElement>, company: CompaniesListItem) => {
    return (
      <li {...props}>
        <ProfileItem name={company.CompanyNameText} byline={company.BestUrlText} logoUrl={company.BestUrlText} />
      </li>
    )
  }

  return (
    <RelationshipPickerDialog isOpened={isOpened} title="Pick a company" close={close}>
      <Combobox<CompaniesListItem>
        autoFocus
        open={open}
        loading={optionsLoading}
        options={options}
        inputValue={inputValue}
        placeholder="Search by name"
        value={value}
        onChange={handleValueChange}
        getOptionLabel={getOptionLabel}
        renderOption={renderOption}
        onInputChange={handleInputChange}
        onOpen={handleOpen}
        onClose={handleClose}
        onFocus={handleFocus}
        filterOptions={filterOptions}
      />

      <PickSuggestionsList
        loading={!suggestionsList}
        suggestions={suggestionsList?.map(({ CompanyMd5, CompanyNameText, BestUrlText }) => ({
          name: CompanyNameText,
          logoUrl: BestUrlText,
          byline: BestUrlText,
          onClick: () => handlePick(CompanyMd5)
        }))}
      />
    </RelationshipPickerDialog>
  )
}

PickAColleagueRelationshipDialog.TriggerEl = TriggerEl
PickACompanyRelationshipDialog.TriggerEl = TriggerEl
export default RelationshipPickerDialog
