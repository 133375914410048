import React, { forwardRef } from 'react'

import { Box } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles<{ minHeight?: number; sticky?: number }>()((theme, { minHeight, sticky }) => ({
  container: {
    boxSizing: 'border-box',
    [theme.breakpoints.up('md')]: {
      minHeight: minHeight || ''
    }
  },
  widget: {
    margin: 0,
    flex: '1 1 auto',
    ['@media (min-height:160px)']: {
      // eslint-disable-line no-useless-computed-key
      borderTop: '8px #ECEEF0 solid'
    },
    [theme.breakpoints.up('md')]: {
      borderTop: 0,
      margin: `0 0 ${theme.spacing(2)} 0`,
      backgroundColor: theme.palette.background.light,
      borderRadius: theme.spacing(0.5)
    }
  },
  default: {
    padding: theme.spacing(1),
    ['@media (min-height:160px)']: {
      // eslint-disable-line no-useless-computed-key
      padding: theme.spacing(2)
    },
    [theme.breakpoints.up('md')]: {
      border: '1px #ECEEF0 solid',
      position: 'relative',
      paddingBottom: theme.spacing(7)
    }
  },
  divider: {
    borderTop: '1px #ECEEF0 solid',
    padding: theme.spacing(1),
    borderRadius: 0,
    ['@media (min-height:160px)']: {
      // eslint-disable-line no-useless-computed-key
      padding: theme.spacing(2)
    }
  },
  stack: {
    ['@media (min-height:160px)']: {
      // eslint-disable-line no-useless-computed-key
      padding: theme.spacing(2)
    },
    [theme.breakpoints.up('md')]: {
      border: '1px #ECEEF0 solid',
      position: 'relative'
    }
  },
  outlined: {
    border: '1px #ECEEF0 solid',
    position: 'relative'
  },
  list: {
    paddingBottom: 0
  },
  card: {
    padding: `0 ${theme.spacing(2)}`
  },
  none: {
    borderTop: 0,
    padding: theme.spacing(1),
    ['@media (min-height:160px)']: {
      // eslint-disable-line no-useless-computed-key
      padding: theme.spacing(2),
      position: 'relative'
    }
  },
  sticky: {
    top: sticky,
    position: 'sticky',
    zIndex: 100,
    background: theme.palette.background.light
  }
}))

type ScopeProps = {
  scope?: 'list' | 'stack' | 'none' | 'outlined' | 'default' | 'divider' | 'card'
}

type WidgetProps = {
  children: React.ReactNode
  empty?: boolean
  mdOrder?: number
  mdFlex0?: boolean
  minHeight?: number
  className?: any
  style?: any
  sticky?: number
} & ScopeProps

const Widget = forwardRef(({ children, scope, empty, mdOrder, mdFlex0, minHeight, className, style = {}, sticky }: WidgetProps, ref) => {
  const { classes, cx } = useStyles({ minHeight, sticky })

  if (empty)
    return (
      <Box ref={ref} className={classes.container} order={{ md: mdOrder || 'inherit' }} style={{ flex: mdFlex0 ? 0 : '' }}>
        Not found
      </Box>
    )

  return (
    <Box
      ref={ref}
      className={cx(classes.container, classes.widget, classes[scope || 'default'], { [classes.sticky]: typeof sticky === 'number' }, className)}
      order={{ md: mdOrder || 'inherit' }}
      style={{ flex: mdFlex0 ? 0 : '', ...style }}
    >
      {children}
    </Box>
  )
})

export default Widget
