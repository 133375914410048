import React from 'react'

import MUISkeleton, { SkeletonProps as MUISkeletonProps } from '@mui/material/Skeleton'

export type SkeletonProps = {
  size?: number
  loading: boolean
}

const Skeleton = (
  props: {
    condition?: boolean
  } & Exclude<MUISkeletonProps, 'animation'>
) => {
  const { condition, ...skProps } = props

  return condition ? <MUISkeleton {...skProps} animation="wave" /> : <>{props.children}</>
}

export default Skeleton
