import { useState } from 'react'

import useAbortableFetch from '_core/hooks/useAbortableFetch'

export type ActivitiesVisibility = 'Off' | 'AdminsOnly' | 'AllUsers'
type ResponseType = [{ visibility: ActivitiesVisibility }, ProfileType, { emailAddress: string }[]]

const useActivitiesAccess = () => {
  const [userAccess, setUserAccess] = useState<boolean>()
  const { fetchWithAbort } = useAbortableFetch<ResponseType>()

  const setUserActivitiesAccess = (visibility: ActivitiesVisibility, isAdmin: boolean) => {
    if (visibility === 'Off') {
      setUserAccess(false)
      return
    }

    const access = visibility === 'AllUsers' || (visibility === 'AdminsOnly' && isAdmin)
    setUserAccess(access)
  }

  const getUserAccess = async () => {
    const settings = await fetchWithAbort([{ url: '/usersettings/activities' }, { url: '/me/profile' }])
    if (settings) {
      const [userSettings, profile] = settings
      setUserActivitiesAccess(userSettings.visibility, profile.IsAdmin)
    }
  }

  return {
    userActivitiesAccess: userAccess,
    getUserActivitiesAccess: getUserAccess,
    setUserActivitiesAccess
  }
}

export default useActivitiesAccess
