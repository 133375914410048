import React, { useEffect, useContext } from 'react'

import { Box } from '@mui/material'
import { Switch, Route } from 'react-router-dom'

import Page from '_shared/Page'

import { Narrow } from '_core/components/layout'
import Topbar from '_core/components/Topbar'
import UploadAgent from '_core/components/UploadAgent'
import UploadSuccess from '_core/components/UploadSuccess'

import useAdmin from '_core/hooks/useAdmin'

import { LayoutContext } from 'Layout/LayoutContextProvider'

import Paths from 'Paths'

const agent = 'deals'
const UploadDealsPage = () => {
  const { setMobileHeader } = useContext(LayoutContext)
  const admin = useAdmin()

  useEffect(() => {
    setMobileHeader('Upload deals')
  }, [setMobileHeader])

  return (
    <Page variant="core">
      {admin && (
        <Narrow>
          <Topbar nativeBack />
          <Box height="calc(100vh - 88px)">
            <Switch>
              <Route path={`${Paths._deals}/upload/success`}>
                <UploadSuccess agent={agent} />
              </Route>
              <Route>
                <UploadAgent agent={agent} />
              </Route>
            </Switch>
          </Box>
        </Narrow>
      )}
    </Page>
  )
}

export default UploadDealsPage
