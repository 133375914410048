import React from 'react'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box } from '@mui/material'

import Paths from 'Paths'
import Empty from '_core/components/Empty'
import { Button } from '_shared/buttons'
import { SidepanelNarrow } from '_core/components/layout'

const LogoutPage = () => (
  <SidepanelNarrow>
    <Box height="100vh">
      <Empty
        title="Please log in to begin using DotAlign Cloud."
        icon={<img
          src={`${process.env.REACT_APP_DOTALIGN_APP_LOGO || '/image/logo.png'}`}
          alt="logo"
          style={{ height: 30 }}
        />}
        action={
          <Box display="flex" alignItems="center" justifyContent="center" mt={3}>
            <Link to={Paths.login}>
              <Button startIcon={<FontAwesomeIcon icon={['fas', 'arrow-right-to-bracket']} style={{ fontSize: 14 }} />}>Login</Button>
            </Link>
          </Box>
        }
      />
    </Box>
  </SidepanelNarrow>
)

export default LogoutPage
