import { useContext, useMemo, useEffect, useRef, useState } from 'react'

import { Box } from '@mui/material'
import { useLocation } from 'react-router-dom'
import { makeStyles } from 'tss-react/mui'

import { TeamContext } from '_core/context/TeamContext'

import Empty from '_core/components/Empty'
import Filters, { Controller } from '_core/components/filters/Search'
import InfiniteScroll from '_core/components/InfiniteScroll'
import { useWide, Wide, Narrow, Columns, Column } from '_core/components/layout'
import Preview from '_core/components/Preview'
import RecentSearches from '_core/components/RecentSearches'
import SearchInput from '_core/components/SearchInput'
import Results from '_core/components/SearchResult'
import Tops from '_core/components/TopPeopleCompanies'
import Widget from '_core/components/Widget'

import useLatestSearchValue from '_core/hooks/useLatestSearchValue'
import useSearchEndpoints from '_core/hooks/useSearchEndpoints'
import useSearchQuery from '_core/hooks/useSearchQuery'
import useSidepanel from '_core/hooks/useSidepanel'

import DynamicEntity from '_core/DynamicEntity'
import UserSettings from '_core/UserSettings'

import { LayoutContext } from 'Layout/LayoutContextProvider'

const useStyles = makeStyles()((theme) => ({
  root: {
    background: theme.palette.background.light,
    maxWidth: 960,
    margin: '0px auto',
    overflow: 'hidden',
    '& .MuiGrid-container': {
      width: '100%',
      margin: 0,
      [theme.breakpoints.up('md')]: {
        height: 'calc(100vh - 173px)'
      }
    },
    '& .MuiGrid-item': {
      padding: '0!important',
      height: 'inherit'
    }
  },
  results: {
    display: 'flex',
    width: '100%',
    flex: 1,
    flexDirection: 'column',
    boxSizing: 'border-box',
    [theme.breakpoints.up('md')]: {
      overflow: 'auto',
      height: 'inherit'
    },
    '& .infinite-scroll-component': {
      overflow: 'initial !important'
    }
  },
  previewColumn: {
    flex: 1,
    borderLeft: '1px solid #f2f2f2'
  },
  searchWidget: {
    padding: theme.spacing(1.5),
    paddingRight: theme.spacing(0.5),
    flexWrap: 'wrap',
    display: 'flex',
    boxSizing: 'border-box',
    position: 'relative',
    zIndex: 12
  },
  input: {
    marginRight: theme.spacing(1)
  }
}))

const R = ({ inputValue, onLoading }: { inputValue?: string; onLoading: (val: boolean) => void }) => {
  const wide = useWide()
  const { queryParams } = useSearchQuery<SearchPageParams, { modifyProps: [{ entities: Entities[] }] }>(['entities'])
  const { entities = [] }: any = queryParams
  const { teamContextValue } = useContext(TeamContext)
  const { teamNumber } = teamContextValue
  const { openUrl, openedUrl } = useSidepanel('preview')

  const urls = useMemo(
    () =>
      entities.map((entity: Entities) => ({
        url: `/${entity}?teamNumber=${teamNumber}&SortBy=ScoreDesc&Take=10`,
        key: entity,
        merge: true
      })),
    [teamNumber, entities.length]
  )

  const { result, loading, more, reload } = useSearchEndpoints(urls, inputValue)
  const dataLength = result ? result.data[0]?.data?.length : 0

  useEffect(() => {
    onLoading(loading)
  }, [loading, onLoading])

  if (!loading && !dataLength) {
    return <Empty title="No results found" subTitle={`No results found for '${inputValue}'`} close={true} />
  }

  return (
    <InfiniteScroll
      loading={loading}
      dataLength={dataLength}
      next={more}
      refreshFunction={reload}
      hasMore={result ? result.are_more : false}
      {...(wide && { scrollableTarget: 'search_results' })}
    >
      <Results items={result ? result.data : undefined} loading={loading} openUrl={openUrl} {...(wide && { openedUrl })} />
    </InfiniteScroll>
  )
}

const SearchPage = () => {
  const { classes } = useStyles()
  const { search } = useLocation()
  const searchWrapperRef = useRef<any>()
  const wide = useWide()
  const [opened, setOpen] = useState<boolean>(wide)
  const { teamContextValue } = useContext(TeamContext)
  const { setMobileHeader } = useContext(LayoutContext)
  const { openUrl } = useSidepanel('preview')
  const [isReady, setIsReady] = useState<boolean>(false)
  const [contentLoading, setContentLoading] = useState<boolean>(false)
  const controllerRef = useRef<HTMLDivElement | null>(null)

  const { queryParams, updateQuery } = useSearchQuery<SearchPageParams, { modifyProps: [{ entities: Entities[] }] }>(['entities'])
  const { entities = [], keyword } = queryParams
  const { isTyping, setIsTyping, inputValue, inputValueRef } = useLatestSearchValue()

  const companiesActive = entities?.includes('companies')
  const peopleActive = entities?.includes('people')

  const { teamNumber } = teamContextValue

  const topsUrls = useMemo(
    () => [
      { url: `/companies?teamNumber=${teamNumber}&SortBy=ScoreDesc&Take=${companiesActive ? (peopleActive ? 5 : 10) : 0}`, key: 'companies' },
      { url: `/people?teamNumber=${teamNumber}&SortBy=ScoreDesc&Take=${peopleActive ? (companiesActive ? 5 : 10) : 0}`, key: 'people' }
    ],
    [teamNumber, companiesActive, peopleActive]
  )

  useEffect(() => {
    setMobileHeader('Search')
  }, [setMobileHeader])

  useEffect(() => {
    openUrl('/blank')
  }, [inputValue, entities.length])

  useEffect(() => {
    searchWrapperRef.current && searchWrapperRef.current.scrollTo(0, 0)
  }, [entities.length, inputValue])

  const setInitial = (resp: ISearchInit) => {
    setIsReady(true)
    if (resp.entities?.length && !search) {
      updateQuery({ entities: resp.entities.map((e) => e.toLowerCase()) })
    }
  }

  const toggleOpen = () => {
    setOpen((prev) => !prev)
  }

  const onResultsLoading = (loading: boolean) => {
    setContentLoading(loading)
  }

  const Result = (
    <div className={classes.results} id="search_results" ref={searchWrapperRef}>
      {((!inputValue && typeof inputValue === 'string') || (typeof inputValue !== 'string' && !keyword)) && (
        <>
          <div style={{ maxHeight: 220 }}>
            <DynamicEntity url={`/savedsearches`} list={true} component={RecentSearches} empty={''} id="recent-searches" />
          </div>
          <DynamicEntity urls={teamNumber ? topsUrls : null} component={Tops} empty="" id="top" />
        </>
      )}

      {!!(inputValue || (typeof inputValue !== 'string' && keyword)) && <R inputValue={inputValue} onLoading={onResultsLoading} />}
    </div>
  )

  return (
    <UserSettings endpoint="/searchfilter" setInitial={setInitial}>
      <Wide>
        <Box className={classes.root}>
          <SearchInput
            ref={inputValueRef}
            placeholder="Search for people and companies"
            isTyping={isTyping}
            setIsTyping={setIsTyping}
            autoFocus
            disabled={!isReady}
            variant="underlined"
          />
          <Columns>
            <Column md={3}>
              <Filters contentLoading={contentLoading} opened={opened} disabled={!isReady} />
            </Column>
            <Column md={5}>{Result}</Column>
            <Column md={4} className={classes.previewColumn}>
              <Preview />
            </Column>
          </Columns>
        </Box>
      </Wide>
      <Narrow>
        <Box height={1}>
          <Widget scope="list" className={classes.searchWidget}>
            <SearchInput
              ref={inputValueRef}
              placeholder="Search for people and companies"
              isTyping={isTyping}
              className={classes.input}
              setIsTyping={setIsTyping}
              variant="collapsed"
              opened
              autoFocus
            />
            <Controller disabled={!isReady} opened={opened} toggleOpen={toggleOpen} ref={controllerRef} />
          </Widget>
          <Filters contentLoading={contentLoading} opened={opened} disabled={!isReady} anchorEl={controllerRef.current} />
          {Result}
        </Box>
      </Narrow>
    </UserSettings>
  )
}

export default SearchPage
