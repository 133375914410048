import { useEffect, useState } from 'react'

import { Route, Switch } from 'react-router-dom'

import Page from '_shared/Page'

import Event, { SubjectTitle } from '_core/components/Event'
import Repeater from '_core/components/lists/Repeater'
import ProfileItem from '_core/components/ProfileItem'
import Topbar from '_core/components/Topbar'
import Widget from '_core/components/Widget'

import { useLookUpCompaniesByIds, useLookUpPeopleByIds } from '_core/hooks/useLookup'
import useSearchQuery from '_core/hooks/useSearchQuery'

import Paths from 'Paths'

const baseURL = `${Paths._activities}/event`

const ActivityEventPage = () => {
  const { queryParams } = useSearchQuery<ActivityEventPageParams, { modifyProps: [{ participants: string[]; companies: string[] }] }>([
    'participants',
    'companies'
  ])
  const { lookUpCompaniesByIds } = useLookUpCompaniesByIds()
  const { lookUpPeopleByIds } = useLookUpPeopleByIds()
  const [participantsList, setParticipantsList] = useState<PersonListItem[]>()
  const [companiesList, setCompaniesList] = useState<CompaniesListItem[]>()

  const { participants: emailsList = [], companies: urlsList = [], startTime = '' } = queryParams
  const loading = emailsList && !participantsList

  useEffect(() => {
    if (emailsList.length > 0 && !participantsList) {
      ;(async () => {
        const result = await lookUpPeopleByIds(emailsList)
        if (result?.data) {
          setParticipantsList(result.data)
        }
      })()
      ;(async () => {
        const result = await lookUpCompaniesByIds(urlsList)
        if (result?.data) {
          setCompaniesList(result.data)
        }
      })()
    }
  }, [])

  const subject = 'Meeting'

  const participants = participantsList?.map((item) => ({
    name: item.PersonNameText || item.UserInput || '',
    userKey: item.UserInput || item.BestEmailAddrText,
    byline: item.BestEmailAddrText || item.UserInput,
    link: `${Paths._people}/${item.PersonMd5 || item.UserInput}`
  }))

  const companies = companiesList?.map((item) => ({
    name: item.CompanyNameText || item.UserInput || '',
    logoUrl: item.UserInput || item.BestUrlText,
    byline: item.BestUrlText || item.UserInput,
    link: `${Paths._companies}/${item.CompanyMd5 || item.UserInput}`
  }))

  if (loading) {
    return <Event loading={loading} details={<SubjectTitle title={subject} loading={loading} />} />
  }

  return (
    <Page>
      <Switch>
        <Route path={`${baseURL}/participants`}>
          <Topbar nativeBack title={subject} sub={`Participants · ${participants?.length || 0}`} />
          <Widget scope="list">
            <Repeater direction="vertical" variant="list" component={ProfileItem} skeleton={{ size: 10, loading }} items={participants || []} />
          </Widget>
        </Route>
        <Route path={`${baseURL}/companies`}>
          <Topbar nativeBack title={subject} sub={`Companies · ${companies?.length || 0}`} />
          <Widget scope="list">
            <Repeater direction="vertical" variant="list" component={ProfileItem} skeleton={{ size: 10, loading }} items={companies || []} />
          </Widget>
        </Route>

        <Route>
          <Event
            loading={false}
            subject={subject}
            startTimeUTC={startTime}
            companies={companies || []}
            participantsWithPeople={participants || []}
            details={<SubjectTitle title={subject} />}
          />
        </Route>
      </Switch>
    </Page>
  )
}

export default ActivityEventPage
