import React, { useMemo } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box } from '@mui/material'
import moment from 'moment'
import { Route, Switch, useParams } from 'react-router-dom'

import { TeamContext } from '_core/context/TeamContext'

import Avatar from '_shared/Avatar'
import { IconButton } from '_shared/buttons'
import Skeleton from '_shared/Skeleton'
import Tooltip from '_shared/Tooltip'
import Typography from '_shared/Typography'

import Dashboard from '_core/components/Dashboard'
import { PickAColleagueRelationshipDialog, PickACompanyRelationshipDialog } from '_core/components/dialogs/RelationshipPicker'
import Empty from '_core/components/Empty'
import Heading from '_core/components/Heading'
import { Column, Columns, Narrow, useWide, Wide } from '_core/components/layout'
import Repeater from '_core/components/lists/Repeater'
import ProfileSummary from '_core/components/ProfileSummary'
import RelationshipDetail from '_core/components/RelationshipDetail'
import RelationshipScore from '_core/components/RelationshipScore'
import TitleDescription from '_core/components/TitleDescription'
import TouchpointRelations, { StatsType } from '_core/components/TouchpointRelations'
import Widget from '_core/components/Widget'

import useDialog from '_core/hooks/useDialog'

import DynamicEntity from '_core/DynamicEntity'

import { LayoutContext } from 'Layout/LayoutContextProvider'

import Paths from 'Paths'

import RelationshipContacts from './contacts'

const url = Paths._relationships

const dashboardLinks = (props: any) => [
  {
    id: 'dashboard',
    name: 'Dashboard',
    link: `${url}/${props.frm}/companies/${props.to}`,
    condition: true,
    icon: ['far', 'th']
  },
  {
    id: 'contacts',
    name: 'Contacts',
    link: `${url}/${props.frm}/companies/${props.to}/contacts`,
    condition: true,
    icon: ['far', 'user']
  }
]

const Header = ({ props, detailed, variant }: any) => (
  <Widget>
    <RelationshipDetail
      {...{
        name1: props?.UserFullName,
        link1: `${Paths._people}/${props?.UserKeyMd5}`,
        title1: props?.TitleText,
        company1: props?.ContactBestJobMatchedCompanyName || props?.ContactBestJobCorpLevelCompanyName,
        companyId1: props?.ContactBestJobCompanyMd5,
        name2: props?.CompanyNameText,
        link2: `${Paths._companies}/${props?.CompanyMd5}`,
        title2: props?.IntroducerBestJobTitleText,
        logo2: props?.BestUrlText,
        company2: props?.IntroducerBestJobMatchedCompanyName || props?.IntroducerBestJobCorpLevelCompanyName,
        companyId2: props?.IntroducerBestJobCompanyMd5,
        score: props?.Score,
        detailed,
        variant
      }}
    />
  </Widget>
)

const RelationPicker = (props: any) => {
  const { dialogContentProps, openDialog, closeDialog } = useDialog<{ type: 'user' | 'company' }>()
  const { type } = dialogContentProps || {}

  const pickAColleageOpen = () => {
    openDialog({ type: 'user' })
  }

  const pickACompanyOpen = () => {
    openDialog({ type: 'company' })
  }

  return (
    <>
      <Widget scope="none">
        <Box display="flex" justifyContent="space-between">
          <ProfileSummary
            title={props.UserFullName}
            loading={props.loading}
            introducers={!props.MyUserKeyMd5 && props.Introducers}
            introducersCopy={`${props.Employees?.total_item_count} contact${props.Employees?.total_item_count !== 1 ? 's' : ''} at `}
            actions={[
              <IconButton
                key="linkedin"
                icon={['fab', 'linkedin']}
                color="primary"
                hint="Show linkedin profile"
                disablePX
                component="a"
                href={`https://www.linkedin.com/search/results/people/?keywords=${props.UserFullName}`}
                loading={!props.UserFullName}
                rel="noopener noreferrer"
                target="_blank"
                size="small"
              />
            ]}
          />
          <Box display="flex" alignItems="center" p={2}>
            <PickAColleagueRelationshipDialog.TriggerEl open={pickAColleageOpen} />
          </Box>
          <RelationshipScore score={props.Score} />
          <Box display="flex" alignItems="center" p={2}>
            <PickACompanyRelationshipDialog.TriggerEl open={pickACompanyOpen} />
          </Box>
          <ProfileSummary
            flip
            url={props.BestUrlText}
            title={props.CompanyNameText}
            loading={props.loading}
            introducers={!props.MyUserKeyMd5 && props.Introducers}
            introducersCopy={`${props.Employees?.total_item_count} contact${props.Employees?.total_item_count !== 1 ? 's' : ''} at `}
            actions={[
              (props.loading || (!props.loading && props.BestUrlText)) && (
                <Skeleton condition={!props.BestUrlText} width="100px">
                  <Tooltip title={`Send an email to ${props.BestUrlText}`}>
                    <a href={`//${props.BestUrlText}`} target="_blank" rel="noopener noreferrer" style={{ color: 'rgba(27, 149, 187, 0.87)' }}>
                      <FontAwesomeIcon icon={['far', 'external-link']} style={{ fontSize: 15, paddingRight: 4 }} color="rgba(27, 149, 187, 0.87)" />
                      {props.BestUrlText}
                    </a>
                  </Tooltip>
                </Skeleton>
              ),
              <IconButton
                key="linkedin"
                icon={['fab', 'linkedin']}
                color="primary"
                hint="Show linkedin profile"
                disablePX
                component="a"
                href={`https://www.linkedin.com/search/results/people/?keywords=${props.CompanyNameText}`}
                loading={!props.CompanyNameText}
                rel="noopener noreferrer"
                target="_blank"
                size="small"
              />
            ]}
          />
        </Box>
      </Widget>
      <PickAColleagueRelationshipDialog isOpened={type === 'user'} close={closeDialog} />
      <PickACompanyRelationshipDialog isOpened={type === 'company'} close={closeDialog} />
    </>
  )
}

const Connected = ({ loading, stats }: any) => (
  <TitleDescription
    title="Connected"
    description={
      loading || (!loading && stats?.FirstInboundMsgContactName) ? (
        <span style={{ display: 'flex', alignItems: 'center' }}>
          <FontAwesomeIcon icon={['far', 'user']} size="sm" color="#1B95BB" style={{ marginRight: '8px' }} />
          <Skeleton condition={loading} width={120}>
            {!loading && stats?.FirstInboundMsgContactName ? stats?.FirstInboundMsgContactName : <></>}
          </Skeleton>
        </span>
      ) : (
        <span>No connection found</span>
      )
    }
    blurb={
      <Skeleton condition={loading} width={140}>
        {!loading && stats?.FirstInboundMsg ? moment(stats.FirstInboundMsg).format('DD MMMM YYYY') : <></>}
      </Skeleton>
    }
  />
)

const Info = (props: any) => (
  <TitleDescription
    title="Relationships"
    description={
      <span style={{ display: 'flex', alignItems: 'center' }}>
        <FontAwesomeIcon icon={['fas', 'chart-network']} size="sm" color="#1B95BB" style={{ marginRight: '8px' }} />
        <Skeleton condition={!props.totalRelationships} width={160}>
          {props.totalRelationships ? <>{props.totalRelationships} relationships</> : <></>}
        </Skeleton>
      </span>
    }
    blurb={
      <Skeleton condition={props.loading} width={140}>
        {!props.loading ? `Including ${props.total} former affiliation${props.total !== 1 ? 's' : ''}` : ``}
      </Skeleton>
    }
  />
)

const TopContacts = ({ total, loading, items }: any) => {
  const { to, from } = useParams<any>()
  const wide = useWide()

  return (
    <Widget>
      <Heading title="Contacts" count={total} icon={['far', 'user']} link={`${Paths._relationships}/${from}/companies/${to}/contacts`} />
      <Repeater
        direction="horizontal"
        component={Avatar}
        skeleton={{ size: wide ? 5 : 4, loading }}
        items={items?.slice(0, wide ? 5 : 4).map((contact: any) => ({
          name: contact.PersonNameText,
          score: contact.UserKnowsPersonScore,
          link: `${Paths._people}/${contact.ContactUserKeyMd5 || contact.PersonMd5}`,
          userKey: contact.ContactBestEmail,
          sidepanel: true
        }))}
      />
    </Widget>
  )
}

const LatestTouchpoints = ({ loading, stats }: { loading: boolean; stats: StatsType<{ CompanyMd5: string }> }) => (
  <Widget scope="stack">
    <Heading title="Latest interactions" icon={['far', 'handshake']} />
    <Repeater component={TitleDescription} skeleton={{ size: 3, loading }} items={!loading ? TouchpointRelations(stats, 'Last') : []} />
  </Widget>
)

const RelationshipDetails = (props: any) => {
  const { main, relationships, former } = props || {}

  if (!props.loading && (!main?.data.length || !relationships?.data.length)) return <Empty title="No relationship details found" />

  const { UserKeyMd5, ...restStatsData } = main?.data[0].Stats || {}

  const userKeyMd5Keys: (keyof StatsType<{ CompanyMd5: string }>)[] = [
    'LastInboundMsgUserKeyMd5',
    'LastActivityUserKeyMd5',
    'LastOutboundMsgUserKeyMd5',
    'LastMeetingUserKeyMd5'
  ]

  const stats = {
    ...restStatsData,
    ...userKeyMd5Keys.reduce((acc, key) => ({ ...acc, [key]: UserKeyMd5 }), {})
  }

  return (
    <>
      <Wide>
        <Columns>
          <Column>
            <Widget>
              <Heading icon={['far', 'bolt']} title="Quick facts" />
              <Box py={1} mt={1}>
                <Connected loading={props.loading} stats={stats} />
              </Box>
              <Box py={1}>
                <Info totalRelationships={relationships?.total_item_count} total={former?.total_item_count} loading={props.loading} />
              </Box>
            </Widget>
          </Column>
          <Column>
            <TopContacts items={relationships?.data} total={relationships?.total_item_count} loading={props.loading} />
          </Column>
          <Column>
            <LatestTouchpoints loading={props.loading} stats={stats} />
          </Column>
        </Columns>
      </Wide>
      <Narrow>
        <Header props={main?.data?.[0]} detailed="simple" variant="pickable" />
        <Widget>
          <Heading icon={['far', 'bolt']} title="Quick facts" />
          <Box py={1} mt={1}>
            <Connected loading={props.loading} stats={main?.data[0].Stats} />
          </Box>
          <Box py={1}>
            <Info totalRelationships={relationships?.total_item_count} total={former?.total_item_count} loading={props.loading} />
          </Box>
        </Widget>
        <TopContacts items={relationships?.data} total={relationships?.total_item_count} loading={props.loading} />
        <LatestTouchpoints loading={props.loading} stats={stats} />
      </Narrow>
    </>
  )
}

const RelationshipUserCompany = (props: any) => {
  const { from, to } = useParams<any>()
  const { setMobileHeader } = React.useContext(LayoutContext)
  const { teamContextValue } = React.useContext(TeamContext)
  const wide = useWide()

  React.useEffect(() => {
    setMobileHeader('Relationship Details')
  }, [setMobileHeader])

  const memoUrls = useMemo(() => {
    return [
      { key: 'main', url: `/users/${from}/companies/${to}?TeamNumber=${teamContextValue.teamNumber}&Take=${wide ? 4 : 3}&IncludeStats=True` },
      {
        key: 'relationships',
        url: `/users/${from}/companies/${to}/people?TeamNumber=${teamContextValue.teamNumber}&Take=${
          wide ? 4 : 3
        }&SortScoreType=InfluenceScore&SortBy=ScoreDesc`
      },
      { key: 'former', url: `/users/${from}/companies/${to}/people?TeamNumber=${teamContextValue.teamNumber}&Take=1&EmployeeType=Former` }
    ]
  }, [from, to, wide, teamContextValue.teamNumber])

  return (
    <>
      <Wide>
        <DynamicEntity
          url={`/users/${from}/companies/${to}?TeamNumber=${teamContextValue.teamNumber}&Take=3&IncludeStats=True`}
          component={RelationPicker}
          nativeBack={true}
          subHeader={(resp: any) => {
            const { UserFullName, CompanyNameText } = resp.data[0] || {}
            const title = UserFullName && CompanyNameText ? `${UserFullName} and ${CompanyNameText}` : ''
            return (
              <Typography title={title} variant="body1" style={{ maxWidth: '100%' }} noWrap ellipsis>
                {title}
              </Typography>
            )
          }}
          id="rel_details"
        />
      </Wide>
      <Switch>
        <Dashboard {...props} {...{ to, frm: from }} links={dashboardLinks}>
          <Route exact path={`${url}/:from/:via/:to/contacts`} component={RelationshipContacts} />

          <Route exact path={`${url}/:from/:via/:to`}>
            <DynamicEntity
              urls={memoUrls}
              component={RelationshipDetails}
              nativeBack={true}
              subHeader={(resp: any) => {
                const { UserFullName, CompanyNameText } = resp.data[0]?.main.data[0] || {}
                const title = UserFullName && CompanyNameText ? `${UserFullName} and ${CompanyNameText}` : ''
                return (
                  <Typography title={title} variant="body1" style={{ maxWidth: '100%' }} noWrap ellipsis>
                    {title}
                  </Typography>
                )
              }}
              id="rel_details_narrow"
            />
          </Route>
        </Dashboard>
      </Switch>
    </>
  )
}

export default RelationshipUserCompany
