import { useEffect, useState } from 'react'

import useAbortableFetch from '_core/hooks/useAbortableFetch'

export type UserTeamRelations = {
  teamNumber: number
  teamName: string
  isActive: boolean
  email: string
  name: string
  userKey: string
  userKeyPlainText: string
} & SharingData

const useUserMembership = (admin?: boolean, load?: boolean, teamId?: string) => {
  const [isContributor, setIsContributor] = useState(false)
  const [userMembership, setUserMembership] = useState<UserTeamRelations>()
  const [fetching, setFetching] = useState(false)
  const [userRelations, setUserRelations] = useState<UserTeamRelations[]>()
  const { fetchWithAbort: fetchTeams } = useAbortableFetch()
  const { fetchWithAbort: fetchTeamRelations } = useAbortableFetch<UserTeamRelations[]>()

  const checkIfUserIsContributor = async (teamId: string, userKey: string) => {
    const role = await fetchTeams({ url: `/teams/${teamId}/members/${userKey}/role` })
    return role === 'Member' || role === 'Contributor'
  }

  useEffect(() => {
    if (!load && typeof admin === 'boolean') {
      ;(async function () {
        try {
          setFetching(true)
          const relations = await fetchTeamRelations({ url: `/memberships` })
          if (relations) {
            setUserRelations(relations)
            if (teamId) {
              const relation = relations.find((relation) => relation.teamNumber.toString() === teamId)
              const userKey = relation?.userKeyPlainText
              const isContributor = userKey ? await checkIfUserIsContributor(teamId, userKey) : false
              setIsContributor(isContributor)
              setUserMembership(relation)
            }
          }
        } catch (e) {
          console.error(e)
        } finally {
          setFetching(false)
        }
      })()
    }
  }, [admin, teamId, load])

  return {
    userRelations,
    isContributor,
    userMembership,
    setUserMembership,
    fetching
  }
}

export default useUserMembership
