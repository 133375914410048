import React, { useState } from 'react'

import { Box, RadioGroup } from '@mui/material'
import { useSnackbar } from 'notistack'
import { Controller, useForm } from 'react-hook-form'
import { makeStyles } from 'tss-react/mui'

import Checkbox from '_shared/forms/Checkbox'
import Radio from '_shared/forms/Radio'

import Heading from '_core/components/Heading'
import Settings from '_core/components/settings'

import { ActivitiesVisibility } from '_core/hooks/useActivitiesAccess'
import usePrivateFeatures from '_core/hooks/usePrivateFeatures'

import { put } from 'utils/httpUtils'

const useStyles = makeStyles()((theme) => ({
  subtitle: {
    paddingBottom: 0
  },
  root: {
    display: 'flex',
    alignItems: 'center',
    marginTop: `-${theme.spacing(1)}`
  }
}))

const ActivitiesSettings = ({
  loading,
  settings,
  profile
}: {
  loading: boolean
  settings: { visibility: ActivitiesVisibility }
  profile: ProfileType
}) => {
  const { setUserActivitiesAccess } = usePrivateFeatures()
  const [isSaving, setSaving] = useState(false)
  const { enqueueSnackbar } = useSnackbar()
  const {
    watch,
    control,
    reset,
    getValues,
    formState: { isDirty: isVisibilityChanged }
  } = useForm<{ level: ActivitiesVisibility; enabled: boolean }>({
    defaultValues: loading
      ? { level: 'AdminsOnly', enabled: false }
      : { level: settings.visibility === 'Off' ? 'AdminsOnly' : settings.visibility, enabled: settings.visibility !== 'Off' }
  })
  const { classes } = useStyles()

  const enabled = watch('enabled')

  const save = async () => {
    setSaving(true)
    const { level } = getValues()
    const visibility = enabled ? level : 'Off'

    if (isVisibilityChanged) {
      await put('/adminsettings/activities', { visibility })
      reset({}, { keepValues: true })
    }
    setUserActivitiesAccess(visibility, profile.IsAdmin)
    enqueueSnackbar('Activities settings have been updated')
    setSaving(false)
  }

  return (
    <Settings isSaving={isSaving} save={save} initialLoading={loading} saveDisabled={loading}>
      <Heading title="Visibility settings" className={classes.subtitle} />
      <Controller
        name="enabled"
        control={control}
        render={({ field: { value, onChange } }) => (
          <Checkbox checked={!!value} onChange={onChange} disabled={isSaving} classes={{ root: classes.root }} label="Turn on activities" />
        )}
      />
      <Box ml={4} mb={2} display="flex" flexDirection="column">
        <Controller
          name="level"
          control={control}
          render={({ field: { value, onChange } }) => (
            <RadioGroup onChange={onChange} value={value}>
              <Radio value="AdminsOnly" disabled={isSaving || !enabled} label="For admins only" />
              <Radio value="AllUsers" disabled={isSaving || !enabled} label="For all users" />
            </RadioGroup>
          )}
        />
      </Box>
    </Settings>
  )
}

export default ActivitiesSettings
