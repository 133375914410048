import { useState, useEffect } from 'react'

import { isSidepanel } from '_pages/sidebar'

import { TaggableType } from '_core/hooks/useLookup'

type ResultType = {
  payloads: PayloadsType | undefined
  updateParent: (payloads: PayloadsType) => void
  updateSidepanel: (payloads: PayloadsType) => void
}

type ReloadListValues = 'associations' | 'data-sources' | 'data-uploads' | 'introductions' | 'privileged-companies'
type DownloadingValues = 'Downloading template...'
export type TagsValue = { tags: UngroupedTag[]; identifier: string }[]
export type SyncTagsValue = {
  taggableType: keyof typeof TaggableType
  categoriesRenames: {
    oldCategoryName: string
    newCategoryName: string
  }[]
  tagsRenames: {
    oldCategoryName: string
    oldTagName: string
    newTagName: string
  }[]
  deprecations: {
    categoryName: string
    tagName: string
  }[]
}
type RelationPrivacyType = { isPrivateRelations: boolean; userKey: string }
type IntroductionValues =
  | { planUid: string; contactId?: string; payload: { fieldName: string; value: any }[] }
  | { planUid: string; contactId?: string; fieldName: string; value: any }

type PayloadsType =
  | { action: 'RELOAD_LIST'; value: ReloadListValues | ReloadListValues[] }
  | { action: 'OPEN_POPUP'; value: { data: { [p: string]: any }; kind: EventPopupKind } }
  | { action: 'EDIT_TEAM'; value: Team }
  | { action: 'UPDATE_RELATIONSHIP_PRIVACY'; value: RelationPrivacyType }
  | { action: 'UPDATE_TAGS'; value: TagsValue }
  | { action: 'SYNC_TAGS'; value: SyncTagsValue }
  | { action: 'UPDATE_STATUS'; value: EventStatusKeys }
  | { action: 'START_DOWNLOADING'; value: DownloadingValues }
  | { action: 'UPDATE_INTRODUCTION_DETAILS'; value: IntroductionValues }

const useSidepanelPayloads = (sidepanel: SidepanelType = true): ResultType => {
  const [payloads, setPayloads] = useState<PayloadsType>()
  const name = typeof sidepanel === 'boolean' ? 'sidepanel' : sidepanel
  const iframe = window.document.querySelector(`iframe[name=${name}]`) as any

  useEffect(() => {
    window.addEventListener('message', receiveMessage, false)

    function receiveMessage(event: any) {
      if (event.data) {
        if (typeof event.data === 'string' && event.data.indexOf(`${name}_payloads:`) > -1) {
          const payloads = event.data.replace(`${name}_payloads:`, '')
          setPayloads(JSON.parse(payloads))
        }
      }
    }

    return () => window.removeEventListener('message', receiveMessage, false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const sendPayloads = (payloads: PayloadsType, window: Window) => {
    window.postMessage(`${name}_payloads:${JSON.stringify(payloads)}`, '*')
  }

  const updateParent = (payloads: PayloadsType) => {
    if (isSidepanel()) {
      sendPayloads(payloads, window.parent)
    }
  }

  const updateSidepanel = (payloads: PayloadsType) => {
    sendPayloads(payloads, iframe?.contentWindow)
  }

  return { payloads, updateParent, updateSidepanel }
}

export default useSidepanelPayloads
