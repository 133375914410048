import React, { ComponentProps, SyntheticEvent, useEffect, useMemo, useState } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box } from '@mui/material'
import moment from 'moment'
import { useLocation, useParams } from 'react-router-dom'
import { makeStyles } from 'tss-react/mui'

import { AlertWidget } from '_pages/people/[id]/touchpoints'

import Avatar from '_shared/Avatar'
import { Button, IconButton } from '_shared/buttons'
import Skeleton from '_shared/Skeleton'
import Tooltip from '_shared/Tooltip'
import Typography from '_shared/Typography'

import Affiliation, { AffiliationPopover } from '_core/components/Affiliation'
import AffiliationInformDialog from '_core/components/dialogs/AffiliationInform'
import { emailReason } from '_core/components/dialogs/InformAboutIdentity'
import Empty from '_core/components/Empty'
import ExternalTag, { ExtraTagsPopover } from '_core/components/ExternalTag'
import Heading from '_core/components/Heading'
import IdentifierLabel from '_core/components/IdentifierLabel'
import { Interaction, getTime } from '_core/components/Interactions'
import InteractionsPrivacyMsg from '_core/components/InteractionsPrivacy'
import { Column, Columns, Narrow, WideStrict, Middle, useWide } from '_core/components/layout'
import Repeater from '_core/components/lists/Repeater'
import Timeline, { TimelineList } from '_core/components/lists/Timeline'
import NameLink from '_core/components/NameLink'
import PhoneNumber from '_core/components/PhoneNumber'
import SidepanelLink from '_core/components/SidepanelLink'
import TagsGroup from '_core/components/TagsGroup'
import TitleDescription from '_core/components/TitleDescription'
import TouchpointSummary from '_core/components/TouchpointSummary'
import Widget from '_core/components/Widget'

import useAbortableFetch from '_core/hooks/useAbortableFetch'
import useDialog from '_core/hooks/useDialog'

import { stringifyUrl } from '_core/helpers/browser'
import { sum } from '_core/helpers/numbers'

import { mergeUrlWithParams, post } from 'utils/httpUtils'

import { widgetSubTitle } from 'AppTheme'

import Paths from 'Paths'

const useStyles = makeStyles()((theme) => ({
  heading: widgetSubTitle(theme),
  timeline: {
    marginTop: theme.spacing(1),
    '& .MuiTimelineSeparator-root': {
      marginTop: 16,
      marginBottom: -16
    },
    '& .MuiTimelineDot-root': {
      background: 'none',
      border: 'none',
      boxShadow: 'none',
      padding: 0,
      color: theme.palette.primary.main
    }
  },
  shiftTop: {
    marginTop: `-${theme.spacing(2)}`
  },
  shiftBottom: {
    marginBottom: `-${theme.spacing(2)}`
  },
  widget: {
    [theme.breakpoints.up('md')]: {
      minHeight: 275
    }
  },
  empty: {
    '& > div:last-child': {
      '& .MuiTypography-root': {
        fontSize: '14px !important'
      },
      [theme.breakpoints.up('md')]: {
        padding: theme.spacing(5)
      }
    }
  },
  label: {
    fontSize: 14,
    lineHeight: '22px'
  },
  bold: {
    fontWeight: 700
  },
  byLineIcon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(1),
    fontSize: 16,
    width: '16px'
  },
  marketDataWrapper: {
    '& > div:not(:first-of-type)': {
      paddingTop: theme.spacing(1)
    }
  },
  informIcon: {
    marginLeft: theme.spacing(1)
  },
  tagsWrapper: {
    marginBottom: `-${theme.spacing(0.5)}`,
    flexWrap: 'wrap',
    rowGap: theme.spacing(0.5)
  }
}))

export const contributor = (props: any) =>
  (!props.hideColleagueRelationships || props.BestEmailAddrText === props.meUser?.userKey) &&
  ((props.loading && props.looksLikeAnUser) || (!props.loading && props.MyUserKeyMd5))

export const PeopleWidget = (props: any) => {
  const { classes } = useStyles()
  const { data: items, total_item_count } = props.UserContacts || {}

  return (
    <Widget mdFlex0 className={classes.widget}>
      <Heading
        underlined
        title="People"
        count={total_item_count}
        icon={['far', 'user']}
        link={total_item_count > 0 ? `${Paths._people}/${props.MyUserKeyMd5}/people` : null}
      />
      {!!total_item_count && (
        <Repeater
          direction="horizontal"
          component={Avatar}
          skeleton={{ size: 4, loading: props.loading }}
          items={items?.map((contact: any) => ({
            userKey: contact.ContactUserKeyMd5 ? contact.BestEmailAddrText : '',
            name: contact.PersonNameText,
            score: contact.ScorePoints,
            sidepanel: true,
            link: `${Paths._people}/${contact.PersonMd5}`
          }))}
        />
      )}
      {!total_item_count && (
        <Empty
          className={classes.empty}
          subTitle={`${props.PersonNameText} has no people`}
          icon={<FontAwesomeIcon size="3x" icon={['fat', 'user-group']} style={{ color: '#A7A7A7' }} />}
          close={false}
        />
      )}
    </Widget>
  )
}

const CompaniesWidget = (props: any) => {
  const { classes } = useStyles()
  const { data: items, total_item_count } = props.UserCompanies || {}

  return (
    <Widget mdFlex0 className={classes.widget}>
      <Heading
        underlined
        title="Companies"
        count={total_item_count}
        icon={['far', 'building']}
        link={total_item_count > 0 ? `${Paths._people}/${props.MyUserKeyMd5}/companies` : null}
      />
      {!!total_item_count && (
        <Repeater
          direction="horizontal"
          component={Avatar}
          skeleton={{ size: 4, loading: props.loading }}
          items={items?.map((company: any) => ({
            name: company.CompanyNameText,
            score: company.ScorePoints,
            logoUrl: company.BestUrlText,
            sidepanel: true,
            link: `${Paths._relationships}/${props.MyUserKeyMd5}/companies/${company.CompanyMd5}`
          }))}
        />
      )}
      {!total_item_count && (
        <Empty
          className={classes.empty}
          subTitle={`${props.PersonNameText} has no companies`}
          icon={<FontAwesomeIcon size="3x" icon={['fat', 'buildings']} style={{ color: '#A7A7A7' }} />}
          close={false}
        />
      )}
    </Widget>
  )
}

const IntroducersWidget = (props: any) => {
  const { classes } = useStyles()
  const { data: items, total_item_count } = props.Introducers || {}

  return (
    <Widget className={classes.widget}>
      <Heading
        underlined
        title="Introducers"
        count={total_item_count}
        icon={['far', 'address-book']}
        link={total_item_count ? `${Paths._people}/${props.PersonMd5}/introducers` : ''}
      />
      {(!!total_item_count || props.loading) && (
        <Repeater
          direction="horizontal"
          component={Avatar}
          skeleton={{ size: 5, loading: props.loading }}
          items={items?.slice(0, 5).map((intro: any) => ({
            name: intro.IntroducerName,
            userKey: intro.IntroducerBestEmailAddressText,
            score: intro.ScorePoints,
            sidepanel: true,
            link: `${Paths._people}/${intro.UserKeyMd5 || intro.IntroducerPersonKeyMd5}`
          }))}
        />
      )}
      {!total_item_count && !props.loading && (
        <Empty
          className={classes.empty}
          subTitle={`${props.PersonNameText} has no introducers`}
          icon={<FontAwesomeIcon size="3x" icon={['fat', 'address-book']} style={{ color: '#A7A7A7' }} />}
          close={false}
        />
      )}
    </Widget>
  )
}

const TouchpointsWidget = (props: any) => {
  const { classes } = useStyles()
  const stats = !props.loading
    ? Object.getOwnPropertyNames(props.Stats).filter(
        (k) => ['FirstInboundMsg', 'FirstMeeting', 'FirstOutboundMsg', 'LastInboundMsg', 'LastMeeting', 'LastOutboundMsg'].indexOf(k) > -1
      )
    : []

  return (
    <Widget className={classes.widget}>
      <Heading
        underlined
        title="Key touch points"
        icon={['far', 'handshake']}
        count={props.loading || props.Stats === null ? -1 : stats.length}
        link={stats.length && !props.loading ? `${Paths._people}/${props.PersonMd5}/touchpoints` : ``}
      />
      {(!!stats.length || props.loading) && (
        <Repeater component={TitleDescription} skeleton={{ size: 3, loading: props.loading }} items={TouchpointSummary(props.Stats)} />
      )}
      {!stats.length && !props.loading && (
        <Empty
          className={classes.empty}
          subTitle={`You have no touchpoints with ${props.PersonNameText}`}
          icon={<FontAwesomeIcon size="3x" icon={['fat', 'handshake']} style={{ color: '#A7A7A7' }} />}
          close={false}
        />
      )}
    </Widget>
  )
}

type InformAffiliationType = { companyName: string; isFormerJob: boolean; currentAsOf: string }

const AffiliationsWidget = (props: any) => {
  const { classes } = useStyles()
  const { data: items, total_item_count } = props.Jobs || {}
  const { dialogContentProps: openedDialog, openDialog, closeDialog, successMode, openSuccess } = useDialog<InformAffiliationType>()
  const wide = useWide()

  const handleOpenDialog = (e: SyntheticEvent, data: InformAffiliationType) => {
    e.stopPropagation()
    openDialog(data)
  }

  const handleCloseDialog = () => {
    closeDialog()
  }

  return (
    <>
      <Widget>
        <Heading
          underlined
          title="Affiliations"
          count={total_item_count}
          icon={['far', 'suitcase']}
          link={total_item_count ? `${Paths._people}/${props.PersonMd5}/affiliations` : ''}
        />
        {(!!total_item_count || props.loading) && (
          <Repeater
            component={Affiliation}
            skeleton={{ size: 2, loading: props.loading }}
            items={(wide ? items?.slice(0, 2) : items)?.map((job: any) => ({
              title: job.JobCoName || job.JobCorpLevelName,
              description: job.JobTitleText,
              blurb: `Last evidence on ${moment(job.JobCurrentAsOf).format('MM/DD/YYYY')}`,
              sidepanel: true,
              link: `${Paths._companies}/${job.JobCompanyMd5}`,
              icons: (
                <Box display="flex" alignItems="center">
                  {job.JobIsFormer && (
                    <AffiliationPopover
                      workExperience={{
                        companyIdentity: job.JobCompanyMd5,
                        companyName: job.JobCoName || job.JobCorpLevelName,
                        jobTitle: job.JobTitleText,
                        currentAsOf: job.JobCurrentAsOf
                      }}
                      icon={['far', 'address-book']}
                    />
                  )}
                  <AffiliationInformDialog.TriggerEl
                    open={(e: SyntheticEvent) =>
                      handleOpenDialog(e, {
                        companyName: job.JobCoName || job.JobCorpLevelName,
                        isFormerJob: !!job.JobIsFormer,
                        currentAsOf: job.JobCurrentAsOf || ''
                      })
                    }
                  />
                </Box>
              )
            }))}
          />
        )}
        {!total_item_count && !props.loading && (
          <Empty
            className={classes.empty}
            subTitle={`${props.PersonNameText} has no affiliations`}
            icon={<FontAwesomeIcon size="3x" icon={['fat', 'suitcase']} style={{ color: '#A7A7A7' }} />}
            close={false}
          />
        )}
      </Widget>
      <AffiliationInformDialog
        personId={props.PersonMd5}
        personName={props.PersonNameText}
        close={handleCloseDialog}
        openSuccess={openSuccess}
        opened={!!openedDialog}
        success={successMode}
        {...openedDialog}
      />
    </>
  )
}

const InteractionsWidget = (props: any) => {
  const { classes } = useStyles()
  const { pathname } = useLocation()
  const { interactions } = props
  const { data: items, errorMessage } = interactions || {}
  const loading = props.loading || !interactions

  return (
    <Widget>
      <Heading
        underlined
        title="Interactions"
        icon={['far', 'envelope']}
        count={0}
        action={
          <Box flex={1}>
            <InteractionsPrivacyMsg />
          </Box>
        }
        link={!loading && items?.length ? `${Paths._people}/${props.PersonMd5}/interactions` : undefined}
      />
      {errorMessage && (
        <Empty
          className={classes.empty}
          icon={<FontAwesomeIcon size="4x" style={{ color: '#A7A7A7' }} icon={['fat', 'envelope-open-text']} />}
          subTitle={errorMessage}
        />
      )}
      {!errorMessage && (
        <>
          {(!!items?.length || loading) && (
            <Timeline scope="widget" className={classes.timeline} loading={loading}>
              <TimelineList
                items={items
                  ?.map((item: { [key: string]: any }) => ({
                    time: getTime(item.receivedDateTime),
                    title: item.rawSubject || '(No subject)',
                    byline: item.body,
                    byline2: (
                      <NameLink
                        variant="light"
                        url={stringifyUrl(`${Paths._people}/${item.senderEmail}`, { name: item.senderName, email: item.senderEmail })}
                        name={item.senderName}
                      />
                    ),
                    dotIcon: <FontAwesomeIcon icon={['fas', 'envelope']} style={{ fontSize: 14 }} />,
                    sidepanel: true,
                    link: stringifyUrl(`${Paths._messages}/${item.sourceKey}`, { deleteBackLink: pathname }),
                    component: 'div'
                  }))
                  .slice(0, 5)}
                skeleton={{ size: 5, loading }}
                component={Interaction}
              />
            </Timeline>
          )}
          {!items?.length && !loading && (
            <Empty
              className={classes.empty}
              subTitle={`You have not exchanged any email messages with ${props.PersonNameText}`}
              icon={<FontAwesomeIcon size="3x" icon={['fat', 'envelope-open-text']} style={{ color: '#A7A7A7' }} />}
              close={false}
            />
          )}
        </>
      )}
    </Widget>
  )
}

const ContactInformationWidget = (props: any) => {
  const {
    classes: { heading, widget }
  } = useStyles()
  const { id } = useParams<{ id: string }>()

  const submitInvalid = (identity: string, value: number) => {
    return post(`/people/disqualify?Identity=${identity}&Reason=${emailReason[value]}`)
  }

  return (
    <Widget className={widget}>
      <Heading
        underlined
        title="Contact information"
        count={props.loading ? -1 : sum(props.Phones?.total_item_count, props.Emails?.total_item_count)}
        icon={['far', 'address-card']}
        link={!props.loading && `${Paths._people}/${props.PersonMd5}/info`}
      />
      <Box marginBottom="16px">
        <Typography
          variant="body1"
          className={heading}
          component="div"
          style={{ marginTop: 16, paddingTop: 8, marginBottom: props.Emails?.total_item_count ? -8 : 0 }}
        >
          Emails
        </Typography>

        <Repeater
          component={IdentifierLabel}
          variant="homepage"
          skeleton={{ size: 2, loading: props.loading }}
          items={props.Emails?.data.map((email: { [key: string]: any }) => ({
            identity: email.AddressText,
            type: 'EmailAddress',
            icon: ['far', 'envelope'],
            name: props.PersonNameText,
            submit: submitInvalid,
            sourceLink: `${Paths._people}/${id}/emailSources/${email.AddressText}`,
            auditLink: `${Paths._people}/${id}/audit`
          }))}
          empty="No email addresses were found"
        />

        <Typography
          variant="body1"
          className={heading}
          component="div"
          style={{ marginTop: 16, marginBottom: props?.Phones?.total_item_count ? -8 : 0 }}
        >
          Phone numbers
        </Typography>

        <Repeater
          component={PhoneNumber}
          variant="homepage"
          skeleton={{ size: 2, loading: props.loading }}
          items={props.Phones?.data.map((phone: any) => ({
            phoneType: phone.PhoneType,
            number: phone.StandardizedPhoneNumber,
            person: { name: props.PersonNameText, id: props.PersonMd5 }
          }))}
          empty="No phone numbers were found"
        />
      </Box>
    </Widget>
  )
}

const SimilarPeopleWidget = ({ similar, setSimilar, teamNumber, ...props }: any) => {
  const [error, setError] = useState('')
  const { id } = useParams<{ id: string }>()
  const { fetchWithAbort, loading } = useAbortableFetch<{ [key: string]: any }>()
  const { classes } = useStyles()

  const load = loading || props.loading
  const {
    job_title,
    job_title_role,
    job_title_sub_role,
    job_title_levels,
    job_company_industry,
    industry,
    location_country,
    job_company_size,
    work_email
  } = props.marketData || {}
  const enoughFilters = props.marketData && (work_email || props.BestEmailAddrText) && (job_title || props.BestJobTitleText)
  const showEmptyWidget = error || (enoughFilters && !loading && !similar?.data?.length) || (props.marketData && !enoughFilters)

  const initParams = useMemo(
    () => ({
      email: work_email || props.BestEmailAddrText,
      jobTitles: [job_title || props.BestJobTitleText].filter(Boolean),
      jobTitleRoles: job_title_role ? [job_title_role] : [],
      jobTitleSubroles: job_title_sub_role ? [job_title_sub_role] : [],
      jobTitleLevels: job_title_levels || [],
      companySize: job_company_size || '',
      industries: job_company_industry || industry ? [job_company_industry || industry] : [],
      countries: [location_country || 'United States'],
      teamNumber: teamNumber.toString(),
      titlecase: 'true'
    }),
    [props.BestJobTitleText, props.BestEmailAddrText, work_email, job_title, job_company_industry, industry, location_country, teamNumber]
  )
  useEffect(() => {
    if (enoughFilters && !similar && teamNumber) {
      ;(async () => {
        try {
          const res = await fetchWithAbort({ url: mergeUrlWithParams('/people/similarPeople', initParams) })
          if (res) {
            setSimilar(res)
          }
        } catch (e: any) {
          setSimilar({})
          setError(e?.status === 500 ? 'No records were found' : e?.error)
          console.log('error during fetching similar people', e)
        }
      })()
    }
  }, [enoughFilters, similar, teamNumber])

  const items = similar?.data
    ? similar.data.map((person: { [key: string]: any }) => {
        return {
          name: person.full_name,
          score: person.personMd5 ? person.score : undefined,
          sidepanel: true,
          link: person.personMd5
            ? `${Paths._people}/${person.personMd5}`
            : mergeUrlWithParams(`${Paths._people}/${id}/similar/${person.full_name}`, {
                email: person.work_email,
                company: person.job_company_name
              })
        }
      })
    : []

  return (
    <Widget mdFlex0>
      <Heading
        underlined
        title="Similar people"
        icon={['fas', 'diagram-venn']}
        count={showEmptyWidget ? 0 : similar?.total}
        link={similar?.total && id && !showEmptyWidget ? `${Paths._people}/${id}/similar?clearSearch=true&name=${props.PersonNameText}` : undefined}
        sidepanel
      />

      {!showEmptyWidget && (
        <Repeater direction="horizontal" component={Avatar} skeleton={{ size: 5, loading: !props.marketData || load }} items={items} />
      )}

      {showEmptyWidget && (
        <Empty
          className={classes.empty}
          subTitle={
            error ||
            (enoughFilters ? 'No records were found matching your search' : 'Not enough market data is available for an effective search filter.')
          }
          action={
            <Box display="flex" justifyContent="center">
              <SidepanelLink
                linkProps={{ to: `${Paths._people}/${id}/similar?openedFilters=true&clearSearch=true&name=${props.PersonNameText}` }}
                sidepanel={true}
              >
                <Button variant="link" bold={false}>
                  {enoughFilters ? 'Change' : 'Add'} filters
                </Button>
              </SidepanelLink>
            </Box>
          }
          icon={<FontAwesomeIcon size="3x" icon={['fat', 'user-group']} style={{ color: '#A7A7A7' }} />}
        />
      )}
    </Widget>
  )
}

const MarketDataWidget = (props: any) => {
  const { classes } = useStyles()
  const { id: personId } = useParams<{ id: string }>()
  const { location_name, industry, job_company_industry, skills, interests, experience, education, work_email, id } = props.marketData || {}
  const mocked = new Array(3).fill('')

  const noData = props.marketData && !id
  const noWidgetData = id && !location_name && (!job_company_industry || !industry) && !skills?.length && !interests?.length
  const notEnoughData = noWidgetData && !experience?.length && !education?.length && !work_email

  return (
    <Widget>
      <Heading
        underlined
        title="Details"
        icon={['fas', 'chart-column']}
        count={0}
        action={
          <Box flex={1}>
            <IconButton
              disablePY
              component="a"
              href="https://help.dotalign.com/article/x8f8c6vw1i-dot-align-market-data"
              target="_blank"
              size="small"
              icon={['far', 'question-circle']}
              hint="Learn more about market data"
            />
          </Box>
        }
        link={!noData && !notEnoughData && props.PersonNameText ? `${Paths._people}/${personId}/market-data?name=${props.PersonNameText}` : ''}
        sidepanel
      />
      {(noData || notEnoughData || (noWidgetData && !notEnoughData)) && (
        <Empty
          className={classes.empty}
          icon={<FontAwesomeIcon size="3x" icon={['fat', 'chart-column']} style={{ color: '#A7A7A7' }} />}
          subTitle={
            <>
              {noData && 'No details are available in market data'}
              {notEnoughData && 'Not enough details are available in market data'}
              {noWidgetData && !notEnoughData && 'No skill, interest, location or industry info is available, click "see all" for other market data'}
            </>
          }
        />
      )}

      <Box mb={{ md: 2 }} className={classes.marketDataWrapper}>
        {(!props.marketData || location_name) && (
          <Skeleton condition={!props.marketData && !location_name}>
            <Tooltip title={location_name} placement="bottom-start">
              <Box display="flex" alignItems="center">
                <FontAwesomeIcon icon={['fas', 'location-dot']} className={classes.byLineIcon} />
                <Typography>{location_name || 'Placeholder name'}</Typography>
              </Box>
            </Tooltip>
          </Skeleton>
        )}
        {(!props.marketData || job_company_industry || industry) && (
          <Skeleton condition={!props.marketData && (!job_company_industry || !industry)}>
            <Tooltip title={job_company_industry || industry} placement="bottom-start">
              <Box display="flex" alignItems="center">
                <FontAwesomeIcon icon={['fas', 'industry']} className={classes.byLineIcon} />
                <Typography>{job_company_industry || industry || 'Placeholder name'}</Typography>
              </Box>
            </Tooltip>
          </Skeleton>
        )}
        {(!props.marketData || !!skills?.length) && (
          <TagsGroup<ComponentProps<typeof ExternalTag>['tagData']>
            tagComponent={ExternalTag}
            className={classes.tagsWrapper}
            max={2}
            items={!props.marketData ? mocked : skills}
            title={<Typography bold>Skills</Typography>}
            {...(props.marketData
              ? {
                  renderShowAll: ({ extraTagsAmount }: { extraTagsAmount: number }) => (
                    <ExtraTagsPopover
                      tags={!props.marketData ? mocked : skills}
                      title="Skills"
                      name={props.PersonNameText}
                      triggerElement={
                        <Box display="inline-flex" alignItems="center" height="100%">
                          <Typography noWrap>{extraTagsAmount} more</Typography>
                        </Box>
                      }
                    />
                  )
                }
              : {})}
          />
        )}
        {(!props.marketData || !!interests?.length) && (
          <TagsGroup<ComponentProps<typeof ExternalTag>['tagData']>
            tagComponent={ExternalTag}
            className={classes.tagsWrapper}
            max={2}
            items={!props.marketData ? mocked : interests}
            title={<Typography bold>Interests</Typography>}
            {...(props.marketData
              ? {
                  renderShowAll: ({ extraTagsAmount }: { extraTagsAmount: number }) => (
                    <ExtraTagsPopover
                      tags={!props.marketData ? mocked : interests}
                      title="Interests"
                      name={props.PersonNameText}
                      triggerElement={
                        <Box display="inline-flex" alignItems="center" height="100%">
                          <Typography noWrap>{extraTagsAmount} more</Typography>
                        </Box>
                      }
                    />
                  )
                }
              : {})}
          />
        )}
      </Box>
    </Widget>
  )
}

const Profile = ({ showInteractionsInProfile, marketData, marketDataIntegration, similar, setSimilar, teamNumber, ...props }: any) => {
  const { classes, cx } = useStyles()

  useEffect(() => {
    if (!props.loading && props.PersonMd5) window.parent.postMessage(`da_open`, '*')
  }, [props.loading, props.PersonMd5])

  const { enabled: enabledMarketData, showSimilarPeople } = marketDataIntegration || {}

  return (
    <>
      <WideStrict>
        <Columns className={classes.shiftBottom}>
          <Column md={12}>
            <AlertWidget {...props} />
          </Column>
        </Columns>
        {showInteractionsInProfile && !enabledMarketData && (
          <>
            <Columns>
              {!contributor(props) && (
                <Column>
                  <IntroducersWidget {...props} />
                  <AffiliationsWidget {...props} />
                </Column>
              )}
              {!contributor(props) && (
                <Column>
                  <TouchpointsWidget {...props} />
                  <ContactInformationWidget {...props} />
                </Column>
              )}
              {contributor(props) && (
                <Column>
                  <PeopleWidget {...props} />
                  <TouchpointsWidget {...props} />
                </Column>
              )}
              {contributor(props) && (
                <Column>
                  <CompaniesWidget {...props} />
                  <AffiliationsWidget {...props} />
                </Column>
              )}
              <Column>
                <InteractionsWidget {...props} />
              </Column>
            </Columns>
            {contributor(props) && (
              <Columns className={classes.shiftTop}>
                <Column>
                  <ContactInformationWidget {...props} />
                </Column>
              </Columns>
            )}
          </>
        )}
        {showInteractionsInProfile && enabledMarketData && (
          <>
            <Columns>
              {showSimilarPeople && (
                <>
                  <Column>
                    <MarketDataWidget marketData={marketData} {...props} />
                    {contributor(props) && <CompaniesWidget {...props} />}
                    {!contributor(props) && <AffiliationsWidget {...props} />}
                  </Column>
                  {!contributor(props) && (
                    <Column>
                      <IntroducersWidget {...props} />
                      <SimilarPeopleWidget marketData={marketData} similar={similar} setSimilar={setSimilar} teamNumber={teamNumber} {...props} />
                    </Column>
                  )}
                  {contributor(props) && (
                    <Column>
                      <SimilarPeopleWidget marketData={marketData} similar={similar} setSimilar={setSimilar} teamNumber={teamNumber} {...props} />
                      <PeopleWidget {...props} />
                    </Column>
                  )}
                </>
              )}
              {!showSimilarPeople && (
                <>
                  <Column>
                    <MarketDataWidget marketData={marketData} {...props} />
                    {contributor(props) && <CompaniesWidget {...props} />}
                    {!contributor(props) && <AffiliationsWidget {...props} />}
                  </Column>
                  <Column>
                    {contributor(props) && <PeopleWidget {...props} />}
                    {!contributor(props) && <IntroducersWidget {...props} />}
                    <TouchpointsWidget {...props} />
                  </Column>
                </>
              )}
              <Column>
                <InteractionsWidget {...props} />
              </Column>
            </Columns>
            <Columns className={classes.shiftTop}>
              {showSimilarPeople && (
                <Column>
                  <TouchpointsWidget {...props} />
                </Column>
              )}
              {contributor(props) && (
                <Column>
                  <AffiliationsWidget {...props} />
                </Column>
              )}
              <Column>
                <ContactInformationWidget {...props} />
              </Column>
            </Columns>
          </>
        )}
        {!showInteractionsInProfile && !enabledMarketData && (
          <>
            <Columns>
              {!contributor(props) && (
                <>
                  <Column>
                    <IntroducersWidget {...props} />
                  </Column>
                  <Column>
                    <TouchpointsWidget {...props} />
                  </Column>
                  <Column>
                    <AffiliationsWidget {...props} />
                  </Column>
                </>
              )}
              {contributor(props) && (
                <>
                  <Column>
                    <PeopleWidget {...props} />
                  </Column>
                  <Column>
                    <CompaniesWidget {...props} />
                  </Column>
                  <Column>
                    <TouchpointsWidget {...props} />
                  </Column>
                </>
              )}
            </Columns>
            <Columns className={classes.shiftTop}>
              {contributor(props) && (
                <Column>
                  <AffiliationsWidget {...props} />
                </Column>
              )}
              <Column>
                <ContactInformationWidget {...props} />
              </Column>
            </Columns>
          </>
        )}
        {!showInteractionsInProfile && enabledMarketData && (
          <>
            <Columns>
              <Column>
                <MarketDataWidget marketData={marketData} {...props} />
              </Column>
              {!contributor(props) && showSimilarPeople && (
                <Column>
                  <IntroducersWidget {...props} />
                </Column>
              )}
              {contributor(props) && (
                <>
                  {showSimilarPeople ? (
                    <Column>
                      <SimilarPeopleWidget marketData={marketData} similar={similar} setSimilar={setSimilar} teamNumber={teamNumber} {...props} />
                    </Column>
                  ) : (
                    <Column>
                      <CompaniesWidget {...props} />
                    </Column>
                  )}
                  <Column>
                    <PeopleWidget {...props} />
                  </Column>
                </>
              )}
              {!contributor(props) && (
                <>
                  {showSimilarPeople && (
                    <Column>
                      <SimilarPeopleWidget marketData={marketData} similar={similar} setSimilar={setSimilar} teamNumber={teamNumber} {...props} />
                    </Column>
                  )}
                  {!showSimilarPeople && (
                    <>
                      <Column>
                        <IntroducersWidget {...props} />
                      </Column>
                      <Column>
                        <TouchpointsWidget {...props} />
                      </Column>
                    </>
                  )}
                </>
              )}
            </Columns>
            <Columns className={classes.shiftTop}>
              {!contributor(props) && (
                <>
                  {showSimilarPeople && (
                    <Column>
                      <TouchpointsWidget {...props} />
                    </Column>
                  )}
                  <Column>
                    <AffiliationsWidget {...props} />
                  </Column>
                  <Column>
                    <ContactInformationWidget {...props} />
                  </Column>
                </>
              )}
              {contributor(props) && (
                <>
                  {showSimilarPeople && (
                    <Column>
                      <CompaniesWidget {...props} />
                    </Column>
                  )}
                  <Column>
                    <TouchpointsWidget {...props} />
                  </Column>
                  <Column>
                    <AffiliationsWidget {...props} />
                  </Column>
                  {!showSimilarPeople && (
                    <Column>
                      <ContactInformationWidget {...props} />
                    </Column>
                  )}
                </>
              )}
            </Columns>
            {contributor(props) && showSimilarPeople && (
              <Columns className={classes.shiftTop}>
                <Column>
                  <ContactInformationWidget {...props} />
                </Column>
              </Columns>
            )}
          </>
        )}
      </WideStrict>
      <Middle>
        <Columns className={classes.shiftBottom}>
          <Column md={12}>
            <AlertWidget {...props} />
          </Column>
        </Columns>
        {showInteractionsInProfile && (!enabledMarketData || showSimilarPeople) && (
          <>
            <Columns>
              {showSimilarPeople && (
                <Column two>
                  <MarketDataWidget marketData={marketData} {...props} />
                  {!contributor(props) && <IntroducersWidget {...props} />}
                  {contributor(props) && (
                    <SimilarPeopleWidget marketData={marketData} similar={similar} setSimilar={setSimilar} teamNumber={teamNumber} {...props} />
                  )}
                </Column>
              )}

              {!contributor(props) && !showSimilarPeople && (
                <Column two>
                  <IntroducersWidget {...props} />
                  <TouchpointsWidget {...props} />
                </Column>
              )}
              {contributor(props) && !showSimilarPeople && (
                <Column two>
                  <PeopleWidget {...props} />
                  <CompaniesWidget {...props} />
                </Column>
              )}
              <Column two>
                <InteractionsWidget {...props} />
              </Column>
            </Columns>

            {showSimilarPeople && (
              <Columns className={classes.shiftTop}>
                {!contributor(props) && (
                  <>
                    <Column two>
                      <SimilarPeopleWidget marketData={marketData} similar={similar} setSimilar={setSimilar} teamNumber={teamNumber} {...props} />
                    </Column>
                    <Column two>
                      <TouchpointsWidget {...props} />
                    </Column>
                  </>
                )}
                {contributor(props) && (
                  <>
                    <Column two>
                      <PeopleWidget {...props} />
                    </Column>
                    <Column two>
                      <CompaniesWidget {...props} />
                    </Column>
                  </>
                )}
              </Columns>
            )}

            {!contributor(props) && (
              <Columns className={classes.shiftTop}>
                <Column two>
                  <AffiliationsWidget {...props} />
                </Column>
                <Column two>
                  <ContactInformationWidget {...props} />
                </Column>
              </Columns>
            )}
            {contributor(props) && (
              <Columns className={classes.shiftTop}>
                <Column two>
                  <TouchpointsWidget {...props} />
                </Column>
                <Column two>
                  <AffiliationsWidget {...props} />
                </Column>
              </Columns>
            )}
            {contributor(props) && (
              <Columns className={classes.shiftTop}>
                <Column two>
                  <ContactInformationWidget {...props} />
                </Column>
              </Columns>
            )}
          </>
        )}
        {showInteractionsInProfile && enabledMarketData && !showSimilarPeople && (
          <>
            <Columns>
              <Column two>
                <MarketDataWidget marketData={marketData} {...props} />
                {!contributor(props) && <IntroducersWidget {...props} />}
                {contributor(props) && <PeopleWidget {...props} />}
              </Column>

              <Column two>
                <InteractionsWidget {...props} />
              </Column>
            </Columns>

            {!contributor(props) && !showSimilarPeople && (
              <Columns className={classes.shiftTop}>
                <Column two>
                  <TouchpointsWidget {...props} />
                </Column>
                <Column two>
                  <AffiliationsWidget {...props} />
                </Column>
              </Columns>
            )}
            {contributor(props) && !showSimilarPeople && (
              <Columns className={classes.shiftTop}>
                <Column two>
                  <TouchpointsWidget {...props} />
                </Column>
                <Column two>
                  <CompaniesWidget {...props} />
                </Column>
              </Columns>
            )}

            <Columns className={classes.shiftTop}>
              {contributor(props) && (
                <Column two>
                  <AffiliationsWidget {...props} />
                </Column>
              )}
              <Column two>
                <ContactInformationWidget {...props} />
              </Column>
            </Columns>
          </>
        )}
        {!showInteractionsInProfile && (!enabledMarketData || showSimilarPeople) && (
          <>
            {showSimilarPeople && (
              <Columns>
                <Column two>
                  <MarketDataWidget marketData={marketData} {...props} />
                </Column>
                {contributor(props) && (
                  <Column two>
                    <SimilarPeopleWidget marketData={marketData} similar={similar} setSimilar={setSimilar} teamNumber={teamNumber} {...props} />
                  </Column>
                )}
                {!contributor(props) && (
                  <Column two>
                    <IntroducersWidget {...props} />
                  </Column>
                )}
              </Columns>
            )}
            <Columns className={cx({ [classes.shiftTop]: showSimilarPeople })}>
              {!contributor(props) && (
                <>
                  {showSimilarPeople ? (
                    <Column two>
                      <SimilarPeopleWidget marketData={marketData} similar={similar} setSimilar={setSimilar} teamNumber={teamNumber} {...props} />
                    </Column>
                  ) : (
                    <Column two>
                      <IntroducersWidget {...props} />
                    </Column>
                  )}
                  <Column two>
                    <TouchpointsWidget {...props} />
                  </Column>
                </>
              )}
              {contributor(props) && (
                <>
                  <Column two>
                    <PeopleWidget {...props} />
                  </Column>
                  <Column two>
                    <CompaniesWidget {...props} />
                  </Column>
                </>
              )}
            </Columns>
            {!contributor(props) && (
              <Columns className={classes.shiftTop}>
                <Column two>
                  <AffiliationsWidget {...props} />
                </Column>
                <Column two>
                  <ContactInformationWidget {...props} />
                </Column>
              </Columns>
            )}
            {contributor(props) && (
              <Columns className={classes.shiftTop}>
                <Column two>
                  <TouchpointsWidget {...props} />
                </Column>
                <Column two>
                  <AffiliationsWidget {...props} />
                </Column>
              </Columns>
            )}
            {contributor(props) && (
              <Columns className={classes.shiftTop}>
                <Column two>
                  <ContactInformationWidget {...props} />
                </Column>
              </Columns>
            )}
          </>
        )}
        {!showInteractionsInProfile && enabledMarketData && !showSimilarPeople && (
          <>
            <Columns>
              <Column two>
                <MarketDataWidget marketData={marketData} {...props} />
              </Column>
              {!contributor(props) && (
                <Column two>
                  <IntroducersWidget {...props} />
                </Column>
              )}
              {contributor(props) && (
                <Column two>
                  <PeopleWidget {...props} />
                </Column>
              )}
            </Columns>
            <Columns className={classes.shiftTop}>
              {!contributor(props) && (
                <Column two>
                  <AffiliationsWidget {...props} />
                </Column>
              )}
              {contributor(props) && (
                <Column two>
                  <CompaniesWidget {...props} />
                </Column>
              )}
              <Column two>
                <TouchpointsWidget {...props} />
              </Column>
            </Columns>

            <Columns className={classes.shiftTop}>
              {contributor(props) && (
                <Column two>
                  <AffiliationsWidget {...props} />
                </Column>
              )}
              <Column two>
                <ContactInformationWidget {...props} />
              </Column>
            </Columns>
          </>
        )}
      </Middle>
      <Narrow>
        <AlertWidget {...props} />
        {enabledMarketData && <MarketDataWidget marketData={marketData} {...props} />}
        {!contributor(props) && <IntroducersWidget {...props} />}
        {showSimilarPeople && (
          <SimilarPeopleWidget marketData={marketData} similar={similar} setSimilar={setSimilar} teamNumber={teamNumber} {...props} />
        )}
        {contributor(props) && (
          <>
            <PeopleWidget {...props} />
            <CompaniesWidget {...props} />
          </>
        )}
        {showInteractionsInProfile && <InteractionsWidget {...props} />}
        <TouchpointsWidget {...props} />
        <AffiliationsWidget {...props} />
        <ContactInformationWidget {...props} />
      </Narrow>
    </>
  )
}

export default Profile
