import React, { useContext, useEffect, useState } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box } from '@mui/material'
import { useLocation } from 'react-router-dom'
import { makeStyles } from 'tss-react/mui'

import { isSidepanel } from '_pages/sidebar'

import { Button, IconButton } from '_shared/buttons'
import FloatingMenuButton, { IMenuAction } from '_shared/buttons/FloatingMenuButton'

import DataUploadsList, {
  DataUploadsListProps,
  fileTypeColumn,
  titleColumn,
  fileNameColumn,
  uploaderColumn,
  descriptionColumn,
  onBehalfOfColumn
} from '_core/components/data-uploads/List'
import Grid from '_core/components/grid'
import { dateColumn, statusColumn } from '_core/components/grid/columns'
import { Middle, Narrow, Wide, WideStrict } from '_core/components/layout'
import { useAutoHideOnScrollStyles } from '_core/components/layout/autohide-on-scroll'
import SidepanelLink from '_core/components/SidepanelLink'
import Sort, { Controller } from '_core/components/sort/DataUploads'
import Topbar from '_core/components/Topbar'
import Widget from '_core/components/Widget'

import useSearchQuery from '_core/hooks/useSearchQuery'
import useSidepanelPayloads from '_core/hooks/useSidepanelPayloads'

import DynamicEntity from '_core/DynamicEntity'
import { checkOutlook } from '_core/helpers/outlook'

import { mergeUrlWithParams } from 'utils/httpUtils'

import { LayoutContext } from 'Layout/LayoutContextProvider'

import Paths from 'Paths'

const useStyles = makeStyles()((theme) => ({
  widget: {
    position: 'relative',
    display: 'flex',
    minHeight: 'calc(100vh - 136px)'
  },
  sortWrapper: {
    padding: theme.spacing(2)
  },
  searchWidget: {
    '&&': {
      padding: 0
    }
  }
}))

const actions: IMenuAction[] = [
  {
    label: 'Upload contacts',
    icon: ['far', 'address-book'],
    link: `${Paths._people}/upload`
  },
  {
    label: 'Upload companies',
    icon: ['far', 'building'],
    link: `${Paths._companies}/upload`
  },
  {
    label: 'Upload deals',
    icon: ['far', 'handshake'],
    link: `${Paths._deals}/upload`
  },
  {
    label: 'Upload LinkedIn connections',
    icon: ['fab', 'linkedin'],
    link: `${Paths.dataSources}/upload`
  }
]

const columns = [dateColumn, fileTypeColumn, titleColumn, fileNameColumn, uploaderColumn, onBehalfOfColumn, descriptionColumn, statusColumn]

const DataUploadsPage = () => {
  const { search } = useLocation()
  const [reload, setReload] = useState<boolean>()
  const { setMobileHeader } = useContext(LayoutContext)
  const { queryParams, updateQuery } = useSearchQuery<DataUploadsPageParams>()
  const [sortCollapsed, setSortCollapsed] = useState(true)
  const { rowsPerPage = '10', sort } = queryParams
  const { payloads } = useSidepanelPayloads()
  const { autoHideClassName } = useAutoHideOnScrollStyles(true)
  const hasSidebar = isSidepanel() || checkOutlook()
  const { classes, cx } = useStyles()

  useEffect(() => {
    setMobileHeader('Data uploads')
  }, [setMobileHeader])

  useEffect(() => {
    if (!search) {
      updateQuery({ rowsPerPage, sort: 'DateDesc' })
    }
  }, [search])

  useEffect(() => {
    if (payloads && payloads.action === 'RELOAD_LIST' && payloads.value.includes('data-uploads')) {
      setReload(true)
    }
  }, [payloads])

  useEffect(() => {
    if (reload) setReload(false)
  }, [reload])

  const onPageSizeChange = (pageSize: NumberToString<RowPerPageOptionsType>) => {
    updateQuery({ rowsPerPage: pageSize, page: null })
  }

  const toggleSort = () => {
    setSortCollapsed((prev) => !prev)
  }

  const DataUploads = (
    <DynamicEntity<{ extraProps: { addprops: { columns: DataUploadsListProps['columns'] } } }>
      url={
        !reload && sort
          ? mergeUrlWithParams(`/agents/uploadstatuses`, {
              sort,
              fileTypes: ['ContactsUpsertion', 'AccountsUpsertion', 'OurDealsUpsertion', 'LinkedIn']
            })
          : null
      }
      addprops={{ columns }}
      pageSize={+rowsPerPage}
      updatePageSize={onPageSizeChange}
      component={DataUploadsList}
      list={true}
      keepMounted
      infinite={true}
      autoHideOnScroll
      empty="No results found"
      id="data_uploads"
    />
  )

  const sortItems = columns.filter(({ sortable }) => sortable).map(({ headerName, field }) => ({ label: headerName || '', field }))

  return (
    <>
      <Wide>
        <Widget scope="list" className={classes.widget}>
          <Grid>
            <Grid.Heading title="Data uploads">
              {actions.map((action, i) => (
                <SidepanelLink sidepanel={true} key={action.link} linkProps={{ to: action.link }}>
                  <WideStrict>
                    <Box ml={2}>
                      <Button variant="outlined" color="primary" startIcon={<FontAwesomeIcon icon={action.icon} style={{ fontSize: 14 }} />}>
                        {action.label}
                      </Button>
                    </Box>
                  </WideStrict>
                  <Middle>
                    <IconButton
                      size="small"
                      icon={action.icon}
                      hint={action.label}
                      color="primary"
                      {...(actions.length - 1 === i ? { disablePR: true } : {})}
                    />
                  </Middle>
                </SidepanelLink>
              ))}
            </Grid.Heading>
            {DataUploads}
          </Grid>
        </Widget>
      </Wide>
      <Narrow>
        <Topbar nativeBack={Paths._dataAdmin} />
        <Widget className={cx(autoHideClassName, classes.searchWidget)} sticky={hasSidebar ? 88 : 60}>
          <Controller collapsed={sortCollapsed} toggleCollapsed={toggleSort} variant="text" />
          <Sort collapsed={sortCollapsed} items={sortItems} className={classes.sortWrapper} />
        </Widget>
        <Box px={2} pt={2}>
          {DataUploads}
        </Box>
        <FloatingMenuButton actions={actions} menuIcon={['fas', 'cloud-arrow-up']} />
      </Narrow>
    </>
  )
}

export default DataUploadsPage
