import { useEffect, useState } from 'react'

import { useSnackbar } from 'notistack'

import { CompaniesListProps } from '_core/components/CompaniesList'

import useSidepanelPayloads from '_core/hooks/useSidepanelPayloads'

const useCompaniesListPayloads = (items: CompaniesListProps['items']) => {
  const [withTagsList, setWithTagsList] = useState<{ identifier: string; tags: UngroupedTag[] }[]>([])
  const { payloads } = useSidepanelPayloads()
  const { enqueueSnackbar } = useSnackbar()

  useEffect(() => {
    if (items.length) {
      setWithTagsList(() =>
        items.map((company) => {
          return {
            identifier: company.CompanyMd5,
            tags: company.Tags?.[0]?.TagNames.map(({ Category, Tag }) => ({ categoryName: Category, tagName: Tag })) || []
          }
        })
      )
    }
  }, [items.length])

  useEffect(() => {
    if (payloads && payloads.action === 'START_DOWNLOADING') {
      enqueueSnackbar(payloads.value, {
        autoHideDuration: 4000
      })
    }
    if (payloads && payloads.action === 'UPDATE_TAGS') {
      if (Array.isArray(payloads.value)) {
        const identifiers = payloads.value.map((el) => el.identifier)
        const indexes = identifiers.map((identifier: string) => withTagsList?.findIndex((item) => item.identifier === identifier))
        const newItems = [...withTagsList]
        indexes.forEach((index: number) => {
          const updatedTags = payloads.value.find((el) => el.identifier === newItems[index]?.identifier)?.tags
          newItems[index] = {
            ...newItems[index],
            ...(updatedTags ? { tags: updatedTags } : {})
          }
        })
        setWithTagsList(newItems)
      }
    }
    if (payloads && payloads.action === 'SYNC_TAGS') {
      const { taggableType, categoriesRenames, tagsRenames, deprecations } = payloads.value
      if (taggableType === 'companies') {
        const renamed = withTagsList.map(({ identifier, tags }) => {
          const nonDeprecated = tags.filter(
            ({ categoryName, tagName }) =>
              !deprecations.find((deprecated) => deprecated.categoryName === categoryName && deprecated.tagName === tagName)
          )
          return {
            identifier,
            tags: nonDeprecated.map(({ categoryName, tagName }) => {
              const { newCategoryName = categoryName } = categoriesRenames.find(({ oldCategoryName }) => categoryName === oldCategoryName) || {}

              const { newTagName = tagName } =
                tagsRenames.find(({ oldCategoryName, oldTagName }) => categoryName === oldCategoryName && tagName === oldTagName) || {}
              return { categoryName: newCategoryName, tagName: newTagName }
            })
          }
        })

        setWithTagsList(renamed)
      }
    }
  }, [payloads])

  return {
    withTagsList
  }
}

export default useCompaniesListPayloads
