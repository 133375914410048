import React, { useContext, useEffect } from 'react'

import { useParams } from 'react-router-dom'

import Page from '_shared/Page'

import { Narrow } from '_core/components/layout'
import CompanyMarketData from '_core/components/market-data/CompanyMarketData'

import { useCompanyMarketData } from '_core/hooks/useCompanyMarketData'
import useSearchQuery from '_core/hooks/useSearchQuery'

import { LayoutContext } from 'Layout/LayoutContextProvider'

interface PageProps {
  getCompanyDetails: (name: string, website?: string) => { [key: string]: any } | undefined
}

const SimilarCompanyPage = ({ getCompanyDetails }: PageProps) => {
  const { similarId: name } = useParams<{ similarId: string }>()
  const { queryParams } = useSearchQuery<any>()
  const { website } = queryParams
  const { marketData, getMarketData, setMarketData, error } = useCompanyMarketData(name, website)
  const { setSubHeader } = useContext(LayoutContext)

  useEffect(() => {
    setSubHeader('Details')
  }, [])

  useEffect(() => {
    if (!marketData && name) {
      const details = getCompanyDetails(name, website)
      if (details) {
        setMarketData(details)
      } else {
        getMarketData()
      }
    }
  }, [name, marketData])

  return (
    <Page>
      <Narrow>
        <CompanyMarketData error={error} company={marketData} />
      </Narrow>
    </Page>
  )
}

export default SimilarCompanyPage
