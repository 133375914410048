import React, { useContext, useEffect } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, Card } from '@mui/material'
import { Link, Route, Switch, useRouteMatch } from 'react-router-dom'
import { makeStyles } from 'tss-react/mui'

import DataUploadsPage from '_pages/data-administration/data-uploads'
import PrivilegedCompaniesPages from '_pages/data-administration/privileged-companies'
import SyncPage from '_pages/data-administration/sync'

import { Button } from '_shared/buttons'
import { IMenuAction } from '_shared/buttons/FloatingMenuButton'
import { CardActionArea, CardContent } from '_shared/Card'
import Typography from '_shared/Typography'

import Heading from '_core/components/Heading'
import { Narrow, Wide } from '_core/components/layout'
import Widget from '_core/components/Widget'

import { LayoutContext } from 'Layout/LayoutContextProvider'

import Paths, { DataAdminSubPaths } from 'Paths'

const useStyles = makeStyles()((theme) => ({
  wrapper: {
    display: 'grid',
    flexWrap: 'wrap',
    gridTemplateColumns: 'repeat(auto-fit, minmax(280px, 1fr))',
    alignItems: 'stretch',
    gap: theme.spacing(3),
    marginTop: theme.spacing(2)
  },
  card: {
    display: 'flex',
    alignItems: 'stretch',
    height: '100%'
  },
  content: {
    width: '100%'
  }
}))

const Content = () => {
  const { setMobileHeader } = useContext(LayoutContext)
  const { classes } = useStyles()

  useEffect(() => {
    setMobileHeader('Data administration')
  }, [setMobileHeader])

  const cards: (IMenuAction & { description: string })[] = [
    {
      label: 'Data uploads',
      icon: ['far', 'cloud-arrow-up'],
      link: DataAdminSubPaths.dataUploads,
      description: 'On this page, you can manage and view various types of uploaded data'
    },
    {
      label: 'Restricted companies',
      icon: ['far', 'ban'],
      link: DataAdminSubPaths.privilegedCompanies,
      description: 'On this page, you can manage restricted companies list and their access'
    },
    {
      label: 'Sync',
      icon: ['far', 'rotate'],
      link: DataAdminSubPaths.sync,
      description: 'On this page, you can manage the SQL database connection settings and configure the synchronization schedule'
    }
  ]

  const Cards = (
    <Box className={classes.wrapper}>
      {cards.map((card, i) => (
        <Card key={i}>
          <Link to={card.link}>
            <CardActionArea className={classes.card}>
              <CardContent className={classes.content}>
                <Heading title={card.label} icon={card.icon} underlined />
                <Typography color="text.secondary">{card.description}</Typography>
              </CardContent>
            </CardActionArea>
          </Link>
        </Card>
      ))}
    </Box>
  )

  return (
    <>
      <Wide>
        <Typography variant="h1">Data administration</Typography>
        {Cards}
      </Wide>
      <Narrow>
        <Widget scope="card">{Cards}</Widget>
      </Narrow>
    </>
  )
}

const BackButton = () => (
  <Link to={Paths._dataAdmin}>
    <Button variant="link" color="primary" startIcon={<FontAwesomeIcon icon={['far', 'arrow-left']} style={{ fontSize: 14 }} />}>
      Data administration
    </Button>
  </Link>
)

const DataAdministrationPage = () => {
  const { isExact: isSubPage } = useRouteMatch(`${Paths._dataAdmin}/*`) || {}

  return (
    <>
      <Wide>{isSubPage && <BackButton />}</Wide>
      <Switch>
        <Route exact path={Paths._dataAdmin} component={Content} />
        <Route exact path={DataAdminSubPaths.dataUploads} component={DataUploadsPage} />
        <Route path={DataAdminSubPaths.privilegedCompanies} component={PrivilegedCompaniesPages} />
        <Route path={DataAdminSubPaths.sync} component={SyncPage} />
      </Switch>
    </>
  )
}

export default DataAdministrationPage
