import { Box, Checkbox, CircularProgress, MenuItem, Select } from '@mui/material'
import { useHistory } from 'react-router-dom'
import { makeStyles } from 'tss-react/mui'

import { isSidepanel } from '_pages/sidebar'

import { Button } from '_shared/buttons'
import FormLabel from '_shared/forms/FormLabel'
import Skeleton from '_shared/Skeleton'
import Typography from '_shared/Typography'

import { Narrow } from '_core/components/layout'
import Success from '_core/components/Success'
import Topbar from '_core/components/Topbar'

import useSidebar from '_core/hooks/useSidebar'
import useTeam, { RenamedRole, Role } from '_core/hooks/useTeam'

import { actionButtons } from 'AppTheme'

import Paths from 'Paths'

const useStyles = makeStyles()((theme) => ({
  buttonContainer: actionButtons(theme),
  wrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 'calc(100vh - 146px)'
  }
}))

interface IAssociationsEdit {
  name: string
  load: boolean
  loading: boolean
  success: boolean
  teamName: string
  teamId: string
  currentRole?: RenamedRole
  handleRole: (role: RenamedRole) => void
  roles: Role[]
  handleAccess: (access: boolean) => void
  currentAccess: boolean
  save: () => void
}

const AssociationsEdit = ({
  name,
  load,
  loading,
  success,
  teamName,
  teamId,
  currentRole,
  handleRole,
  roles,
  handleAccess,
  currentAccess,
  save
}: IAssociationsEdit) => {
  const { close: sidebarClose } = useSidebar()
  const history = useHistory()
  const { classes } = useStyles()
  const sidepanel = isSidepanel('sidepanel')
  const { renameMemberRole } = useTeam()

  const close = () => {
    if (sidepanel) {
      sidebarClose()
    } else {
      history.push(`${Paths._teams}/${teamId}`)
    }
  }

  return (
    <Narrow>
      <Topbar sub={name} nativeBack={true} />
      {load && !success && (
        <Box className={classes.wrapper}>
          <CircularProgress />
        </Box>
      )}

      {!load && !success && (
        <Box px={2}>
          <Typography style={{ paddingBottom: 16 }}>
            You are looking at associations of team{' '}
            <Skeleton condition={loading}>
              <b>&quot;{teamName || 'Placeholder'}&quot;</b>.
            </Skeleton>
          </Typography>
          <FormLabel label="Role" />
          <Select
            value={currentRole || ''}
            onChange={(e) => handleRole(e.target.value as RenamedRole)}
            variant="outlined"
            disabled={!currentRole}
            fullWidth
          >
            {roles &&
              roles.map(renameMemberRole).map((role: string) => {
                return (
                  <MenuItem key={role} value={role}>
                    {role}
                  </MenuItem>
                )
              })}
          </Select>

          {currentRole === 'Contributor' && (
            <Box display="flex" alignItems="center" pt={2} mt={'-9px'} ml={'-9px'}>
              <Checkbox checked={currentAccess} disabled={loading} onChange={(e) => handleAccess(e.target.checked)} />
              <Typography variant="body1">Allow access to app</Typography>
            </Box>
          )}
        </Box>
      )}

      {success && (
        <Box className={classes.wrapper}>
          <Success variant="centered" text="Thank you! You edited the association successfully">
            <Box px={4} textAlign="center">
              <Button color="secondary" variant="text" onClick={close}>
                Close
              </Button>
            </Box>
          </Success>
        </Box>
      )}

      {!success && (
        <Box className={classes.buttonContainer}>
          <Button onClick={close} variant="text" color="secondary">
            Close
          </Button>
          <Button onClick={save} variant="text" disabled={loading || load} disablePR>
            Save
          </Button>
        </Box>
      )}
    </Narrow>
  )
}

export default AssociationsEdit
