import { ReactNode, createContext, useState, useCallback, useEffect, useMemo } from 'react'

import useEntityEndpoint from '_core/hooks/useEntityEndpoint'

import { put } from 'utils/httpUtils'

type ContextType = {
  dataVisibilityLoading: boolean
  dataVisibility: UserDataVisibilityParams | null
  saveDataVisibility: (payload: UserDataVisibilityParams) => Promise<void>
}

export const DataVisibilityContext = createContext({} as ContextType)

const DataVisibilityProvider = (props: { children: ReactNode }) => {
  const [dataVisibilitySaving, setDataVisibilitySaving] = useState(false)
  const { result, loading } = useEntityEndpoint<{ results: UserDataVisibilityParams }>('/usersettings/datavisibility')

  const [dataVisibility, setDataVisibility] = useState<UserDataVisibilityParams | null>(null)

  useEffect(() => {
    if (dataVisibility === null && result) {
      setDataVisibility(result.results)
    }
  }, [dataVisibility, result])

  const saveDataVisibility = useCallback(
    async (payload: UserDataVisibilityParams) => {
      const oldDataVisibility = dataVisibility
      setDataVisibilitySaving(true)
      try {
        setDataVisibility(payload)
        await put<UserDataVisibilityParams>('/adminsettings/datavisibility', payload)
      } catch (err) {
        setDataVisibility(oldDataVisibility)
        throw err
      } finally {
        setDataVisibilitySaving(false)
      }
    },
    [dataVisibility, setDataVisibility]
  )

  const ctx = useMemo(
    () => ({
      dataVisibility,
      dataVisibilityLoading: loading || dataVisibilitySaving,
      saveDataVisibility
    }),
    [dataVisibility, loading, dataVisibilitySaving, saveDataVisibility]
  )

  return <DataVisibilityContext.Provider value={ctx}>{props.children}</DataVisibilityContext.Provider>
}

export default DataVisibilityProvider
